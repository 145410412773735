import React, { useState, useRef } from "react";
import util from "../util/util";
import database from "../util/database";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import api from "../util/api";
import CircularProgress from "@mui/material/CircularProgress";
import Snack from "../components/controls/snack";
import LabelControl from "./controls/labelControl";
import Link from "@mui/material/Link";

const useConstructor = (callBack = () => { }) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
};

function PasswordChange(props) {
    var formValid = false;

    const [showPwd, setShowPwd] = useState(false);

    const [oldPwd, setOldPwd] = useState("");
    const [oldPwdError, setOldPwdError] = useState(false);
    const [oldPwdHelper, setOldPwdHelper] = useState("");

    const [newPwd1, setNewPwd1] = useState("");
    const [newPwd1Error, setNewPwd1Error] = useState(false);
    const [newPwd1Helper, setNewPwd1Helper] = useState("");

    const [newPwd2, setNewPwd2] = useState("");
    const [newPwd2Error, setNewPwd2Error] = useState(false);
    const [newPwd2Helper, setNewPwd2Helper] = useState("");

    const [snackOpen, setSnackOpen] = useState();
    const [snackType, setSnackType] = useState("success");
    const [snackMessage, setSnackMessage] = useState("");

    const [guid, setGuid] = useState("");
    const [langID, setLangID] = useState("");
    const [loading, setLoading] = useState(false);

    const [type, setType] = useState(3);
    const [username, setUsername] = useState();
    const [usernameError, setUsernameError] = useState(false);
    const [passwordResetToken, setPasswordResetToken] = useState();
    const [passwordResetTokenError, setPasswordResetTokenError] = useState();

    useConstructor(() => {
        let langID = database.get("langID");
        let user = database.get("user");
        let params = util.getParametersFromUrl(window.location);

        if (params.type) {
            setType(params.type);
        }

        if (user) {
            setGuid(user.guid);
            setLangID(langID);
        }
    });

    function handleOldPwdChange(data) {
        if (data.length == 0) {
            setOldPwdError(true);
            setOldPwdHelper("Empty field");
            formValid = false;
        } else {
            setOldPwdError(false);
            setOldPwdHelper("");
        }
        setOldPwd(data);
    }

    function handleNewPwd1Change(data) {
        setNewPwd1(data);

        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/.test(data)) {
            setNewPwd1Error(true);
            setNewPwd1Helper("The password must be between 8 and 15 characters long, must have uppercase and lowercase, and at least one digit.");
            formValid = false;
            return;
        }

        if (data == oldPwd) {
            setNewPwd1Error(true);
            setNewPwd1Helper("The new password must be different from the old one.");
            formValid = false;
            return;
        }

        //prva zaporka je OK, sad treba samo vidjeti da li su jednake
        setNewPwd1Error(false);
        setNewPwd1Helper("");

        if (data != newPwd2) {
            setNewPwd2Error(true);
            setNewPwd2Helper("Passwords do not match.");
            formValid = false;
            return;
        } else {
            setNewPwd2Error(false);
            setNewPwd2Helper("");
            return;
        }
    }

    function handleNewPwd2Change(data) {
        setNewPwd2(data);

        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/.test(data)) {
            setNewPwd2Error(true);
            setNewPwd2Helper("The password must be between 8 and 15 characters long, must have uppercase and lowercase, and at least one digit.");
            formValid = false;
            return;
        }

        if (data == oldPwd) {
            setNewPwd2Error(true);
            setNewPwd2Helper("The new password must be different from the old one.");
            formValid = false;
            return;
        }
        //druga zaporka je OK, sad treba samo vidjeti da li su jednake
        setNewPwd2Error(false);
        setNewPwd2Helper("");

        if (data != newPwd1) {
            setNewPwd2Error(true);
            setNewPwd2Helper("Passwords do not match.");
            formValid = false;
            return;
        } else {
            setNewPwd1Error(false);
            setNewPwd1Helper("");
            setNewPwd2Error(false);
            setNewPwd2Helper("");
            return;
        }
    }

    function handleSaveClick() {
        if (type == 3) {
            let data = { Mail: username, MailMessage: "Password recovery token" };
            setLoading(true);
            api.postOut("UserResetPasswordRequest", data, (result) => {
                setLoading(false);
                setType(2);
                if (result.Success) {
                    setSnackType("success");
                    setSnackMessage("A password recovery token has been sent to the email address " + " " + username);
                } else {
                    setSnackType("error");
                    setSnackMessage(result.ErrorMessage);
                }
                setSnackOpen(true);
            });
        }

        if (type == 2) {
            let data = {
                Mail: username,
                NewPassword: newPwd1,
                NewPasswordConfirm: newPwd2,
                PasswordResetToken: passwordResetToken,
            };

            setLoading(true);
            api.postOut("UserResetPassword", data, (result) => {
                setLoading(false);
                setType(2);
                if (result.Success) {
                    setSnackType("success");
                    setSnackMessage("Saved successfully");
                    setTimeout(() => {
                        window.location = "#/login";
                    }, 2000);
                } else {
                    setSnackType("error");
                    setSnackMessage(result.ErrorMessage);
                }
                setSnackOpen(true);


            });
        }

        if (type == 1) {
            let pwd = "";
            if (oldPwd) {
                pwd = oldPwd.trim();
            }

            let pwd1 = "";
            if (newPwd1) {
                pwd1 = newPwd1.trim();
            }

            let pwd2 = "";
            if (newPwd2) {
                pwd2 = newPwd2.trim();
            }

            formValid = true;
            handleOldPwdChange(pwd);
            handleNewPwd1Change(pwd1);
            handleNewPwd2Change(pwd2);

            if (formValid) {
                //save podataka
                setLoading(true);
                let changePwdModel = {
                    OldPassword: pwd,
                    NewPassword: pwd1,
                    UserDeviceGUID: database.get("userDeviceGUID"),
                };

                api.post(
                    "UserChangePassword",
                    changePwdModel,
                    (result) => {
                        successCbk(result);
                    },
                    (errorRes) => {
                        errorCbk(errorRes);
                    }
                );
            }
        }
    }

    function successCbk(result) {
        if (result.Success) {
            setSnackType("success");
            setSnackMessage("Saved successfully");
        } else {
            setSnackType("error");
            setSnackMessage(result.ErrorMessage);
        }

        setLoading(false);
        setSnackOpen(true);
    }

    function errorCbk(data) {
        setLoading(false);
        util.throwExcMessage(data);
    }

    const handleClickShowPassword = () => {
        setShowPwd(!showPwd);
    };

    function handleUsernameChange(data) {
        if (data && data.length > 30) {
            data = data.substr(0, 30);
        }

        if (data) {
            data = data.toLowerCase().trim();
        }

        setUsername(data);

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data)) {
            setUsernameError(false);
        } else {
            setUsernameError(true);
            formValid = false;
        }
    }

    const isValid = () => {
        if (type == 1) {
            return !oldPwdError && !newPwd1Error && !newPwd2Error;
        }

        if (type == 2) {
            return !passwordResetTokenError && !newPwd1Error && !newPwd2Error && passwordResetToken && newPwd1 && newPwd2;
        }

        if (type == 3) {
            return !usernameError && username;
        }
    };

    return (
        <div className="component-container">
            {loading ? (
                <div style={{ marginTop: "50vh", marginLeft: "50vw" }}>
                    <CircularProgress style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", marginLeft: "-20px", marginTop: "-20px" }} />
                </div>
            ) : (
                <div>
                    <LabelControl style={{ fontSize: "12px", marginTop: 40 }} className="title">{type == 3 ? "Send a password change request" : "Change password"}</LabelControl>
                    <Container maxWidth="sm">
                        <Snack open={snackOpen} type={snackType} onClose={() => setSnackOpen(false)} message={snackMessage} />

                        {type < 3 ? (
                            <FormControl fullWidth>
                                {/* <TextField value={oldPwd} label={util.translate("lozinka")} variant="outlined" style={{ marginTop: 10, width:"100%"}}/>
                                    <br></br> */}

                                <TextField
                                    autoCapitalize="none"
                                    autoComplete="new-password"
                                    value={passwordResetToken}
                                    required
                                    error={passwordResetTokenError}
                                    helperText={passwordResetTokenError && "required"}
                                    onChange={(e) => {
                                        setPasswordResetToken(e.target.value);
                                        setPasswordResetTokenError(!e.target.value);
                                    }}
                                    label={type == 1 ? "Old password" : "Token"}
                                    variant="outlined"
                                    FormHelperTextProps={{
                                        style: { lineHeight: "1em", fontSize: "x-small" },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton tabIndex={-1} aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end" className="mybuttonicon"></IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ marginTop: 10, width: "100%" }}
                                />

                                <TextField
                                    autoCapitalize="none"
                                    autoComplete="new-password"
                                    required
                                    type={showPwd ? "text" : "password"}
                                    value={newPwd1}
                                    error={newPwd1Error}
                                    helperText={newPwd1Helper}
                                    onChange={(e) => handleNewPwd1Change(e.target.value)}
                                    label={"New password"}
                                    variant="outlined"
                                    FormHelperTextProps={{
                                        style: { lineHeight: "1em", fontSize: "x-small" },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton tabIndex={-1} aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end" className="mybuttonicon">
                                                    {showPwd ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ marginTop: 10, width: "100%" }}
                                />

                                <TextField
                                    required
                                    autoCapitalize="none"
                                    autoComplete="new-password"
                                    type={showPwd ? "text" : "password"}
                                    value={newPwd2}
                                    error={newPwd2Error}
                                    helperText={newPwd2Helper}
                                    onChange={(e) => handleNewPwd2Change(e.target.value)}
                                    label={"Repeat password"}
                                    variant="outlined"
                                    FormHelperTextProps={{
                                        style: { lineHeight: "1em", fontSize: "x-small" },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton tabIndex={-1} aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end" className="mybuttonicon">
                                                    {showPwd ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ marginTop: 10, width: "100%" }}
                                />
                                <br></br>
                            </FormControl>
                        ) : null}

                        {type == 3 ? (
                            <FormControl fullWidth>
                                <TextField
                                    value={username}
                                    error={usernameError}
                                    onChange={(e) => handleUsernameChange(e.target.value)}
                                    helperText={usernameError && "Invalid mail"}
                                    label={"Email"}
                                    variant="outlined"
                                    FormHelperTextProps={{
                                        style: { lineHeight: "1em", fontSize: "x-small" },
                                    }}
                                    style={{ marginTop: 10, width: "100%" }}
                                />
                            </FormControl>
                        ) : null}

                        <FormControl fullWidth style={{ marginTop: "10px" }}>
                            <Button onClick={handleSaveClick} variant="contained" style={{ width: "100%" }} disabled={!isValid()}>
                                {type == 3 ? "Send" : "Save"}
                            </Button>
                        </FormControl>

                        {type > 1 && (
                            <FormControl fullWidth style={{ marginTop: "10px" }}>
                                <Button onClick={() => (window.location = "#/login")} variant="contained" style={{ width: "100%" }}>
                                    {"Cancel"}
                                </Button>
                            </FormControl>
                        )}

                        {type == 3 ?
                            <FormControl fullWidth>
                                <LabelControl onClick={() => setType(2)} style={{ marginTop: "4px", float: "right", textAlign: "right", textDecoration: "underline" }}>
                                    {util.translate("alreadyHaveToken")}
                                </LabelControl>
                            </FormControl> : null}
                    </Container>
                </div >
            )
            }
        </div >
    );
}

export default PasswordChange;
