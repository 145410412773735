import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faParking } from "@fortawesome/free-solid-svg-icons";
import util from "../util/util";
import GlobalContext from "../components/globalContext";
import GoogleIcon from "@mui/icons-material/Google";
import Logo from "../images/logo.png";
import En from "../images/en.png";
import Hr from "../images/hr.png";
import LabelControl from "./controls/labelControl";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ButtonControl, PageTitleControl, DividerControl, Snack, MuiButton } from "../components/controls/controls";
import LoaderControl from "../components/controls/loaderControl";
import GoogleImg from "../images/google.png";
import { WindowSharp } from "@mui/icons-material";
import Link from "@mui/material/Link";

const useConstructor = util.useConstructor();

function LogIn(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [mailSent, setMailSent] = React.useState(false);
    const [mailSentTo, setMailSentTo] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState();
    const [wrongUsernamePassword, setWrongUsernamePassword] = React.useState(false);

    const global = useContext(GlobalContext);

    useConstructor(() => {
        // alert("login - constructor");

        // api.get("api/values", (data) => {
        //     alert("getvalues success");
        //     alert(JSON.stringify(data));
        // },
        //     (data) => {
        //         alert("getvalues error");
        //         alert(JSON.stringify(data));
        //     }
        // )

        // $.ajax({
        //     type: "GET",
        //     url: "https://reqres.in/api/users?page=1",
        //     dataType: "json",
        //     crossDomain: true,
        // })
        //     .done(function (data) {
        //         alert(JSON.stringify(data));
        //     })
        //     .fail(function (ex) {
        //         alert("ajax fail");
        //         alert(JSON.stringify(ex));
        //     });

        let ppa = database.get("privacyPolicyAccepted");

        // alert(JSON.stringify(ppa));
        if (!ppa) {
            database.set("user", null);
            window.location = "#/privacyPolicy";
            return;
        }

        global.setSelectedTab("login");
        let params = util.getParametersFromUrl();
        if (params.registersuccess) {
            setMailSentTo("Confirmation mail sent to " + params.registersuccess);
            setMailSent(true);
        }
        //if (global.selectedTab != "login") global.setSelectedTab("login");
    });

    function logIn() {
        setLoading(true);
        database.set("firebaseToken", "");
        api.logIn(username, password, success, fail);
    }

    function success(data) {
        database.set("user", data);
        database.set("username", data.username);
        setLoading(false);
        window.location = "#/home";
        global.setSelectedTab("home");
        //window.location.reload();
    }

    function fail(data) {
        // alert(JSON.stringify(data));
        setWrongUsernamePassword(true);

        setLoading(false);
    }

    function register() {
        window.location = "#/register";
    }

    function activation() {
        window.location = "#/activation";
    }

    function changeLang(lang) {
        setLoading(true);
        localStorage.setItem("lang", lang == 1 ? "EN" : "HR");
        localStorage.setItem("langID", lang);
        window.location.reload();
    }

    function google() {
        let wbclid = "611680537448-650e84qgpn1ni63oa5n8k1num0kid7ib.apps.googleusercontent.com";

        // alert("try silent login");

        window.plugins.googleplus.trySilentLogin(
            {
                webClientId: wbclid, // optional - clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
                offline: true, // Optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
            },
            function (obj) {
                // alert("sylent login success. we have idToken: " + obj.idToken);
                api.logIn("google", obj.idToken, success, fail);
            },
            function (msg) {
                // alert("sylent login fail. try to standard login");
                setLoading(true);
                window.plugins.googleplus.login(
                    {
                        webClientId: wbclid,
                        offline: true, // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
                    },
                    function (obj) {
                        // alert("standard login success.");

                        // setUsername(obj.idToken);
                        // alert(JSON.stringify(obj)); // do something useful instead of alerting
                        api.logIn("google", obj.idToken, success, fail);
                    },
                    function (msg) {
                        setLoading(false);
                        // alert(JSON.stringify(msg));
                        // setSnackMessage("Something went wrong.");
                    }
                );
            }
        );
    }

    return (
        <div style={{ height: "100vh", overflow: "auto" }}>
            <Container maxWidth="sm">
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <span>
                        <img style={{ width: "150px" }} src={Logo}></img>
                    </span>
                    {/* &nbsp; */}
                    {/* <LabelControl style={{ letterSpacing: "4px", fontSize: "25px", marginTop: "-40px" }}>FUTSA</LabelControl> */}
                </div>

                {loading ? (
                    <LoaderControl></LoaderControl>
                ) : (
                    <Stack spacing={3}>
                        <FormControl fullWidth style={{ marginTop: 30 }}>
                            <TextField
                                label={util.translate("username")}
                                variant="outlined"
                                onKeyPress={(e) => {
                                    if (e.key == "Enter") logIn();
                                }}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                autoComplete="new-password"
                                type="password"
                                onKeyPress={(e) => {
                                    if (e.key == "Enter") logIn();
                                }}
                                label={util.translate("password")}
                                variant="outlined"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <LabelControl style={{ marginTop: "4px", float: "right", textAlign: "right", textDecoration:"underline" }} onClick={() => window.location ="#/passwordchange?type=3"}>
                           
                                    {util.translate("forgotPassword")}
                                
                            </LabelControl>
                        </FormControl>

                        <MuiButton onClick={logIn} style={{ height: "50px" }} variant="contained">
                            {util.translate("login")}
                        </MuiButton>

                        <MuiButton onClick={google} style={{ height: "50px" }} variant="outlined">
                            {util.translate("signInWithGoogle")} &nbsp; <img src={GoogleImg}></img>
                        </MuiButton>

                        <MuiButton onClick={register} style={{ height: "50px" }} variant="outlined">
                            {util.translate("registration")}
                        </MuiButton>
                    </Stack>
                )}

                {!loading ? (
                    <table style={{ width: "100%", marginTop: 20, marginBottom: 20 }}>
                        <tbody>
                            <tr>
                                <td style={{ padding: 10, textAlign: "right" }}>
                                    <img onClick={() => changeLang(2)} style={{ width: "25px" }} src={Hr}></img>
                                </td>
                                <td style={{ padding: 10, textAlign: "left" }}>
                                    <img onClick={() => changeLang(1)} style={{ width: "25px" }} src={En}></img>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ) : null}
            </Container>

            <Snack open={wrongUsernamePassword} type={"error"} onClose={() => setWrongUsernamePassword(false)} message={"Wrong username or password!"} />
            <Snack open={mailSent} type={"success"} onClose={() => setMailSent(false)} message={mailSentTo} />
            <Snack open={snackMessage} type={"error"} onClose={() => setSnackMessage(null)} message={snackMessage} />
        </div>
    );
}

export default LogIn;
