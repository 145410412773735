import React, { useState, useEffect, useRef, useContext } from "react";
import util from "../util/util";
import database from "../util/database";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import api from "../util/api";
import GlobalContext from "../components/globalContext";
import CircularProgress from "@mui/material/CircularProgress";
import { Oval } from "react-loader-spinner";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoaderControl from '../components/controls/loaderControl';
import { ButtonControl, PageTitleControl, DividerControl, Snack, MuiButton } from "../components/controls/controls";



const useConstructor = util.useConstructor();

function Registration(props) {
    var formValid = false;
    const global = useContext(GlobalContext);

    useConstructor(() => {
        if (global.selectedTab != "register") global.setSelectedTab("register");
    });

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(props.name || "");
    const [surname, setSurname] = useState(props.surname || "");

    const [address, setAddress] = useState(props.address || "");
    const [zip, setZip] = useState(props.zip || "");
    const [city, setCity] = useState(props.city || "");
    const [country, setCountry] = useState(props.country || "");
    const [oib, setOib] = useState(props.oib || "");
    const [guid, setGuid] = useState(props.guid || "");

    const [showPwd, setShowPwd] = useState(false);

    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState(false);
    const [usernameHelper, setUsernameHelper] = useState("");

    const [newPwd1, setNewPwd1] = useState("");
    const [newPwd1Error, setNewPwd1Error] = useState(false);
    const [newPwd1Helper, setNewPwd1Helper] = useState("");

    const [newPwd2, setNewPwd2] = useState("");
    const [newPwd2Error, setNewPwd2Error] = useState(false);
    const [newPwd2Helper, setNewPwd2Helper] = useState("");

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailHelper, setEmailHelper] = useState("");

    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState(false);
    const [phoneHelper, setPhoneHelper] = useState("");


    const [errorMessage, setErrorMessage] = useState();

    const [emailNotifTypeID, setEmailNotifTypeID] = useState(props.emailNotifTypeID || "");

    function handleEmailNotifTypeChange(data) {
        setEmailNotifTypeID(data);
    }

    function handleEmailChange(data) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data) || data == "") {
            setEmailError(false);
            setEmailHelper("");
        } else {
            setEmailError(true);
            setEmailHelper("Neispravan e-mail");
            formValid = false;
        }
        setEmail(data);
    }

    function handleUsernameChange(data) {
        if (data.length > 5) {
            setUsernameError(false);
            setUsernameHelper("");
        } else {
            setUsernameError(true);
            if (data == "") {
                setUsernameHelper("Neispravan username (min 6 znakova)");
            } else {
                setUsernameHelper("Neispravan username (min 6 znakova)");
            }
            formValid = false;
        }
        setUsername(data);
    }

    function handleNewPwd1Change(data) {
        setNewPwd1(data);
        if (newPwd2) {
            if (data != newPwd2) {
                setNewPwd1Error(true);
                setNewPwd1Helper("Passwords do not match.")

            } else {
                setNewPwd1Error(false);
                setNewPwd2Error(false);
                setNewPwd1Helper(null)
                setNewPwd2Helper(null)
            }
        }
    }

    function handleNewPwd2Change(data) {
        setNewPwd2(data);
        if (newPwd1) {
            if (data != newPwd1) {
                setNewPwd2Error(true);
                setNewPwd2Helper("Passwords do not match.")
            } else {
                setNewPwd1Error(false);
                setNewPwd2Error(false);
                setNewPwd1Helper(null)
                setNewPwd2Helper(null)
            }
        }
    }

    function handleSaveClick() {
        setLoading(true);
        //save podataka
        let registerModel = {
            Email: email,
            Username: username,
            Password: newPwd1,
            Nick: name,
            Mobile: phone
        };
        api.register(
            "Members/registration",
            registerModel,
            (result) => {
                successCbk(result);
            },
            (errorRes) => {
                errorCbk(errorRes);
            }
        );
    }

    function successCbk(result) {
        if (result.Success) {
            window.location = "#/login?registersuccess=" + email;
        } else {
            setLoading(false);
            setErrorMessage(result.ErrorMessage);
        }
    }

    function errorCbk(result) {
        setLoading(false);
        setErrorMessage(result.ErrorMessage);
    }

    const handleClickShowPassword = () => {
        setShowPwd(!showPwd);
    };

    return (
        <div className="component-container centered-container" style={{ padding: "20px" }}>
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <>
                    <       Snack open={errorMessage} type={"error"} onClose={() => setErrorMessage(null)} message={errorMessage} />
                    <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                        <Toolbar>
                            <Typography style={{ textAlign: "left" }} component="div" sx={{ flexGrow: 1 }}>
                                {util.translate("registration")}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <FormControl fullWidth>
                        <br></br>
                        <TextField value={email} required error={emailError} helperText={emailHelper} onChange={(e) => handleEmailChange(e.target.value)} label={util.translate("eMail")} variant="outlined" style={{ marginTop: 10, width: "100%" }} />


                        {/* <TextField value={username} error={usernameError} helperText={usernameHelper} onChange={(e) => handleUsernameChange(e.target.value)} label={"Username"} variant="outlined" style={{ marginTop: 10, width: "100%" }} /> */}
                        {/* <br></br> */}

                        {/* <TextField value={oldPwd} label={util.translate("lozinka")} variant="outlined" style={{ marginTop: 10, width:"100%"}}/>
                        <br></br> */}




                        <TextField
                            autoComplete="new-password"
                            type={showPwd ? "text" : "password"}
                            value={newPwd1}
                            error={newPwd1Error}
                            required
                            helperText={newPwd1Helper}
                            onChange={(e) => handleNewPwd1Change(e.target.value)}
                            label={util.translate("newPassword")}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                            {showPwd ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginTop: 10, width: "100%" }}
                        />


                        <TextField
                            autoComplete="new-password"
                            type={showPwd ? "text" : "password"}
                            value={newPwd2}
                            required
                            error={newPwd2Error}
                            helperText={newPwd2Helper}
                            onChange={(e) => handleNewPwd2Change(e.target.value)}
                            label={util.translate("repeatPassword")}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                            {showPwd ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginTop: 10, width: "100%" }}
                        />


                        <TextField required value={name} onChange={(e) => setName(e.target.value)} label={"Nick"} variant="outlined" style={{ marginTop: 10, width: "100%" }} />



                        {/* <TextField value={phone} onChange={(e) => setPhone(e.target.value)} label={"Mobile"} variant="outlined" style={{ marginTop: 10, width: "100%" }} /> */}

                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <div className="mycontainer">
                            <MuiButton onClick={() => (window.location = "#/login")} variant="contained">
                                {util.translate("back")}
                            </MuiButton>{" "}
                            &nbsp;
                            <MuiButton disabled={emailError || newPwd1Error || newPwd2Error || (!email) || (!name)} onClick={handleSaveClick} variant="contained">
                                {util.translate("save")}
                            </MuiButton>
                        </div>
                    </FormControl>
                </>
            )}
        </div>
    );
}

export default Registration;
