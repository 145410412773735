import React, { useState, useRef, useContext, useEffect } from "react";
import database from "../util/database";
import { faParking, faMoneyCheck, faUsers, faFutbol } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../util/api";
import util from "../util/util";
import { ButtonControl, PageTitleControl, LabelControl, DividerControl, MuiButton } from "../components/controls/controls";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Team1img from '../images/team1.png';
import Team2img from '../images/team2.png';
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { CheckOutlined, LeftOutlined } from "@ant-design/icons";
import LoaderControl from '../components/controls/loaderControl';
import $ from "jquery";

const useConstructor = util.useConstructor();

function SelectResult2(props) {
    const [loading, setLoading] = useState(true);
    const [value, setValue] = React.useState(0);
    // const [players, setPlayers] = React.useState([]);
    const [match, setMatch] = React.useState();
    const [date, setDate] = React.useState();
    const [dateString, setDateString] = React.useState();
    const [team1Score, setTeam1Score] = React.useState(0);
    const [team2Score, setTeam2Score] = React.useState(0);
    const [team1OwnGoals, setTeam1OwnGoals] = React.useState(0);
    const [team2OwnGoals, setTeam2OwnGoals] = React.useState(0);
    const [team1Total, setTeam1Total] = React.useState(0);
    const [team2Total, setTeam2Total] = React.useState(0);
    const [players, setPlayers] = React.useState([]);
    const [scorrersDivY, setScorrersDivY] = React.useState(0);
    const [assistsDivY, setAssistsDivY] = React.useState(0);

    useConstructor(() => {
        let params = util.getParametersFromUrl();
        if (params.matchID) {
            api.get("GetMatch?id=" + params.matchID, success, fail);
        } else {
            api.get("GetNextMatch?teamID=" + params.teamID, success, fail);
        }
    });

    function success(data) {
        setLoading(false);
        setPlayers(data.Players);
        setMatch(data);

        let team1s = 0;
        let team2s = 0;

        data.Players.map((p, index) => {
            if (p.Goals) {
                if (p.ForTeam == 1) {
                    team1s += p.Goals;
                }
                if (p.ForTeam == 2) {
                    team2s += p.Goals;
                }
            }
        })

        let t1OwnGoals = data.Team1Score - team1s;
        let t2OwnGoals = data.Team2Score - team2s;

        setTeam1OwnGoals(t1OwnGoals);
        setTeam2OwnGoals(t2OwnGoals);

        setTeam1Score(team1s);
        setTeam2Score(team2s);
    }

    function fail(data) {
        setLoading(false);
    }

    const cancel = () => {
        window.location = "#/teampage";
    }

    const save = () => {
        setLoading(true);
        let data = {
            Team1Score: team1Score,
            Team2Score: team2Score,
            players: [...players],
            MatchID: match.MatchID
        };

        data.TeamID = database.get("selectedTeamID");
        api.post("CloseMatch", data, (data) => {
            let params = util.getParametersFromUrl();
            if (params.backto) {
                window.location = "#/" + params.backto;
            } else {
                window.location = "#/teamPage";
            }
        });
    }


    function addGoals(index, goals) {
        let pls = [...players];
        let player = pls[index];
        player.Goals += goals;
        if (player.Goals < 0)
            player.Goals = 0;

        setPlayers(pls)
        let gls = calculateGoals(pls);
        setTeam1Score(gls.team1Goals + team2OwnGoals);
        setTeam2Score(gls.team2Goals + team1OwnGoals);
    }

    function addOwnGoals(team, goals) {
        let gls = calculateGoals(players);
        let ogls = 0;

        if (team == 1) {
            ogls = team1OwnGoals + goals;
            if (ogls < 0) ogls = 0;
            setTeam1OwnGoals(ogls);
            setTeam2Score(gls.team2Goals + ogls);
        }
        else {
            ogls = team2OwnGoals + goals;
            if (ogls < 0) ogls = 0;
            setTeam2OwnGoals(ogls);
            setTeam1Score(gls.team1Goals + ogls);
        }
    }

    function addAssists(index, assists) {
        let pls = [...players];
        let player = pls[index];
        player.Assists += assists;
        if (player.Assists < 0)
            player.Assists = 0;
        setPlayers(pls)
    }

    function calculateGoals(pls) {
        let t1goals = 0;
        let t2goals = 0;

        pls.forEach((pl, index) => {
            if (pl.ForTeam == 1) {
                t1goals += pl.Goals;
            }
            if (pl.ForTeam == 2) {
                t2goals += pl.Goals;
            }
        });

        return {
            team1Goals: t1goals,
            team2Goals: t2goals
        }
    }

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <>
                    {/* <PageTitleControl>{database.get("selectedTeam").Name}</PageTitleControl> */}

                    <Box sx={{ bgcolor: "background.paper", width: "100%" }}>

                        <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                            <Toolbar>
                                <IconButton onClick={cancel} size="small" edge="start" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                    <LeftOutlined></LeftOutlined>
                                </IconButton>

                                <Typography component="div" sx={{ flexGrow: 1 }}>
                                    {team1Score} : {team2Score}
                                </Typography>

                                <IconButton onClick={save} size="small" edge="end" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                    <CheckOutlined></CheckOutlined>
                                </IconButton>
                            </Toolbar>
                        </AppBar>


                        {/* <div  style={{ padding: "5px" }}>
                            <table style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: "100px"}}>
                                            Player
                                        </th>
                                        <th>
                                            Goals
                                        </th>
                                        <th>
                                            Assists
                                        </th>
                               
                                    </tr>
                                </thead>
                            </table>
                        </div> */}

                        <div className="fixed-container" id="scorrersDiv">
                            <table style={{ width: "100%" }} className={database.get("theme") == "light" ? "table-light" : "table-dark"}>

                                {/* <thead>
                                            <tr>
                                                <th>
                                                    Player
                                                </th>
                                                <th>
                                                    Goals
                                                </th>
                                                <th>
                                                   
                                                </th>
                                                <th>
                                                    +
                                                </th>
                                                <th>

                                                </th>

                                            </tr>
                                        </thead> */}
                                <tbody>

                                    <tr key={"pl1-1---1"}>
                                        <td style={{ width: "100px", textAlign: "left", paddingLeft: "10px" }}>
                                            <LabelControl style={{ fontWeight: "bold" }}>PLAYER</LabelControl>
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>
                                            GOALS
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>
                                            ASSISTS
                                        </td>
                                    </tr>

                                    {players && players.map((player, index) => {
                                        return player && player.ForTeam == 1 && (
                                            <tr key={"pl1-1-" + index}>
                                                <td style={{ width: "100px", textAlign: "left", paddingLeft: "10px" }}>
                                                    <LabelControl style={{ fontWeight: "bold" }}> <img style={{ width: "30px" }} src={Team1img}></img> {player.Nick}</LabelControl>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <MuiButton fast onClick={() => addGoals(index, -1)}>-</MuiButton>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <LabelControl style={{ fontWeight: "bold" }}>{player.Goals}</LabelControl>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <MuiButton fast onClick={() => addGoals(index, 1)}>+</MuiButton>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <MuiButton fast onClick={() => addAssists(index, -1)}>-</MuiButton>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <LabelControl style={{ fontWeight: "bold" }}>  {player.Assists}</LabelControl>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <MuiButton fast onClick={() => addAssists(index, 1)}>+</MuiButton>
                                                </td>
                                            </tr>
                                        )
                                    })}



                                    <tr key={"pl1-og1-urz5"}>
                                        <td style={{ width: "100px", textAlign: "left", paddingLeft: "10px" }}>
                                            <LabelControl style={{ fontWeight: "bold" }}><img style={{ width: "30px" }} src={Team1img}></img>Own goals</LabelControl>
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            <MuiButton fast onClick={() => addOwnGoals(1, -1)}>-</MuiButton>
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            <LabelControl style={{ fontWeight: "bold" }}>{team1OwnGoals}</LabelControl>
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            <MuiButton fast onClick={() => addOwnGoals(1, 1)}>+</MuiButton>
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            {/* <MuiButton fast onClick={() => addAssists(index, -1)}>-</MuiButton> */}
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            {/* <LabelControl style={{ fontWeight: "bold" }}>  {player.Assists}</LabelControl> */}
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            {/* <MuiButton fast onClick={() => addAssists(index, 1)}>+</MuiButton> */}
                                        </td>
                                    </tr>

                                    <tr key={"pl2-1---1"}>
                                        <td style={{ width: "100px", textAlign: "left", paddingLeft: "10px" }}>
                                            <LabelControl style={{ fontWeight: "bold" }}>PLAYER</LabelControl>
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>
                                            GOALS
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>
                                            ASSISTS
                                        </td>
                                    </tr>

                                    {players && players.map((player, index) => {
                                        return player && player.ForTeam == 2 && (
                                            <tr key={"pl1-1-" + index}>
                                                <td style={{ width: "100px", textAlign: "left", paddingLeft: "10px" }}>
                                                    <LabelControl style={{ fontWeight: "bold" }}><img style={{ width: "30px" }} src={Team2img}></img> {player.Nick}</LabelControl>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <MuiButton fast onClick={() => addGoals(index, -1)}>-</MuiButton>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <LabelControl style={{ fontWeight: "bold" }}>{player.Goals}</LabelControl>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <MuiButton fast onClick={() => addGoals(index, 1)}>+</MuiButton>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <MuiButton fast onClick={() => addAssists(index, -1)}>-</MuiButton>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <LabelControl style={{ fontWeight: "bold" }}>  {player.Assists}</LabelControl>
                                                </td>
                                                <td style={{ width: "30px" }}>
                                                    <MuiButton fast onClick={() => addAssists(index, 1)}>+</MuiButton>
                                                </td>
                                            </tr>
                                        )
                                    })}


                                    <tr key={"pl1-og1-9ouih"}>
                                        <td style={{ width: "100px", textAlign: "left", paddingLeft: "10px" }}>
                                            <LabelControl style={{ fontWeight: "bold" }}><img style={{ width: "30px" }} src={Team2img}></img>Own goals</LabelControl>
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            <MuiButton fast onClick={() => addOwnGoals(2, -1)}>-</MuiButton>
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            <LabelControl style={{ fontWeight: "bold" }}>{team2OwnGoals}</LabelControl>
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            <MuiButton fast onClick={() => addOwnGoals(2, 1)}>+</MuiButton>
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            {/* <MuiButton fast onClick={() => addAssists(index, -1)}>-</MuiButton> */}
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            {/* <LabelControl style={{ fontWeight: "bold" }}>  {player.Assists}</LabelControl> */}
                                        </td>
                                        <td style={{ width: "30px" }}>
                                            {/* <MuiButton fast onClick={() => addAssists(index, 1)}>+</MuiButton> */}
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </Box>
                </>
            )}
        </div>
    );
}

export default SelectResult2;
