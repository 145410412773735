import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faParking } from "@fortawesome/free-solid-svg-icons";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import * as moment from "moment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ButtonControl, PageTitleControl, LabelControl, DividerControl, MuiButton } from "../components/controls/controls";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoaderControl from "../components/controls/loaderControl";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import "dayjs/locale/hr";
import { setMinutes } from "date-fns";
//import 'dayjs/locale/en';

function NewMatch(props) {
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(new Date());
    const [time, setTime] = React.useState((props.match && props.match.datePlayed) || new Date());
    const [date, setDate] = React.useState(new Date());
    const [totalDate, setTotalDate] = React.useState(mergeDateTime(new Date(), props.minDateTime || new Date()));
    const [minDateTime, setMinDateTime] = React.useState();
    const [matchID, setMatchID] = React.useState();
    function success(data) {}

    function fail(data) {}

    function mergeDateTime(d, t) {
        let hours = new Date(t).getHours();
        let minutes = new Date(t).getMinutes();

        let tDate = new Date(d);
        tDate.setHours(hours);
        tDate.setMinutes(minutes);
        tDate.setSeconds(0);
        tDate.setMilliseconds(0);

        let mdt = new Date(props.minDateTime);

        if (tDate.getTime() <= mdt.getTime()) {
            return null;
        }

        return tDate;
    }

    function save() {
        setLoading(true);

        let str = moment(totalDate).format("DD.MM.YYYY HH:mm");

        let data = {
            TeamID: database.get("selectedTeamID"),
            MatchStatusTypeID: 1,
            DatePlayed: totalDate,
            DatePlayedDTO: str,
            SeasonID: database.get("season").SeasonID,
        };

        if (props.match) {
            data.MatchID = props.match.MatchID;
        }

        // alert(totalDate.toLocaleString());
        // alert(new Date(totalDate.toLocaleString()));
        // alert(JSON.stringify(data));

        props.onSave(data);
    }

    function dateChange(e) {
        setDate(e);
        const d = mergeDateTime(e, time);
        setTotalDate(d);
    }

    function timeChange(e) {
        

        let min = Math.round(e.minute() / 5) * 5;
        e = e.set('minute', min);
        setTime(e);
        console.log(e.minute());
        const d = mergeDateTime(date, e);
        setTotalDate(d);
    }

    useEffect(() => {
        if (props.match && props.match.MatchID != matchID) {
            setMatchID(props.match.MatchID);
            setTime(props.match.DatePlayed);
            setDate(props.match.DatePlayed);
        }
    });

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <div style={{ padding: "40px" }} className="component-container centered-container">
                    {/* <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                        <Toolbar>
                            <Typography style={{ textAlign: "left" }} component="div" sx={{ flexGrow: 1 }}>
                                <p style={{ fontSize: "12px" }}>{"New match"}</p>
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <br></br><br></br> */}
                    {/* ovdje će biti postavljen format pickera ovisno o jeziku */}
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"hr"}>
                        {/* <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Date/Time"
                            value={value}
                            minDateTime={minDateTime}
                            ampm={false}
                            onChange={(newValue) => {
                                setValue(newValue);
                                console.log(newValue)
                            }}
                        /> */}

                        <MobileDatePicker label={util.translate("date")} minDate={props.minDateTime} value={date} onChange={(e) => dateChange(e)} renderInput={(params) => <TextField {...params} />} />
                        <br></br>
                        <br></br>
                        <TimePicker label={util.translate("time")} ampm={false} value={time} style={{color:"red"}} onChange={(e) => timeChange(e)} renderInput={(params) => <TextField {...params} />} />
                    </LocalizationProvider>
                    <br></br>
                    <br></br>
                    <MuiButton variant="contained" disabled={!totalDate} onClick={save} style={{ width: "100px" }}>
                        {util.translate("save")}
                    </MuiButton>{" "}
                    &nbsp;
                    <MuiButton variant="contained" onClick={() => props.onCancel()} style={{ width: "100px" }}>
                        {util.translate("cancel")}
                    </MuiButton>
                    <br></br>
                </div>
            )}
        </div>
    );
}

export default NewMatch;
