import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import GlobalContext from "../components/globalContext";
import { faParking, faMoneyCheck, faUser, faFutbol, faGlasses, faTrash } from "@fortawesome/free-solid-svg-icons";

import { ButtonControl, PageTitleControl, DividerControl, TextBoxControl } from "../components/controls/controls";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonGroup from '@mui/material/ButtonGroup';
import LoaderControl from '../components/controls/loaderControl';

const useConstructor = util.useConstructor();

function Invitations(props) {
    const [invitations, setInvitations] = useState([]);
    const [invitation, setInvitation] = useState("");
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);

    useConstructor(() => {
        if (global.selectedTab != "invitations") global.setSelectedTab("invitations");
    });

    function success(data) {
        window.location = "#/teamPage";
    }

    function fail(data) {
        setLoading(false);
    }

    function save() { }

    function add() {
        let items = invitations;
        items.push(invitation);
        setInvitations(items);
        setInvitation("");
    }

    function removeAt(index) {
        invitations.splice(index, 1);
        let items = [...invitations];
        setInvitations(items);
        setInvitation("");
    }

    function send() {
        setLoading(true);
        let selectedTeam = database.get("selectedTeam");
        let user = database.get("user");

        let data = {
            Users: invitations,
            TeamID: selectedTeam.TeamID,
            Message: user.nick + " invites you to the team " + selectedTeam.Name,
            Title: "Invitation"
        }

        api.post("Members/SendInvitations", data, success, fail);
    }

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <>
                    <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
                        <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                            <Toolbar>
                                <Typography style={{ textAlign: "left" }} component="div" sx={{ flexGrow: 1 }}>
                                    <p style={{ fontSize: "12px" }}>Invite members</p>
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Box>

                    <div style={{ padding: "20px" }}>
                        <TextBoxControl value={invitation} onChange={(e) => setInvitation(e.target.value)} label={""} variant="outlined" style={{ width: "100%" }} />
                        <Button variant="contained" onClick={() => add()} style={{ width: "100%", marginTop: "10px" }}>
                            ADD
                        </Button>
                        <br></br><br></br>
                        {invitations.length > 0 ? (
                            <>
                                {/* <div className="divider">MEMBERS</div> */}
                                {invitations.map((item, index) => {
                                    return (


                                        <ButtonGroup aria-label="split button" style={{ width: "100%", marginTop: "1px" }}>
                                            <Button style={{ width: "100%" }}>{item}</Button>
                                            <Button
                                                size="small"
                                                aria-controls={open ? 'split-button-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-label="select merge strategy"
                                                aria-haspopup="menu"

                                            >
                                                <FontAwesomeIcon style={{ fontSize: "20", float: "right" }} onClick={() => removeAt(index)} icon={faTrash}></FontAwesomeIcon>

                                            </Button>
                                        </ButtonGroup>

                                    );
                                })}
                                <br></br><br></br>
                                <Button variant="contained" onClick={() => send()} style={{ width: "100%", marginTop: "10px" }}>
                                    SEND
                                </Button>
                            </>
                        ) : (
                            <DividerControl>You are not member of any group but you can join the group if you accept the invitation</DividerControl>
                        )}

                    </div>
                </>
            )}
        </div>
    );
}

export default Invitations;
