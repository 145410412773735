import React from "react";
import "./css/App.css";
import Navigation from "./components/navigation";
import "antd-mobile/dist/antd-mobile.css"; // or 'antd-mobile/dist/antd-mobile.less'
//import { I18nextProvider, useTranslation } from "react-i18next";
//import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Oval } from "react-loader-spinner";
import { useState, useRef } from "react";
import database from "./util/database";
import util from "./util/util";
import GlobalContext from "./components/globalContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LoaderControl from '../src/components/controls/loaderControl';

import $ from "jquery";


const useConstructor = util.useConstructor();



// if (theme == "dark") {
//     $('body').css('backgroundImage', 'linear-gradient(rgb(36, 36, 36), rgb(56, 56, 56));');
// } else {
//     $('body').css('backgroundImage', 'linear-gradient(rgb(255, 255, 255), rgb(185, 185, 185))');
// }

function App(props) {
    const [loading, setLoading] = useState(false);
    const [showBack, setShowBack] = useState(true);
    const [selectedTab, setSelectedTab] = useState("home");
    const [selectedTheme, setSelectedTheme] = useState("home");
    const [teamName, setTeamName] = useState(database.get("teamName") || "FUTSA");
    const [iAmAdmin, setIAmAdmin] = useState(0);

    const showBackCallback = () => {
        setShowBack(true);
    };

    const onSetSelectedTab = (index) => {
        setSelectedTab(index);
    };

    const onSetTeamName = (name) => {
        setTeamName(name);
    };

    const onSetLoading = (data) => {
        setLoading(data);
    };

    const onSetIAmAdmin = (data) => {
        setIAmAdmin(data);
    };

    useConstructor(() => {

        var theme = database.get("theme");

        if (!theme) {
            util.setTheme("dark");
        }else{
            util.setTheme(theme);
        }

        document.addEventListener('deviceready', function () {

            document.addEventListener("backbutton", function (e) {
                e.preventDefault();
            }, false );

            util.getPermissionForAndroidNotifications();
            // util.getPermissionForiOSNotifications();
            
            if (!theme) {
                util.setTheme("dark");
            }else{
                util.setTheme(theme);
            }
            
            if(cordova){
                cordova.getAppVersion.getVersionNumber(function (version) {
                    database.set("appversion", version);
                });

                
                // window.FirebasePlugin.grantPermission(function (hasPermission) {
                //     // alert(
                //     //     "Notifications permission was " + (hasPermission ? "granted" : "denied")
                //     // );
                // });

            }else{
                database.set("appversion", "1.4.56");
            }

        }, false);


        let st = createTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#003d78',

                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                },
                secondary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#003d78',

                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                },
                mode: theme == "light" ? "light" : "dark",
            },
        });

        setSelectedTheme(st);

        let ppa = database.get("privacyPolicyAccepted");
        const params = util.getParametersFromUrl(window.location);

        if (!ppa && !params.uid) {
            database.set("user", null);
            window.location = "#/privacyPolicy";
            return;
        }

    });

    return (
        <ThemeProvider theme={selectedTheme}>
            <div className={database.get("theme") == "light" ? "body-light" : "body-dark"} style={{}}>
                {loading ? (
                    <LoaderControl></LoaderControl>
                ) : (
                    <GlobalContext.Provider value={{ teamName: teamName, setTeamName: onSetTeamName, showBack: showBack, showBackCallback: showBackCallback, selectedTab: selectedTab, setSelectedTab: onSetSelectedTab, loading, setLoading: onSetLoading, iAmAdmin: iAmAdmin, setIAmAdmin: onSetIAmAdmin }}>
                        <Navigation selectedTab={selectedTab} style={{ height: "100%" }}></Navigation>
                    </GlobalContext.Provider>
                )}
            </div>
        </ThemeProvider>
    );
}

export default App;
