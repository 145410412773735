import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import database from "./util/database";
import util from "./util/util";

const renderReactDom = () => {
    ReactDOM.render(
     
            <App />
      ,
        document.getElementById("root")
    );
};

database.set("DeviceUUID", "DEBUG-1"); 

if (window.cordova) {
    document.addEventListener(
        "deviceready",
        () => {

            // alert("device UUID - " + window.device.uuid);

            if(window.device){
                database.set("DeviceUUID", window.device.uuid); 
                //alert(window.device.uuid);
            }
            renderReactDom();
        },
        false
    );
} else {
    renderReactDom();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
