import React, { useState, useRef, useContext } from "react";

import { faParking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";
import util from "../util/util";
import database from "../util/database";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../util/api";

function Language(props) {
    var languages = database.get("languages");
    const [loading, setLoading] = useState(false);

    return (
        <div className="component-container">
            {!loading ? (
                <div className="centered">
                    {languages.map((language) => {
                        return (
                            <div
                                key={language.shortName}
                                className="menu-item"
                                onClick={() => {
                                    util.setLanguage(language);

                                    setLoading(true);
                                    api.post("MobileApi/UpdateSettings", {LanguageID: database.get("langID"), UserDeviceGuid: database.get("UserDeviceGUID")}, (data) => {
                                        window.location = "#/home";
                                        window.location.reload();
                                    })


                                }}
                            >
                                {language.name}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div style={{ marginTop: "50vh", marginLeft: "50vw" }}>
                    <CircularProgress style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", marginLeft: "-20px", marginTop: "-20px" }} />
                </div>
            )}
        </div>
    );
}

export default Language;
