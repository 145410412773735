import React, { useState, useEffect } from "react";
import "../css/FootballPitch.css";
import FootballPitch from "./FootballPitch2";
import Team1img from '../images/team1.png';
import Team2img from '../images/team2.png';
import Team0img from '../images/team.png';
import { Button } from "antd-mobile";
import ButtonControl from "./controls/buttonControl";

const Test = () => {
    const [players, setPlayers] = useState([
        { PlayerID: 1, Nick: "Livaković", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 2, Nick: "Thuram", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 3, Nick: "Vida", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 4, Nick: "Brozović", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 5, Nick: "Jarni", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 6, Nick: "Zidane", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 7, Nick: "Ronaldo", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 8, Nick: "Ibrahimović", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 9, Nick: "Mbappe", PositionX: 50, PositionY: 10, ForTeam: 0 },
        { PlayerID: 10, Nick: "Cafu", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 11, Nick: "Lovren", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 12, Nick: "Šimić", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 13, Nick: "Roberto Carlos", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 14, Nick: "Luka Modrić", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 15, Nick: "Christiano Ronaldo", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 16, Nick: "Maradona", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 17, Nick: "De Bruyne", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 18, Nick: "Messi", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 19, Nick: "Haaland", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 },
        { PlayerID: 20, Nick: "Neymar", PositionX: Math.random() * 100, PositionY: Math.random() * 100, ForTeam: 0 }


    ]);

    const [height, setHeight] = useState();

    useEffect(()=>{
        setHeight(Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 55);
    });

    const handleDrop = (event) => {
        event.preventDefault();
        const playerId = event.dataTransfer.getData("playerId");
        const player = document.getElementById(playerId);
        player.style.display = "block";
        event.target.appendChild(player);
        setPlayers([...players, playerId]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    function drag(ev) {
        ev.dataTransfer.setData("text", ev.target.id);
    }

    return (
        <div style={{ height: height, width: "100%", position:"relative" }}>
            <FootballPitch width="100%" height="100%" mode="edit" buttons substitutions team1img={Team1img} team2img={Team2img} players={players}></FootballPitch>
        </div>

    );
};

export default Test;
