import React, { useState, useRef, useContext } from "react";
import { NavBar, Icon, Popover } from "antd-mobile";
import { faArrowLeft, faParking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideMenu from "./sideMenu";
import Hamburger, { Divide } from "hamburger-react";
import $ from "jquery";
import database from "../util/database";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Notifications from "../components/notifications";
import Logo from "../images/logo.png";
import GlobalContext from "../components/globalContext";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TeamEditor from "../components/teamEditor";
import { ButtonControl, PageTitleControl, DividerControl, TextBoxControl, LabelControl } from "../components/controls/controls";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import util from "../util/util";
import About from "../components/about";
import ShareIcon from "@mui/icons-material/Share";

function TopBarMenu(props) {
    const [showNotificationAlert, setShowNotificationAlert] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [openTeamInfo, setOpenTeamInfo] = useState(false);
    const [openAbout, setOpenAbout] = useState(false);
    const global = useContext(GlobalContext);

    // document.addEventListener(
    //     "deviceready",
    //     () => {
    //         if (window.FirebasePlugin) {
    //             window.FirebasePlugin.onMessageReceived(
    //                 (e) => {
    //                     addNotification(e.body);
    //                     // alert(e.jsonData);
    //                     // let ob = eval(e.jsonData);
    //                     // alert(ob[1].Nick);
    //                 },
    //                 function (data) {
    //                     //alert(error);
    //                     util.throwExcMessage(data);
    //                 }
    //             );
    //         }
    //     },
    //     false
    // );

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function onMenuClick(menu) {
     
        handleClose();
        switch (menu) {
            case "light-theme":
                util.setTheme("light");
                location.reload();
                break;
            case "dark-theme":
                util.setTheme("dark");
                location.reload();
                break;
            case "home":
                window.location = "#/home";
                break;

            case "logout":
                if (props.onMenuClick) {
                    props.onMenuClick(menu);
                }
                break;

            case "players":
                window.location = "#/playerList";
                break;

            case "about":
                setOpenAbout(true);
                break;

            case "share":
                const options = {
                    message: database.get("selectedTeam").Name, // not supported on some apps (Facebook, Instagram)
                    subject: 'Standings', // fi. for email
                    url: "http://futsa.online/#/teamPage?uid=" + database.get("TeamUID"),
                    chooserTitle: 'Whatsapp' // Android only, you can override the default share sheet title
                };

                window.plugins.socialsharing.shareWithOptions(
                    options,
                    () => {
                        node.style.backgroundColor = "transparent";
                    },
                    () => {
                        node.style.backgroundColor = "transparent";
                    }
                );
                break;

            case "editGroup":
                setOpenTeamInfo(true);
                break;


        }
    }

    function onShowNotifications() {
        setShowNotificationAlert(false);
        setShowNotifications(!showNotifications);
    }

    function addNotification(e) {
        //let notifics = database.get("notifications") || [];
        setShowNotificationAlert(true);
        let notifics = [...notifications];
        //alert("tekst - " + e.body.Text);

        notifics.unshift(e);
        //database.set("notifications", notifics);
        setNotifications(notifics);
    }

    function onOpenTeamInfo() {
        if (global.selectedTab != "home") {
            setOpenTeamInfo(true);
        }else{
            onMenuClick("about");
        }
    }


    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"} >
                <Toolbar>
                    <Typography component="div" sx={{ flexGrow: 1 }}>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td onClick={() => window.location != "#/home" ? window.location = "#/home" : null} >
                                        {global.selectedTab == "home" ?
                                            <img style={{ width: "25px" }} src={Logo}></img> :
                                            <ArrowBackIosIcon></ArrowBackIosIcon>}
                                    </td>
                                    <td style={{ width: "100%", paddingRight: "20px" }} onClick={onOpenTeamInfo}>
                                        {global.selectedTab != "home" && global.selectedTab != "teamEditor" ? (
                                            // <div className="team-title" onClick={() => (window.location = "#/teamEditor?edit=true")}>
                                            //     {global.teamName}
                                            // </div>
                                            <div className="team-title">
                                                {global.teamName}
                                            </div>
                                        ) : <div className="team-title">
                                            FUTSA
                                        </div>}{" "}
                                        <div className="team-subtitle" style={{ fontSize: "10px", opacity: "0.4", marginTop: -9 }}>
                                            {/* {global.selectedTab == "home" || global.selectedTab == "teamEditor" ? "FUTSA" : util.translate("season") + " " + (database.get("season") && database.get("season").Name)} */}
                                            {database.get("username")}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Typography>

                    {/* <IconButton size="large" edge="end" onClick={onShowNotifications} color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                        <NotificationsIcon style={showNotificationAlert ? { color: "#ff8100" } : { color: "white" }} />
                    </IconButton> */}
                    {!window.location.toString().includes("privacyPolicy") ? (
                        <IconButton size="large" edge="end" onClick={handleClick} color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                            <MenuIcon />
                        </IconButton>
                    ) : null}
                </Toolbar>
            </AppBar>
            {/* {this.state.showSideMenu && <SideMenu onMenuClick={this.onMenuClick}></SideMenu>} */}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {!window.location.toString().endsWith("/home") ? (
                    <MenuItem onClick={() => onMenuClick("home")}>
                        <ListItemIcon>
                            <ArrowBackIosIcon></ArrowBackIosIcon>
                        </ListItemIcon>
                        <ListItemText> {util.translate("home")}</ListItemText>
                    </MenuItem>
                ) : null}

                {!window.location.toString().endsWith("/home") && !window.location.toString().endsWith("/teamPage") ? <Divider></Divider> : null}

                {window.location.toString().includes("/teamPage") && global.iAmAdmin ? (
                    <MenuItem
                        onClick={(e) => {
                            onMenuClick("players");
                        }}
                    >
                        {/* <ListItemIcon>
                            <GroupsIcon></GroupsIcon>
                        </ListItemIcon> */}
                        <ListItemText> {util.translate("managePlayers")}</ListItemText>
                    </MenuItem>
                ) : null}

                {window.location.toString().includes("/teamPage") && global.iAmAdmin ? (
                    <MenuItem
                        onClick={(e) => {
                            onMenuClick("editGroup");
                        }}
                    >
                        {/* <ListItemIcon>
                            <GroupsIcon></GroupsIcon>
                        </ListItemIcon> */}
                        <ListItemText> {util.translate("editGroup")}</ListItemText>
                    </MenuItem>
                ) : null}

                {/* {window.location.toString().endsWith("/teamPage") ? <Divider></Divider> : null} */}



                {window.location.toString().includes("/teamPage") && !window.location.toString().includes("futsa.online") ?
                     
                    
                        <MenuItem onClick={() => onMenuClick("share")}>
                            {/* <ListItemIcon>
                                <ShareIcon></ShareIcon>
                            </ListItemIcon> */}
                            <ListItemText>Share</ListItemText>
                        </MenuItem>
                      : null}


              

                {database.get("user") && database.get("user").username == "vedranselendic@gmail.com" && <MenuItem onClick={() => (window.location = "#/tracker")}>Tracker</MenuItem>}

                {database.get("user") && database.get("user").username == "vedranselendic@gmail.com" && <MenuItem onClick={() => (window.location = "#/test")}>Test</MenuItem>}

                <MenuItem onClick={() => onMenuClick("about")}>
                    <ListItemText> {util.translate("about")}</ListItemText>
                </MenuItem>
                {database.get("theme") == "dark" ? (
                    <MenuItem
                        onClick={(e) => {
                            onMenuClick("light-theme");
                        }}
                    >
                        {/* <ListItemIcon>
                            <LightModeIcon></LightModeIcon>
                        </ListItemIcon> */}
                        <ListItemText>{util.translate("lightMode")}</ListItemText>
                    </MenuItem>
                ) : (
                    <MenuItem
                        onClick={(e) => {
                            onMenuClick("dark-theme");
                        }}
                    >
                        {/* <ListItemIcon>
                            <DarkModeIcon></DarkModeIcon>
                        </ListItemIcon> */}
                        <ListItemText>{util.translate("darkMode")}</ListItemText>
                    </MenuItem>
                )}

                <MenuItem onClick={() => onMenuClick("logout")}>
                    <ListItemIcon>
                        <LogoutIcon></LogoutIcon>
                    </ListItemIcon>
                    <ListItemText> {util.translate("logout")}</ListItemText>
                </MenuItem>
            </Menu>

            <Dialog onClose={() => setOpenTeamInfo(false)} open={openTeamInfo} fullScreen>
                <DialogTitle style={{ textAlign: "center" }}>
                    <LabelControl style={{ fontSize: "11px" }}>GROUP INFO</LabelControl>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenTeamInfo(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <TeamEditor onLeaveDelete={() => setOpenTeamInfo(false)} mode="edit"></TeamEditor>
            </Dialog>

            <Dialog onClose={() => setOpenAbout(false)} open={openAbout} fullScreen>
                <DialogTitle style={{ textAlign: "center" }}>
                    <LabelControl style={{ fontSize: "11px" }}>ABOUT</LabelControl>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenAbout(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <About></About>
            </Dialog>
            {showNotifications && <Notifications notifications={notifications}></Notifications>}
        </Box>
    );
}

export default TopBarMenu;
