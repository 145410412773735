import React, { useState, useEffect, useRef } from "react";
import util from "../util/util";
import database from "../util/database";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from '@mui/icons-material/Email';
import Container from "@mui/material/Container";
import api from "../util/api";

const useConstructor = (callBack = () => {}) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
};

function UserProfile(props) {
    
    var formValid = false;

    const [name, setName] = useState(props.name || "");
    const [surname, setSurname] = useState(props.surname || "");
    const [address, setAddress] = useState(props.address || "");
    const [zip, setZip] = useState(props.zip || "");
    const [city, setCity] = useState(props.city || "");
    const [country, setCountry] = useState(props.country || "");
    const [oib, setOib] = useState(props.oib || "");
    const [guid, setGuid] = useState(props.guid || "");
    

    const [username, setUsername] = useState(props.username || "");
    const [usernameError, setUsernameError] = useState(false);
    const [usernameHelper, setUsernameHelper] = useState("");

    const [email, setEmail] = useState(props.email || "");
    const [emailError, setEmailError] = useState(false);
    const [emailHelper, setEmailHelper] = useState("");

    const [phone, setPhone] = useState(props.phone || "");
    const [phoneError, setPhoneError] = useState(false);
    const [phoneHelper, setPhoneHelper] = useState("");

    const [emailNotifTypeID, setEmailNotifTypeID] = useState(props.emailNotifTypeID || "");

    useConstructor(() => {
        let user = database.get("user");
        if (user) {
            setName(user.firstName);
            setSurname(user.lastName);
            setAddress(user.address);
            setZip(user.zip);
            setCity(user.city);
            setCountry(user.country);
            setEmail(user.email);
            setPhone(user.phone);
            setGuid(user.guid);
            setEmailNotifTypeID(user.emailNotifTypeID);
            setUsername(user.username.toUpperCase());
        }
    });

    function handleEmailNotifTypeChange(data) {
        setEmailNotifTypeID(data);
    }

    function handleEmailChange(data) {
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data) || data == ''){
            setEmailError(false);
            setEmailHelper("");
        } else {
            setEmailError(true);
            setEmailHelper(util.translate("neispravan-email"));
            formValid = false;
          }
        setEmail(data);
    }

    function handlePhoneChange(data) {
        if(/^(?<phone>[\d]{7,13})$/.test(data) || data == ''){
            setPhoneError(false);
            setPhoneHelper("");
        } else {
            setPhoneError(true);
            setPhoneHelper(util.translate("neispravan-tel-broj"));
            formValid = false;
          }
        setPhone(data);
    }

    // function handleNewPwd1Change(){

    // }

    // function handleNewPwd2Change(){

    // }

    function handleSaveClick(){
        formValid = true;
        handlePhoneChange(phone);
        handleEmailChange(email);
        if(formValid){
            //save podataka
            let userModel = {
                Guid: guid,
                FirstName: name,
                LastName: surname,
                Address: address,
                City: city,
                Zip: zip,
                Country: country,
                Phone: phone,
                Email: email,
                Oib: oib,
                EmailNotifTypeID: emailNotifTypeID
            }
            api.post("TokenMobileApi/saveexistinguser",userModel,(result) => {successCbk(result)},(errorRes) => {errorCbk(errorRes)});

        }
    }

    function successCbk(result){
        window.location = "#/home";
        //alert(result.Message);
    }

    function errorCbk(result){
        alert(result.Message);
    }

    return(
        <div className="component-container">
            <Container maxWidth="sm">
                <FormControl fullWidth>
                        {/* <TextField value={username} onChange={(e) => setName(e.target.value)} label={util.translate("korisnicko-ime")} variant="outlined" style={{ marginTop: 10, width:"100%"}}/>
                        <br></br>
                        <TextField value={oldPwd} label={util.translate("lozinka")} variant="outlined" style={{ marginTop: 10, width:"100%"}}/>
                        <br></br>
                        <TextField value={newPwd1} onChange={handleNewPwd1Change} label={util.translate("nova-lozinka")} variant="outlined" style={{ marginTop: 10, width:"100%"}}/>
                        <br></br>
                        <TextField value={newPwd2} onChange={handleNewPwd2Change} label={util.translate("ponovite-lozinku")} variant="outlined" style={{ marginTop: 10, width:"100%"}}/>
                        <br></br> */}
                        <div style={{marginBottom: 20, fontSize: "large", textAlign:"center"}}>{username}</div>
                        <TextField value={name} onChange={(e) => setName(e.target.value)} label={util.translate("ime")} variant="outlined" style={{ width:"100%"}}/>
                        <br></br>
                        <TextField value={surname} onChange={(e) => setSurname(e.target.value)} label={util.translate("prezime")} variant="outlined" style={{ width:"100%"}} />
                        <br></br>
                        <TextField value={address} onChange={(e) => setAddress(e.target.value)} label={util.translate("adresa")} variant="outlined" style={{ width:"100%" }} />
                        <br></br>
                        <TextField value={zip} onChange={(e) => setZip(e.target.value)} label={util.translate("postanski-broj")} variant="outlined" style={{ width:"100%" }} />
                        <br></br>
                        <TextField value={city} onChange={(e) => setCity(e.target.value)} label={util.translate("grad")} variant="outlined" style={{ width:"100%" }} />
                        <br></br>
                        <TextField value={country} onChange={(e) => setCountry(e.target.value)} label={util.translate("drzava")} variant="outlined" style={{ width:"100%"}} />
                        <br></br>
                        <TextField value={oib} onChange={(e) => setOib(e.target.value)} label={util.translate("pid")} variant="outlined" style={{ width:"100%"}} />
                        <br></br>
                        <TextField value={email} error={emailError} helperText={emailHelper} onChange={(e) => handleEmailChange(e.target.value)} label={util.translate("email-adresa")} variant="outlined" style={{ width:"100%" }} />
                        <br></br>
                        <TextField value={phone} error={phoneError} helperText={phoneHelper} onChange={(e) => handlePhoneChange(e.target.value)} label={util.translate("telefon")} variant="outlined" style={{ width:"100%" }} />
                        <br></br>
                        <FormControl fullWidth>
                            <InputLabel>{util.translate("email-notif")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={util.translate("email-notif")}
                                onChange={(e) => handleEmailNotifTypeChange(e.target.data)}
                                value={emailNotifTypeID}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem key={"emailNotifTypeID0"} value={0}>
                                    {util.translate("email-notif-0")}
                                </MenuItem>
                                <MenuItem key={"emailNotifTypeID1"} value={1}>
                                    {util.translate("email-notif-1")}
                                </MenuItem>
                                <MenuItem key={"emailNotifTypeID2"} value={2}>
                                    {util.translate("email-notif-2")}
                                </MenuItem>
                            </Select>
                        </FormControl>                                              
                </FormControl>
                <FormControl fullWidth style={{marginTop:"10px"}}>
                    <div className="mycontainer">
                        <Button onClick={handleSaveClick} variant="contained"                 
                        >
                            {util.translate("save")}
                        </Button>
                    </div>
                </FormControl>
            </Container>      
        </div>
    );
}

export default UserProfile;
