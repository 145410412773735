import React, { useState, useRef, useContext } from "react";
import { faParking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import api from "../util/api";
import LoaderControl from '../components/controls/loaderControl';

const useConstructor = (callBack = () => {}) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
};

function SuccessForm(props) {
    let params = util.getParametersFromUrl(window.location);
    const [uid, setUid] = useState(params.uid);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    useConstructor(() => {
        const data = { guid: params.uid };

        if (params.error) {
            setError(params.error);
            setLoading(false);
        } else {
            api.post(
                "TokenMobileApi/GetTransactionInformation",
                data,
                (data) => {
                    setUid(data.OrderID);
                    setLoading(false);
                },
                (error) => {}
            );
        }
    });

    return loading ? (
        <LoaderControl></LoaderControl>
    ) : (
        <div className="component-container">
            <div className="centered-container">
                {error ? (
                    <div>{error}</div>
                ) : (
                    <>
                        <div className="checkmark_wrapper">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                        <p style={{ color: "gray" }}>
                            {util.translate("successfulPayment")}
                            <br></br>
                            {util.translate("transactionID")}
                            <br></br>
                            {uid}
                        </p>
                    </>
                )}
            </div>
        </div>
    );
}

export default SuccessForm;
