import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";

import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import GlobalContext from "../components/globalContext";
import { faParking, faMoneyCheck, faUser, faFutbol, faGlasses, faTrash, faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import PlayerEditor from "../components/playerEditor";
import { ButtonControl, PageTitleControl, DividerControl, TextBoxControl, LabelControl, Snack, MuiButton } from "../components/controls/controls";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PhoneIcon from "@mui/icons-material/Phone";
import Team1img from "../images/team1.png";
import Team2img from "../images/team2.png";
import Team0img from "../images/team.png";
import Paper from "@mui/material/Paper";
import LoaderControl from "../components/controls/loaderControl";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Shirt from './shirt';

const useConstructor = util.useConstructor();

function PlayerList(props) {
    const [players, setPlayers] = useState([]);
    const [player, setPlayer] = useState({ Nick: "", Email: "", Mobile: "" });
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [snackMessage, setSnackMessage] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        window.location = "#/addplayers";
        // setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useConstructor(() => {
        load();
    });

    function load() {
        api.get("GetAllPlayer?teamID=" + database.get("selectedTeam").TeamID, success, fail);
    }

    function success(data) {
        setLoading(false);
        setPlayers(data);
    }

    function fail(data) {
        setLoading(false);
        setSnackMessage(data.ErrorMessage);
    }

    function onAdd() {
        let items = [...players];
        let player = {
            Name: name,
            Email: email,
            Mobile: mobile,
        };

        items.push(player);
        setPlayers(items);
        setName("");
        setEmail("");
        setMobile("");
    }

    function removeAt(index) {
        if (confirm("Delete player?")) {
            let pl = players[index];
            setLoading(true);
            api.post("DeletePlayer", pl, (data) => {
                setLoading(false);
                if (data.Success == 0) {
                    setSnackMessage(data.ErrorMessage);
                } else {
                    players.splice(index, 1);
                    let items = [...players];
                    setPlayers(items);
                    setLoading(false);
                }
            });
        }
    }

    function edit(item, index) {
        setSelectedIndex(index);
        setPlayer(item);
        setEditing(true);
    }

    function save(item) {
        setLoading(true);
        api.post("UpdatePlayer", item, (data) => {
            setLoading(false);
            if (data.ErrorMessage)
                setSnackMessage(data.ErrorMessage);

            if (data.Success) {
                //setPlayer(null);


                let newPlayers = [...players];
                newPlayers[selectedIndex] = data;
                setPlayers(newPlayers);
                setEditing(false);
            } else {

            }
        });
    }

    function deletePlayer(p) {
        setLoading(true);
        api.post(
            "DeletePlayer",
            p,
            (data) => {
                if (data.Success) {
                    let newPlayers = [...players];
                    let i = newPlayers.indexOf(p);
                    newPlayers.splice(i, 1);
                    setPlayers(newPlayers);
                    setEditing(false);
                    setLoading(false);
                } else {
                    alert("Error while deleting player.");
                }
            },
            () => {
                alert("Error while deleting player.");
                setLoading(false);
            }
        );
    }

    function cancel(item) {
        setPlayer(null);
        setEditing(false);
    }

    function call(player) {
        // if(cordova){
        //     alert("cordova");
        //     if( cordova.plugins){
        //         alert(" cordova.plugins");
        //         if(cordova.plugins.phonedialer){

        //         }
        //     }

        // }
        cordova.plugins.phonedialer.dial(
            player.Mobile,
            function (success) { },
            function (err) {
                if (err == "empty") alert("Unknown phone number");
                else alert("Dialer Error:" + err);
            },
            null,
            null
        );
    }

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <>
                    {/* <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem onClick={() => (window.location = "#/addplayers")}>Add players manually</MenuItem>
                        <MenuItem onClick={() => (window.location = "#/addplayers?fromContacts=true")}>Add players from contacts</MenuItem>
                    </Menu> */}

                    <Snack open={snackMessage} onClose={() => setSnackMessage(null)} message={snackMessage} type="error"></Snack>
                    <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
                        <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                            <Toolbar>
                                <IconButton
                                    onClick={(e) => {
                                        window.location = "#/teamPage";
                                    }}
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 1 }}
                                >
                                    <ArrowBackIosIcon />
                                </IconButton>
                                <Typography style={{ textAlign: "left" }} component="div" sx={{ flexGrow: 1 }}>
                                    {util.translate("managePlayers")}
                                </Typography>
                                <IconButton onClick={handleClick} size="large" edge="end" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                    <PersonAddAlt1Icon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Box>

                    <div className="fixed-container-playerList">
                        <Dialog onClose={() => setEditing(false)} open={editing}>
                            <DialogTitle style={{ textAlign: "center" }}>
                                <LabelControl style={{ fontSize: "11px" }}>{util.translate("editPlayer")}</LabelControl>
                                <IconButton
                                    aria-label="close"
                                    onClick={() => setEditing(false)}
                                    sx={{
                                        position: "absolute",
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <PlayerEditor onSave={save} onCancel={() => setEditing(false)} onDelete={deletePlayer} players={players} player={player}></PlayerEditor>
                        </Dialog>

                        <div style={{ padding: "20px", paddingBottom: "100px" }}>
                            <>
                                {players.map((item, index) => {
                                    return (
                                        <MuiButton
                                            className={database.get("theme") == "dark" ? "darkgray-dark" : "darkgray-light"}
                                            variant={database.get("theme") == "light" ? "" : "contained"}
                                            key={"match" + index}
                                            style={{ width: "100%", marginTop: "2px", textAlign: "left", float: "left", display: "inline" }}
                                            noTimeOut
                                            onClick={() => edit(item, index)}
                                        >

                                            <>
                                                {item.UserID ? (
                                                    <span >
                                                        <AccountCircleIcon style={{ fontSize: "20px", float: "right", fontWeight: "bold", color: "#69e769" }}></AccountCircleIcon>
                                                    </span>
                                                ) : null}

                                                {item.UserID && item.IsAdmin ? (
                                                    <span >
                                                        <AdminPanelSettingsIcon style={{ fontSize: "20px", float: "right", fontWeight: "bold", color: "#69e769" }}></AdminPanelSettingsIcon>
                                                    </span>
                                                ) : null}


                                                <span style={{ fontSize: "14px", float: "left", fontWeight: "bold" }}>{item.Nick}</span> 
                                                {/* <span style={{marginLeft:10, fontSize: "14px", float: "left", fontWeight: "bold", color: "#69e769" }}>{item.Number}</span> */}
                                                <br></br>
                                                {item.Email ? <span style={{ fontSize: "10px", float: "left", marginRight: "5px", marginTop: "-5px", opacity: 0.7 }}> {item.Email}</span> : null}


                                            </>
                                        </MuiButton>
                                        // <div key={"pl1" + index} className={database.get("theme") == "light" ? "trplayer-light" : "trplayer-dark"} >
                                        //     <table style={{ width: "100%" }}>
                                        //         <tbody>
                                        //             <tr>
                                        //                 {/* <td style={{ width: "40px", textAlign: "left" }}>
                                        //                             {item.ForTeam == 1 && <img style={{ width: "30px" }} src={Team1img}></img>}
                                        //                             {item.ForTeam == 2 && <img style={{ width: "30px" }} src={Team2img}></img>}
                                        //                             {item.ForTeam == 0 && <img style={{ width: "30px" }} src={Team0img}></img>}
                                        //                         </td> */}
                                        //                 <td onClick={() => edit(item, index)} style={{ textAlign: "left", wordWrap: "break-word" }}>
                                        //                     <LabelControl style={{ fontWeight: "bold" }}>{item.Nick}</LabelControl>
                                        //                     {/* <LabelControl style={{ fontSize: "9px", marginTop: "-5px", wordWrap:"break-word" }}>{item.Email}</LabelControl> */}
                                        //                 </td>
                                        //             </tr>
                                        //         </tbody>

                                        //     </table>
                                        // </div>
                                    );
                                })}
                            </>
                            <br></br><br></br><br></br><br></br><br></br>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default PlayerList;
