import React, { useState, useRef, useContext } from "react";
import { faParking, faMoneyCheck, faUsers, faFutbol, faGlasses } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import database from "../../util/database";
import { I18nextProvider, useTranslation } from "react-i18next";
import $ from "jquery";
import CircularProgress from "@mui/material/CircularProgress";
import { alertTitleClasses } from "@mui/material";

import { Oval } from "react-loader-spinner";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Team1img from '../../images/team1.png';
import Team2img from '../../images/team2.png';
import Team0img from '../../images/team.png';
import Button from '@mui/material/Button';
// const useConstructor = util.useConstructor();

let MuiButton = (props) => {
    // useConstructor(() => {

    // });
    const [selected, setSelected] = useState(props.selectedd ? props.selectedd : false);


    return (
        <Button
            disabled={props.disabled}
            style={props.style}
            variant={props.variant}
            endIcon={props.endIcon}
            color={props.color}
            className={props.className}
            // className={database.get("theme") == "light" ? "mui-button-light" : "mui-button-dark"}
            startIcon={props.startIcon}
            onClick={(e) => {

                if (props.fast) {
                    props.onClick(e);
                } else {
                    setTimeout(() => {
                        props.onClick(e);
                    }, 300)
                }

            }}
        >
            {props.children}
        </Button>

    );
};

export default MuiButton;
