import React, { useState, useRef, useContext, Profiler, useEffect, useLayoutEffect } from "react";
import database from "../util/database";
import { faParking, faMoneyCheck, faUsers, faFutbol } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../util/api";
import util from "../util/util";
import { ButtonControl, PageTitleControl, LabelControl, DividerControl, MuiButton } from "../components/controls/controls";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CloseMatch from "../components/closeMatch";
import Paper from "@mui/material/Paper";
import { fn } from "jquery";
import Button from "@mui/material/Button";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SportsIcon from "@mui/icons-material/Sports";
import DeleteIcon from "@mui/icons-material/Delete";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import { Oval } from "react-loader-spinner";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import GroupsIcon from "@mui/icons-material/Groups";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MatchDetails from "../components/matchDetails";
import { DashboardCustomizeSharp, ElectricScooterTwoTone, ElevatorTwoTone } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import Team1img from "../images/team1.png";
import Team2img from "../images/team2.png";
import Team0img from "../images/team.png";
import Boot from "../images/boot.png";
import Trophy from "../images/trophy16.png";
import GoldenBoot from "../images/goldenboot16.png";
import LoaderControl from "../components/controls/loaderControl";
import PlayerProfile from "../components/playerProfile";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import html2canvas from "html2canvas";
import CloseIcon from "@mui/icons-material/Close";
import $ from "jquery";
import NewMatch from "./newMatch";
import EndMatch from "../components/endMatch";
import SelectPlayers from "../components/selectPlayers2";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GlobalContext from "../components/globalContext";
import FootballPitch from "../components/FootballPitch2";
import Beer from "../images/beer.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import HelpIcon from "@mui/icons-material/Help";
import BarChartIcon from '@mui/icons-material/BarChart';
import HistoryIcon from '@mui/icons-material/History';
import Shirt from "./shirt";
import Playstore from "../images/playstore.png";
// import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";

const useConstructor = util.useConstructor();

function TeamPage(props) {
    const [loading, setLoading] = useState(true);
    const [loadingIAmComming, setLoadingIAmComming] = useState(false);
    const [value, setValue] = React.useState(0);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [playerID, setPlayerID] = React.useState(0);
    const [players, setPlayers] = React.useState([]);
    const [allPlayers, setAllPlayers] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [playersToBe, setPlayersToBe] = React.useState([]);
    const [match, setMatch] = React.useState();
    const [matches, setMatches] = React.useState();
    const [teamName, setTeamName] = React.useState();
    const [playerStats, setPlayerStats] = React.useState();
    const [sortColumn, setSortColumn] = React.useState("KOEF");

    const [openProfile, setOpenProfile] = React.useState(false);
    const [newMatch, setNewMatch] = React.useState(false);
    const [newMatchUpdate, setNewMatchUpdate] = React.useState(false);
    const [overalStats, setOveralStats] = React.useState();
    const [team1Stats, setTeam1Stats] = React.useState();
    const [team2Stats, setTeam2Stats] = React.useState();
    const [matchDetailsMatchID, setMatchDetailsMatchID] = React.useState(false);
    const [teamStats, setTeamStats] = React.useState();
    const [closingMatch, setClosingMatch] = React.useState(false);
    const [selectedMatch, setSelectedMatch] = React.useState(null);
    const [date, setDate] = React.useState();
    const [dateString, setDateString] = React.useState();
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [selectedTeamStats, setSelectedTeamStats] = React.useState(0);
    const [selectedPlayerID, setSelectedPlayerID] = React.useState();
    const [endMatch, setEndMatch] = React.useState(false);
    const [pickPlayers, setPickPlayers] = React.useState(false);
    const [pickPositionPlayerID, setPickPositionPlayerID] = React.useState();
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const [firstTime, setFirstTime] = React.useState(false);
    const [iAmCommingType, setIAmCommingType] = React.useState(false);
    const [height, setHeight] = useState();
    const [matchesScrollTop, setMatchesScrollTop] = useState(0);
    const [matchDayTabScrollTop, setMatchDayTabScrollTop] = useState(0);
    const [standingsScrollTop, setStandingsScrollTop] = useState(0);
    const [preview, setPreview] = useState(false);
    const [team, setTeam] = useState({
        Team1Color: "blue",
        Team2Color: "red",
        Team1Color: "white",
        Team2Color: "black",
        Team1Name: "Team 1",
        Team2Name: "Team 2"
    });

    const global = useContext(GlobalContext);

    function onMessageReceived(e, m) {
        if (e.type == 5) {
            //match ended
            load();
        }

        if (e.type == 3) {
            //new player is comming to match
            load();
        }

        if (e.type == 2) {
            //new match
            load();
        }

        if (e.type == 6) {
            //you become admin
            load();
        }
    }

    function addPlayerToState(player, mtc) {
        let m = { ...mtc };
        let pls = [...m.Players];
        let existingPls = pls.filter((x) => x.PlayerID == player.PlayerID);
        if (existingPls && existingPls.length > 0) {
            existingPls[0] = player;
            m.Players = existingPls;
        } else {
            pls.push(player);
            m.Players = pls;
        }

        setMatch(m);
        setPlayers(pls);
    }

    useConstructor(() => {
        // const img = new Image();
        // img.src = Team1img;
        // const img2 = new Image();
        // img2.src = Team2img;
        // const img3 = new Image();
        // img3.src = Team0img;

        document.addEventListener("resume", () => {
            load();
        }, false);

        const img1 = new Image();
        img1.src = Team1img;
        const img2 = new Image();
        img2.src = Team2img;
        const img3 = new Image();
        img3.src = Team0img;

        let params = util.getParametersFromUrl(window.location);
        if (params.firstTime) {
            setFirstTime(true);
        }

        global.setSelectedTab("teamPage");

        if (window.FirebasePlugin) {
            window.FirebasePlugin.onMessageReceived(
                (e) => {
                    onMessageReceived(e, match);
                },
                function (data) {
                    //alert(error);
                    util.throwExcMessage(data);
                }
            );
        }




        load();
    });

    function load() {

        setLoading(true);
        const params = util.getParametersFromUrl(window.location);
        let seasonID = params.seasonID;


        if (params.uid) {
            api.get("GetTeamInfoBYGuid?uid=" + params.uid, success, fail);
            database.set("season", { Name: "Season 1", SeasonID: seasonID });
            setPreview(true);
            return;
        }


        if (!seasonID) {
            seasonID = database.get("season").SeasonID;
        }
        else {
            database.set("season", { Name: "Season 1", SeasonID: seasonID });
            setPreview(true);
        }

        api.get("GetTeamInfo?id=" + seasonID, success, fail);
    }

    useEffect(() => {
        let imc = match && match.MatchStatusTypeID == 1 && !match.IAmCommingStatusID ? true : false;
        setHeight(Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 55);

        if (players) {
            players.map((pl) => {
                if (pl.ForTeam != 0 && pl.PlayerID == playerID) {
                    imc = false;
                }
            });
        }

        if (imc != showIAmCommingToMatchQuestion) {
            console.log(match);
            setShowIAmCommingToMatchQuestion(imc);
        }
    });

    function success(data) {
        // let pls = [];
        // data.PlayerStats.map((data, index) => {
        //     pls.push({PlayerID: data.PlayerID, Nick: data.Nick})
        // })

        global.setIAmAdmin(data.IAmAdmin);

        setLoading(false);
        setPlayerID(data.PlayerID);


        setMatch(data.NextMatch);

        if(data.NextMatch){
            database.set("seasonID", data.NextMatch.SeasonID);
        }

        database.set("match", data.NextMatch);
        database.set("playerID", data.PlayerID);
        database.set("TeamUID", data.UID);

        setMatches(data.Matches);
        setTeamName(data.Name);
        setAllPlayers(data.Players);
        setPlayerStats(data.PlayerStats.filter((x) => x.Sorter == 0));

        setOveralStats(data.PlayerStats.filter((x) => x.Sorter == 0));
        setTeam1Stats(data.PlayerStats.filter((x) => x.Sorter == 1) || []);
        setTeam2Stats(data.PlayerStats.filter((x) => x.Sorter == 2) || []);
        setTeamStats(data.TeamStats || []);
        // let userID = database.get("user").userID;
        // let me = data.Players.filter(x => x.UserID == userID && x.IsAdmin == 1);

        // if (me.length > 0) {
        //     setIsAdmin(data.IAmAdmin);
        // }

        setIsAdmin(data.IAmAdmin);

        if (data.NextMatch)
            setPlayers(data.NextMatch.Players);

        setTeam({
            Team1Color: data.Team1Color,
            Team2Color: data.Team2Color,
            Team1Color2: data.Team1Color2,
            Team2Color2: data.Team2Color2,
            Team1Name: data.Team1Name,
            Team2Name: data.Team2Name
        })


        let st = database.get("selectedTeam");
        st.Team1Color = data.Team1Color;
        st.Team2Color = data.Team2Color;
        st.Team1Color2 = data.Team1Color2;
        st.Team2Color2 = data.Team2Color2;
        st.Team1Name = data.Team1Name;
        st.Team2Name = data.Team2Name;

        database.set("selectedTeam", st);


    }

    function fail(data) {
        setLoading(false);
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    }

    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSelectedTabIndex(newValue);
        saveScrolls();
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const onSave = (data) => {
        setLoading(true);
        setEndMatch(false);
        setClosingMatch(false);
        // setSelectedMatch(data);
        data.TeamID = database.get("selectedTeamID");
        api.post("CloseMatch", data, (newData) => {
            if (selectedTabIndex == 2) {
                setSelectedMatch(data);
            }

            success(newData);
            setLoading(false);

            // let mtchs = [...matches];
            // mtchs.unshift(data);
            // setMatches(mtchs);
            // setMatch(newData);
            // setLoading(false);
        });
    };

    const onSelectPlayers = (data) => {
        setLoading(true);
        if (pickPositionPlayerID) {
            iAmCommingUpdate(data);
        } else {
            let m = selectedTabIndex == 2 ? selectedMatch : match;
            m.Players = [...data];
            // setSelectedMatch(m);
            api.post("SaveMatchPlayers", m, (newData) => onSelectPlayersSuccess(newData), fail);
        }
    };

    function onSelectPlayersSuccess(data) {
        // if (selectedTabIndex == 2) {
        //     let m = { ...selectedMatch };
        //     m.Players = data;
        //     setSelectedMatch(m);
        // } else {
        //     setPlayers(data);
        // }
        success(data);
        setPickPlayers(false);
        setLoading(false);
    }

    const cancel = () => {
        setClosingMatch(false);
        setPlayersToBe([]);
    };

    const applyPlayersToBe = (pls) => {
        setPlayersToBe(pls);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    function onMenuClose() {
        setMenuOpen(false);
        setAnchorEl(null);
    }

    function iAmCommingUpdate(data, type) {
        if (!data) {
            data = {
                PlayerID: database.get("playerID"),
                ForTeam: 0,
                PositionX: 0,
                PositionY: 0,
                Type: type,
            };
        }

        let url = "IAmComming?matchID=" + match.MatchID + "&playerID=" + data.PlayerID + "&type=" + (iAmCommingType || type) + "&&forTeam=" + data.ForTeam + "&positionX=" + data.PositionX + "&positionY=" + data.PositionY;

        api.get(url, (data) => {
            load();

            // addPlayerToState(data, match);
            setPickPlayers(false);
        });
    }

    function iAmComming(type) {
        setIAmCommingType(type);
        if (type == 1) {
            //  odremati ovo ako hočeš uključiti biranje pozicije, a ono dolje zaremati
            // setPickPositionPlayerID(database.get("playerID"));
            // setPickPlayers("true");
            // return;
            setLoading(true);
            iAmCommingUpdate(null, type);
        } else {
            setPickPositionPlayerID(null);
            let url = "IAmComming?matchID=" + match.MatchID + "&playerID=" + database.get("playerID") + "&type=" + type + "&&forTeam=" + "0" + "&positionX=" + "0" + "&positionY=" + "0";
            setLoading(true);
            api.get(url, (data) => {
                load();
                setLoading(false);
                setPickPlayers(false);
            });
        }

        // setLoading(true);
        // // alert(database.get("user").userID);
        // // alert(match.MatchID);
        // let url = "IAmComming?matchID=" + match.MatchID + "&playerID=" + database.get("playerID") + "&type" + type + "&&forTeam" + ;
        // // alert(url);
        // api.get(url, (data) => {
        //     // alert(data.ErrorMessage);
        //     // alert(JSON.stringify(data));
        //     setMatch(data);
        //     setPlayers(data.Players);
        //     setLoading(false);
        // })
    }

    // function loadMatch(id) {
    //     setLoading(true);
    //     api.get("GetMatch?id=" + id, (data) => {
    //         console.log(data.Players);
    //         setSelectedMatch(data);
    //         setLoading(false);
    //     });
    // }

    function deleteSelectedMatch(matchID) {
        if (confirm("Are you sure you want to delete match?")) {
            setLoading(true);
            api.get("DeleteMatch?matchID=" + matchID, (data) => {
                setMatchDetailsMatchID(null);
                success(data);
                setLoading(false);
            });
        }
    }

    const [showIAmCommingToMatchQuestion, setShowIAmCommingToMatchQuestion] = React.useState(false);

    function share(elementID) {
        var node = document.getElementById(elementID);

        if (elementID == "matchDetails") {
            node.style.backgroundColor = "rgb(49 49 49)";
        }

        html2canvas(node).then(function (canvas) {
            try {
                window.plugins.socialsharing.share(
                    null,
                    "Futsa",
                    canvas.toDataURL(),
                    null,
                    () => {
                        node.style.backgroundColor = "transparent";
                    },
                    () => {
                        node.style.backgroundColor = "transparent";
                    }
                );
            } catch (ex) {
                node.style.backgroundColor = "transparent";
                alert("err - " + ex);
            }
        });
    }

    function onSuccess(result) {
        // alert("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
        // alert("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    }

    function onError(msg) {
        // alert("Sharing failed with message: " + msg);
    }

    function onColumnClick(col) {
        setSortColumn(col);
        selectTeamStats(selectedTeamStats, col);
    }

    function selectTeamStats(index, col) {
        if (!col) col = sortColumn;

        setSelectedTeamStats(index);

        let stats = null;
        if (index == 0) {
            stats = [...overalStats];
        }
        if (index == 1) {
            stats = [...team1Stats];
        }
        if (index == 2) {
            stats = [...team2Stats];
        }

        stats.sort((a, b) => b[col] - a[col]);
        setPlayerStats(stats);
    }

    function profile(playerID) {
        saveScrolls();
        setSelectedPlayerID(playerID);
        setOpenProfile(true);
    }

    function onPlayerClick(player) {
        profile(player.PlayerID);
    }

    function saveScrolls() {
        var el = document.getElementById("matchDayTab");
        if (el) setMatchDayTabScrollTop(document.getElementById("matchDayTab").scrollTop);

        el = document.getElementById("standingsTab");
        if (el) setStandingsScrollTop(document.getElementById("standingsTab").scrollTop);

        el = document.getElementById("matchesTab");
        if (el) setMatchesScrollTop(document.getElementById("matchesTab").scrollTop);
    }

    function newMatchOnSave(data) {
        setLoading(true);
        api.post(
            "AddMatch",
            data,
            (data) => {
                if (data.Success) {
                    window.location.reload();
                } else {
                    alert(data.ErrorMessage);
                }
                // setLoading(false);
                // setMatch(data);
            },
            (err) => {
                alert(err);
            }
        );
    }

    function newMatchOnUpdate(data) {
        setLoading(true);
        api.post(
            "UpdateMatch",
            data,
            (data) => {
                if (data.Success) {
                    window.location.reload();
                } else {
                    alert(data.ErrorMessage);
                }
                // setLoading(false);
                // setMatch(data);
            },
            (err) => {
                alert(err);
            }
        );
    }

    useLayoutEffect(() => {
        // debugger;
        // var matchDayTab = document.getElementById("matchDayTab");
        // if (matchDayTab) {
        //     setMatchDayTabScrollTop(matchDayTab.scrollTop);
        // }
        // var matchesTab = document.getElementById("matchesTab");
        // if (matchesTab) {
        //     setMatchesScrollTop(matchesTab.scrollTop);
        // }
    });

    useEffect(() => {
        var matchDayTab = document.getElementById("matchDayTab");
        if (matchDayTab) {
            matchDayTab.scrollTop = matchDayTabScrollTop;
        }

        var matchesTab = document.getElementById("matchesTab");
        if (matchesTab) {
            matchesTab.scrollTop = matchesScrollTop;
        }

        var standingsTab = document.getElementById("standingsTab");
        if (standingsTab) {
            standingsTab.scrollTop = standingsScrollTop;
        }
    });

    function onMatchClick(id) {

        let st = document.getElementById("matchesTab");
        if (st) setMatchesScrollTop(st.scrollTop);

        setMatchDetailsMatchID(id);
    }

    function onDatePlayedClick() {
        if (global.iAmAdmin) {
            setNewMatchUpdate(true);
        }
    }

    function onResultClick() {
        if (global.iAmAdmin) {
            setEndMatch(true);
        }
    }

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl type="ball"></LoaderControl>
            ) : (
                <>
                    {endMatch ? <EndMatch data={selectedTabIndex == 2 ? selectedMatch : match} team={team} onSave={onSave} onCancel={() => setEndMatch(false)}></EndMatch> : null}
                    {/* 
                    {pickPlayers ?
                        <SelectPlayers data={selectedTabIndex == 2 ? selectedMatch.Players : match.Players} onSave={onSelectPlayers} onCancel={() => setPickPlayers(false)}></SelectPlayers> : null} */}

                    {pickPlayers ? (
                        <div style={{ height: "100%" }}>
                            <FootballPitch matches={matches} mode={"edit"} pickPositionPlayerID={pickPositionPlayerID} width="100%" height={height} buttons onSave={onSelectPlayers} onCancel={() => setPickPlayers(false)} substitutions team1img={Team1img} team2img={Team2img} team={team} players={players}></FootballPitch>
                        </div>
                    ) : null}

                    {!endMatch && !pickPlayers && (
                        <>
                            {/* <PageTitleControl>{database.get("selectedTeam").Name}</PageTitleControl> */}

                            <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
                                <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                                    <Tabs value={value} onChange={handleChange} indicatorColor="secondary" textColor="inherit" variant="fullWidth" aria-label="full width tabs example">
                                        {/* <Tab label={match && match.MatchStatusTypeID != 2 ? "NEXT MATCH" : "LAST MATCH"}  {...a11yProps(0)} style={{ fontSize: "12px" }} /> */}
                                        <Tab label={util.translate("matchDay")} icon={<SportsSoccerIcon></SportsSoccerIcon>} className="fugaz1" {...a11yProps(0)} style={{ fontSize: "12px" }} />
                                        <Tab label={util.translate("standings")} icon={<BarChartIcon></BarChartIcon>} className="fugaz1" {...a11yProps(1)} style={{ fontSize: "12px" }} />
                                        <Tab label={util.translate("matches")} icon={<HistoryIcon></HistoryIcon>} className="fugaz1" {...a11yProps(2)} style={{ fontSize: "12px" }} />
                                        {/* <Tab label="Players" {...a11yProps(3)} style={{ fontSize: "12px" }} /> */}
                                    </Tabs>
                                </AppBar>
                                <BottomNavigation showLabels className="bottom-bar">
                                    {isAdmin && match && match.MatchID != 0 && value == 0 ? (
                                        <BottomNavigationAction
                                            label={util.translate("selectPlayers")}
                                            icon={<TransferWithinAStationIcon />}
                                            onClick={() => {
                                                setPickPositionPlayerID(null);
                                                setPickPlayers(true);
                                                // window.location = "#/players";
                                            }}
                                        />
                                    ) : null}

                                    {isAdmin && match && match.MatchID != 0 && value == 0 ? (
                                        <BottomNavigationAction
                                            label={match.MatchStatusTypeID == 1 ? util.translate("endMatch") : util.translate("changeResult")}
                                            icon={<SportsIcon />}
                                            onClick={() => {
                                                setEndMatch(true);
                                                // window.location = "#/selectResult?teamID=" + database.get("selectedTeamID");
                                            }}
                                        />
                                    ) : null}

                                    {(isAdmin && match && match.MatchID == 0 && value == 0) || (isAdmin && match && match.MatchStatusTypeID == 2 && value == 0) || (isAdmin && !match && value == 0) ? (
                                        <BottomNavigationAction
                                            label={util.translate("newMatch")}
                                            icon={<SportsSoccerIcon />}
                                            onClick={() => {
                                                setNewMatch(true);
                                            }}
                                        />
                                    ) : null}

                                    {(value == 1 || value == 2) && !preview && (
                                        <BottomNavigationAction
                                            label={util.translate("share")}
                                            icon={<ShareIcon />}
                                            onClick={() => {
                                                // if (value == 0) share("matchday");

                                                // if (value == 1) share("standings");

                                                // if (value == 2) share("matches");
                                                // setEndMatch(true);
                                                // window.location = "#/selectResult?teamID=" + database.get("selectedTeamID");
                                                util.shareFutsa();
                                            }}
                                        />
                                    )}
                                </BottomNavigation>
                                <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex}>
                                    <TabPanel value={value} index={0} dir={theme.direction}>
                                        <div className="fixed-container" id="matchDayTab">
                                            <div id="matchday" style={database.get("theme") == "dark" ? { background: "#121212" } : null}>
                                                {/* <LabelControl>{date}</LabelControl> */}
                                                {
                                                    showIAmCommingToMatchQuestion && !preview ? (
                                                        <>
                                                            <LabelControl>{util.translate("areYouCommingToMatch")}</LabelControl>
                                                            <MuiButton style={{ width: "20%", margin: 4 }} variant="contained" onClick={() => iAmComming(1)} color="success">
                                                                Yes
                                                            </MuiButton>
                                                            <MuiButton style={{ width: "20%", margin: 4 }} variant="contained" onClick={() => iAmComming(2)} color="error">
                                                                No
                                                            </MuiButton>
                                                            <MuiButton style={{ width: "20%", margin: 4 }} variant="contained" onClick={() => iAmComming(3)} color="info">
                                                                Maybe
                                                            </MuiButton>
                                                        </>
                                                    ) : null

                                                    // <MuiButton variant="contained" color="success" onClick={() => iAmComming()}
                                                    //     startIcon={<ThumbUpAltIcon></ThumbUpAltIcon>}
                                                    //     loading={loadingIAmComming}
                                                    //     style={{ margin: "4px", width: "70%", height: "60px", fontSize: "10px", marginBottom: "40px!important" }}>
                                                    //     I am comming to match
                                                    // </MuiButton> : null
                                                }

                                                {match && match.MatchID != 0 ? (
                                                    <>
                                                        {/* <LabelControl>{match && match.MatchStatusTypeID != 2 ? "NEW MATCH STARTS" : "MATCH ENDED"} </LabelControl> */}
                                                        {/* <LabelControl><SportsIcon></SportsIcon></LabelControl> */}

                                                        {/* <MatchDetails onPitchDoubleClick={() => setPickPlayers(true)} onPlayerClick={onPlayerClick} hideAppBar data={match}></MatchDetails> */}

                                                        <MatchDetails onResultClick={onResultClick} substitutions onDatePlayedClick={onDatePlayedClick} hideField={match.Players.filter((x) => x.ForTeam != 0).length == 0} onPlayerClick={onPlayerClick} onLineUpPlayerClick={onPlayerClick} team={team} hideAppBar data={match}></MatchDetails>

                                                        <br></br>
                                                        <div style={{ textAlign: "center", width: "100%" }}>
                                                            <table style={{ display: "inline-block" }}>
                                                                <tbody>
                                                                    {match.Players.map((pl, index) => {
                                                                        switch (pl.IAmCommingStatusID) {
                                                                            case 1:
                                                                                return (
                                                                                    <tr>
                                                                                        <td style={{ width: "20px" }}>
                                                                                            <LabelControl>
                                                                                                <CheckCircleIcon style={{ fontSize: 20, color: "#00ffa1" }}></CheckCircleIcon>
                                                                                            </LabelControl>
                                                                                        </td>
                                                                                        <td style={{ textAlign: "left" }}>
                                                                                            <LabelControl style={{ fontSize: 12, marginTop: -6, marginLeft: 5 }}> {pl.Nick} is comming</LabelControl>
                                                                                        </td>
                                                                                    </tr>
                                                                                );

                                                                            case 3:
                                                                                return (
                                                                                    <tr>
                                                                                        <td style={{ width: "20px", textAlign: "left" }}>
                                                                                            <LabelControl>
                                                                                                <HelpIcon style={{ fontSize: 20, color: "#6bbdff" }}></HelpIcon>
                                                                                            </LabelControl>
                                                                                        </td>
                                                                                        <td style={{ textAlign: "left" }}>
                                                                                            <LabelControl style={{ fontSize: 12, marginTop: -6, marginLeft: 5 }}> {pl.Nick} maybe comming</LabelControl>
                                                                                        </td>
                                                                                    </tr>
                                                                                );

                                                                            case 2:
                                                                                return (
                                                                                    <tr>
                                                                                        <td style={{ width: "20px", textAlign: "left" }}>
                                                                                            <LabelControl>
                                                                                                <RemoveCircleIcon style={{ fontSize: 20, color: "#ed124a" }}></RemoveCircleIcon>
                                                                                            </LabelControl>
                                                                                        </td>
                                                                                        <td style={{ textAlign: "left" }}>
                                                                                            <LabelControl style={{ fontSize: 12, marginTop: -6, marginLeft: 5 }}> {pl.Nick} is not comming</LabelControl>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                        }
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {window.location.toString().includes("futsa.online") ?
                                                            <a href="https://play.google.com/store/apps/details?id=com.vsprojects007.futsa">
                                                                <img style={{ width: 200, marginBottom: 100 }} src={Playstore}></img>
                                                            </a> : null}
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                    </>
                                                ) : null}
                                            </div>

                                            {/* {match && match.MatchID != 0 &&
                                                <LabelControl><ShareIcon style={{ marginBottom: "50px" }} onClick={() => share("matchday")}></ShareIcon></LabelControl>
                                            } */}

                                            <br></br>

                                            {/* {!inPlayers && match && match.MatchStatusTypeID != 2 && match.MatchID != 0 &&
                                                <div style={{marginBottom:"70px"}}>
                                                    <MuiButton variant="contained" color="success" onClick={() => iAmComming()}
                                                        startIcon={<ThumbUpAltIcon></ThumbUpAltIcon>}
                                                        loading={loadingIAmComming}
                                                        style={{ margin: "4px", width: "70%", height: "60px", fontSize: "10px", marginBottom: "40px!important" }}>
                                                        I am comming to match
                                                    </MuiButton>
                                                </div>} */}

                                            {firstTime && !match ? (
                                                <>
                                                    <LabelControl>
                                                        {util.translate("welcomeToGroup")} {database.get("selectedTeam").Name}.
                                                    </LabelControl>
                                                    <LabelControl>{util.translate("youDontHaveAnyMatches")}</LabelControl>
                                                    <Button
                                                        onClick={(e) => {
                                                            window.location = "#/playerList";
                                                        }}
                                                        e
                                                    >
                                                        {util.translate("managePlayers")}
                                                    </Button>
                                                    <LabelControl>{util.translate("inMainMenuOrYou")}</LabelControl>
                                                    <Button
                                                        onClick={(e) => {
                                                            setNewMatch(true);
                                                        }}
                                                        endIcon={<SportsSoccerIcon />}
                                                    >
                                                        {util.translate("createNewMatch")}
                                                    </Button>
                                                </>
                                            ) : (match && match.MatchID == 0) || !match ? (
                                                isAdmin ? (
                                                    <>
                                                        <LabelControl>{util.translate("theresNoNewMatches")}</LabelControl>
                                                        <Button
                                                            onClick={(e) => {
                                                                setNewMatch(true);
                                                            }}
                                                            endIcon={<SportsSoccerIcon />}
                                                        >
                                                            {util.translate("createNewMatch")}
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <LabelControl>There is no new matches. Waiting for the admin to create new match.</LabelControl>
                                                )
                                            ) : null}



                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1} dir={theme.direction}>
                                        <table style={{ width: "100%", fontSize: "9px" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "33%" }} onClick={() => selectTeamStats(1)} className={selectedTeamStats == 1 ? (database.get("theme") == "light" ? "tab-selected-light" : "tab-selected-dark") : ""}>
                                                        <Shirt label={team.Team1Name} color={team.Team1Color} number="10" color2={team.Team1Color2}></Shirt>
                                                    </td>
                                                    <td style={{ width: "33%" }} onClick={() => selectTeamStats(0)} className={selectedTeamStats == 0 ? (database.get("theme") == "light" ? "tab-selected-light" : "tab-selected-dark") : ""}>

                                                        <Shirt label="Overal" color="gray" number="10"></Shirt>
                                                    </td>
                                                    <td style={{ width: "33%" }} onClick={() => selectTeamStats(2)} className={selectedTeamStats == 2 ? (database.get("theme") == "light" ? "tab-selected-light" : "tab-selected-dark") : ""}>
                                                        <Shirt label={team.Team2Name} team={team} color={team.Team2Color} number="10" color2={team.Team2Color2}></Shirt>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="fixed-container-standings" id="standingsTab">
                                            <div id="standings" style={database.get("theme") == "dark" ? { background: "#121212", textAlign: "center" } : null}>
                                                <table style={{ marginTop: 20, width: "100%", textAlign: "center", fontSize: "8px", tableLayout: "fixed" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <LabelControl>{team.Team1Name} won</LabelControl>
                                                                <div className="blue-dot" style={{ backgroundColor: team.Team1Color }}></div>
                                                            </td>
                                                            <td>
                                                                <LabelControl>{util.translate("draw")}</LabelControl>
                                                                <div className="empty-dot"></div>
                                                            </td>
                                                            <td>
                                                                <LabelControl>{team.Team2Name} won</LabelControl>
                                                                <div className="red-dot" style={{ backgroundColor: team.Team2Color }}></div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {matches &&
                                                    matches
                                                        .slice(0)
                                                        .reverse().filter(x => x.MatchStatusTypeID == 2)
                                                        .map((m, i) => {
                                                            if (m.Team1Score > m.Team2Score) return <div key={"dot" + i} className={"blue-dot"} style={{ backgroundColor: team.Team1Color }}></div>;

                                                            if (m.Team1Score == m.Team2Score) return <div key={"dot" + i} className={"empty-dot"}></div>;

                                                            if (m.Team1Score < m.Team2Score) return <div key={"dot" + i} className={"red-dot"} style={{ backgroundColor: team.Team2Color }}></div>;
                                                        })}

                                                {selectedTeamStats == 0 && (
                                                    <>
                                                        <DividerControl>{"Matches played " + (matches ? matches.length : "0")}</DividerControl>
                                                        <table className={database.get("theme") == "light" ? "table-light" : "table-dark"}>
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th style={{ textAlign: "center" }}>Team</th>
                                                                    <th style={{ textAlign: "center" }}>W</th>
                                                                    <th style={{ textAlign: "center" }}>L</th>
                                                                    <th style={{ textAlign: "center" }}>D</th>
                                                                    <th style={{ textAlign: "center" }}>G</th>
                                                                    <th style={{ textAlign: "center" }}>WR</th>
                                                                    <th style={{ textAlign: "center" }}>NLR</th>
                                                                    <th style={{ textAlign: "center" }}>PTS</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {teamStats &&
                                                                    teamStats.map((data, index) => {
                                                                        return (
                                                                            <tr key={"player" + index}>
                                                                                <td></td>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    <Shirt style={{ height: 25 }} color={data.ForTeam == 1 ? team.Team1Color : team.Team2Color} number={10} color2={data.ForTeam == 1 ? team.Team1Color2 : team.Team2Color2}></Shirt>
                                                                                </td>
                                                                                <td style={{ textAlign: "center" }}>{data.W}</td>
                                                                                <td style={{ textAlign: "center" }}>{data.L}</td>
                                                                                <td style={{ textAlign: "center" }}>{data.D}</td>
                                                                                <td style={{ textAlign: "center" }}>{data.G}</td>
                                                                                <td style={{ textAlign: "center" }}>{data.WinsInARow}</td>
                                                                                <td style={{ textAlign: "center" }}>{data.NotLostInARow}</td>
                                                                                <td style={{ textAlign: "center" }}>{data.PTS}</td>
                                                                                <td style={{ textAlign: "center" }}>{data.KOEF}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )}

                                                <DividerControl>{util.translate("playerStats")}</DividerControl>

                                                <table className={database.get("theme") == "light" ? "table-light" : "table-dark"}>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th style={{ textAlign: "center", width: "20px" }}></th>
                                                            <th>{util.translate("player")}</th>

                                                            <th
                                                                style={{ textAlign: "center" }}
                                                                className={sortColumn == "W" ? "selected-column" : ""}
                                                                onClick={() => {
                                                                    onColumnClick("W");
                                                                }}
                                                            >
                                                                W
                                                            </th>
                                                            <th
                                                                style={{ textAlign: "center" }}
                                                                className={sortColumn == "L" ? "selected-column" : ""}
                                                                onClick={() => {
                                                                    onColumnClick("L");
                                                                }}
                                                            >
                                                                L
                                                            </th>
                                                            <th
                                                                style={{ textAlign: "center" }}
                                                                className={sortColumn == "D" ? "selected-column" : ""}
                                                                onClick={() => {
                                                                    onColumnClick("D");
                                                                }}
                                                            >
                                                                D
                                                            </th>
                                                            <th
                                                                style={{ textAlign: "center" }}
                                                                className={sortColumn == "G" ? "selected-column" : ""}
                                                                onClick={() => {
                                                                    onColumnClick("G");
                                                                }}
                                                            >
                                                                G
                                                            </th>
                                                            <th
                                                                style={{ textAlign: "center" }}
                                                                className={sortColumn == "P" ? "selected-column" : ""}
                                                                onClick={() => {
                                                                    onColumnClick("P");
                                                                }}
                                                            >
                                                                P
                                                            </th>
                                                            {/* <th
                                                                style={{ textAlign: "center" }}
                                                                className={sortColumn == "WinsInARow" ? "selected-column" : ""}
                                                                onClick={() => {
                                                                    onColumnClick("WinsInARow");
                                                                }}
                                                            >
                                                                WR
                                                            </th>
                                                            <th
                                                                style={{ textAlign: "center" }}
                                                                className={sortColumn == "NotLostInARow" ? "selected-column" : ""}
                                                                onClick={() => {
                                                                    onColumnClick("NotLostInARow");
                                                                }}
                                                            >
                                                                NLR
                                                            </th> */}
                                                            <th
                                                                style={{ textAlign: "center" }}
                                                                className={sortColumn == "Mvp" ? "selected-column" : ""}
                                                                onClick={() => {
                                                                    onColumnClick("Mvp");
                                                                }}
                                                            >
                                                                <img style={{ width: "14px" }} src={Beer}></img>
                                                            </th>
                                                            <th
                                                                style={{ textAlign: "center" }}
                                                                className={sortColumn == "PTS" ? "selected-column" : ""}
                                                                onClick={() => {
                                                                    onColumnClick("PTS");
                                                                }}
                                                            >
                                                                PTS
                                                            </th>
                                                            <th
                                                                style={{ textAlign: "center" }}
                                                                className={sortColumn == "KOEF" ? "selected-column" : ""}
                                                                onClick={() => {
                                                                    onColumnClick("KOEF");
                                                                }}
                                                            >
                                                                KOEF
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {playerStats &&
                                                            playerStats.map((data, index) => {
                                                                return (
                                                                    <tr key={"player" + index}>
                                                                        <td><div className="circle" style={data.ForTeam != 0 ? { backgroundColor: data.ForTeam == 1 ? team.Team1Color : team.Team2Color, color: data.ForTeam == 1 ? team.Team1Color2 : team.Team2Color2 } : {}}>{index + 1}</div>  </td>
                                                                        <td>
                                                                            {data.LPOS == data.POS || data.PTS == 0 || matches.length < 2 ? <div className="dot"></div> : null}

                                                                            {data.LPOS > data.POS && !data.PTS == 0 && matches.length > 1 ? <div className="arrow-up"></div> : null}

                                                                            {data.LPOS < data.POS && !data.PTS == 0 && matches.length > 1 ? <div className="arrow-down"></div> : null}
                                                                        </td>
                                                                        <td
                                                                            onClick={() => {
                                                                                profile(data.PlayerID);
                                                                            }}
                                                                        >
                                                                            {/* <MuiButton style={{width:"100%", fontSize:11, backgroundColor:"black"}} variant="contained">{data.Nick}</MuiButton> */}
                                                                            {data.Nick}
                                                                            {/* {data.IsLeader && <img src={Trophy}></img>} {data.IsTopScorrer && <img src={GoldenBoot}></img>} */}
                                                                        </td>

                                                                        <td style={{ textAlign: "center" }}>{data.W}</td>
                                                                        <td style={{ textAlign: "center" }}>{data.L}</td>
                                                                        <td style={{ textAlign: "center" }}>{data.D}</td>
                                                                        <td style={{ textAlign: "center" }}>{data.G}</td>
                                                                        <td style={{ textAlign: "center" }}>{data.P}</td>
                                                                        {/* <td style={{ textAlign: "center" }}>{data.WinsInARow}</td>
                                                                        <td style={{ textAlign: "center" }}>{data.NotLostInARow}</td> */}
                                                                        <td style={{ textAlign: "center" }}>{data.Mvp}</td>
                                                                        <td style={{ textAlign: "center" }}>{data.PTS}</td>
                                                                        <td style={{ textAlign: "center" }}>{data.KOEF}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>

                                                <DividerControl img={Boot}>{util.translate("topScorrers")}</DividerControl>

                                                <table style={{ marginBottom: 100 }} className={database.get("theme") == "light" ? "table-light" : "table-dark"}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: "center", width: "20%" }}></th>
                                                            <th style={{ textAlign: "center", width: "20px" }}></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th style={{ textAlign: "center", width: "20%" }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {playerStats &&
                                                            playerStats
                                                                .filter((x) => x.G > 0)
                                                                .sort((a, b) => b.G - a.G)
                                                                .map((data, index) => {
                                                                    return (
                                                                        <tr key={"playeri" + index}>
                                                                            <td>

                                                                            </td>
                                                                            <td style={{ textAlign: "left" }}>
                                                                                {/* <LabelControl style={{ fontSize: 15 }}>{index + 1}</LabelControl> */}
                                                                                <div className="circle" style={data.ForTeam != 0 ? { backgroundColor: data.ForTeam == 1 ? team.Team1Color : team.Team2Color, color: data.ForTeam == 1 ? team.Team1Color2 : team.Team2Color2 } : {}}>{index + 1}</div>
                                                                            </td>
                                                                            <td
                                                                                style={{ textAlign: "left" }}
                                                                                onClick={() => {
                                                                                    profile(data.PlayerID);
                                                                                }}
                                                                            >
                                                                                <LabelControl style={{ fontSize: 15 }}>{data.Nick}</LabelControl>
                                                                            </td>
                                                                            <td style={{ textAlign: "right" }}>
                                                                                <LabelControl style={{ fontSize: 15 }}>{data.G}</LabelControl>
                                                                            </td>
                                                                            <td>

                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                    </tbody>
                                                </table>
                                                {window.location.toString().includes("futsa.online") ?
                                                    <a href="https://play.google.com/store/apps/details?id=com.vsprojects007.futsa">
                                                        <img style={{ width: 200, marginBottom: 100 }} src={Playstore}></img>
                                                    </a> : null}
                                            </div>

                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={2} dir={theme.direction}>
                                        <div className="fixed-container" id="matchesTab">
                                            {matches && matches.length > 0 ? (
                                                <div id="matches">
                                                    {matches.filter(x => x.MatchStatusTypeID == 2).map((item, index) => {
                                                        return (
                                                            <MuiButton
                                                                // className={item.Team1Score == item.Team2Score ? "gray" : item.Team1Score > item.Team2Score ? "blue" : item.Team1Score < item.Team2Score ? "red" : "blue"}
                                                                className={database.get("theme") == "dark" ? "darkgray-dark" : "darkgray-light"}
                                                                variant={database.get("theme") == "light" ? "" : "contained"}
                                                                key={"match" + index}
                                                                style={{ width: "100%", marginTop: "2px" }}
                                                                noTimeOut
                                                                onClick={() => {
                                                                    onMatchClick(item.MatchID);
                                                                }}
                                                            >
                                                                <table style={{ width: "100%", color: database.get("theme") == "light" ? "inherit" : "whitesmoke" }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ textAlign: "left", width: "70%", fontSize: "12px" }}>
                                                                                {util.getDateFromDateTimeString(item.DatePlayed)}{" / "} {util.getTimeFromDateTimeString(item.DatePlayed)}
                                                                                <br></br>
                                                                                {util.getDay(item.DatePlayed).toUpperCase()}
                                                                            </td>
                                                                            <td>
                                                                                <div className={item.Team1Score == item.Team2Score ? "empty-dot" : "blue-dot"} style={{ backgroundColor: item.Team1Score == item.Team2Score ? "transparent" : item.Team1Score > item.Team2Score ? team.Team1Color : item.Team1Score < item.Team2Score ? team.Team2Color : team.Team1Color }}></div>
                                                                            </td>
                                                                            <td style={{ textAlign: "center", fontSize: "25px" }}>
                                                                                {/* <p className="res" style={{ fontSize: "25px", fontWeight: "bold" }}><img style={{ width: "25px" }} src={Team1img}></img> {"   "} {item.Team1Score} - {item.Team2Score} {"   "} <img style={{ width: "25px" }} src={Team2img}></img></p> */}

                                                                                {item.Team1Score} - {item.Team2Score}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </MuiButton>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <>
                                                    <LabelControl>{util.translate("noMatchesPlayedYet")}</LabelControl>
                                                    {isAdmin ? (
                                                        <Button
                                                            onClick={(e) => {
                                                                setNewMatch(true);
                                                            }}
                                                            endIcon={<SportsSoccerIcon />}
                                                        >
                                                            Create new match
                                                        </Button>
                                                    ) : null}
                                                </>
                                            )}
                                            <br></br>
                                            <br></br> <br></br>
                                            <br></br>
                                            {window.location.toString().includes("futsa.online") ?
                                                <a href="https://play.google.com/store/apps/details?id=com.vsprojects007.futsa">
                                                    <img style={{ width: 200, marginBottom: 100 }} src={Playstore}></img>
                                                </a> : null}
                                        </div>
                                    </TabPanel>
                                </SwipeableViews>
                            </Box>

                            <Dialog onClose={() => setOpenProfile(false)} open={openProfile} fullScreen>
                                <DialogTitle style={{ textAlign: "center" }}>
                                    <LabelControl style={{ fontSize: "11px" }}>{util.translate("playerProfile")}</LabelControl>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setOpenProfile(false)}
                                        sx={{
                                            position: "absolute",
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <PlayerProfile onMatchClick={onMatchClick} playerID={selectedPlayerID}></PlayerProfile>
                            </Dialog>
                            <Dialog fullWidth onClose={() => setNewMatch(false)} open={newMatch}>
                                <DialogTitle style={{ textAlign: "center" }}>
                                    <LabelControl style={{ fontSize: "11px" }}> {util.translate("newMatch")}</LabelControl>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setNewMatch(false)}
                                        sx={{
                                            position: "absolute",
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                {/* <NewMatch minDateTime={(match && match.DatePlayed)} onSave={newMatchOnSave} onCancel={() => setNewMatch(false)}></NewMatch> */}
                                <NewMatch onSave={newMatchOnSave} onCancel={() => setNewMatch(false)}></NewMatch>
                            </Dialog>
                            <Dialog fullWidth onClose={() => setNewMatchUpdate(false)} open={newMatchUpdate}>
                                <DialogTitle style={{ textAlign: "center" }}>
                                    <LabelControl style={{ fontSize: "11px" }}> {util.translate("edit")}</LabelControl>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setNewMatchUpdate(false)}
                                        sx={{
                                            position: "absolute",
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                {/* <NewMatch minDateTime={(match && match.DatePlayed)} onSave={newMatchOnSave} onCancel={() => setNewMatch(false)}></NewMatch> */}
                                <NewMatch onSave={newMatchOnUpdate} match={match} onCancel={() => setNewMatchUpdate(false)}></NewMatch>
                            </Dialog>
                            <Dialog onClose={() => setMatchDetailsMatchID(null)} open={matchDetailsMatchID} fullScreen>
                                <DialogTitle style={{ textAlign: "center" }}>
                                    <LabelControl style={{ fontSize: "11px" }}>MATCH DETAILS</LabelControl>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setMatchDetailsMatchID(null)}
                                        sx={{
                                            position: "absolute",
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <MatchDetails id={"matchDetails"} isAdmin={isAdmin} team={team} onShare={(id) => share("matchDetails")} matchID={matchDetailsMatchID} hideAppBar onLineUpPlayerClick={onPlayerClick} onDelete={(id) => deleteSelectedMatch(id)}></MatchDetails>
                                {/* {isAdmin && match && match.MatchID != 0 ?
                                        <div style={{ paddingBottom: "20px", textAlign:"center" }}>
                                            <MuiButton
                                                style={{ margin: "4px", height: "60px", width: "27%", fontSize: "10px" }}
                                                variant="contained"
                                                color="error"
                                                startIcon={<DeleteIcon></DeleteIcon>}
                                                onClick={() => {
                                                    deleteSelectedMatch(matchDetailsMatchID);
                                                    // setNewMatch(true);
                                                    // window.location = "#/newmatch?datePlayed=" + (match && match.DatePlayed);
                                                }}
                                            >
                                                DELETE MATCH
                                            </MuiButton>
                                        </div> : null
                                    } */}
                            </Dialog>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={onMenuClose}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                }}
                            >
                                {/* <MenuItem onClick={() => window.location = "#/invitations"}>Invite members</MenuItem> */}
                                {/* <MenuItem onClick={() => window.location = "#/addPlayers"}>Add players</MenuItem> */}
                                {isAdmin && <MenuItem onClick={() => (window.location = "#/playerList")}>Players</MenuItem>}
                            </Menu>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default TeamPage;
