import React, { useState, useRef, useContext } from "react";
import { faParking, faMoneyCheck, faUsers, faFutbol, faGlasses } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";
import database from "../../util/database";
import { I18nextProvider, useTranslation } from "react-i18next";
import $ from "jquery";
import CircularProgress from "@mui/material/CircularProgress";
import { alertTitleClasses } from "@mui/material";

import { Oval } from "react-loader-spinner";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
 
// const useConstructor = util.useConstructor();

let LabelControl = (props) => {
    // useConstructor(() => {
    const theme = database.get("theme");
    // });
    return (
        <div style={props.style} onChange={(e) => props.onChange(e)} onClick={()=>{if(props.onClick)props.onClick()}} variant="outlined" className={theme == "light" ? "color-black" + " " + props.className : "color-white" + " " + props.className }>{props.children}</div>             
    );
};

export default LabelControl;