import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import GlobalContext from "../components/globalContext";
import { faParking, faMoneyCheck, faUser, faFutbol, faGlasses, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ButtonControl, PageTitleControl, DividerControl, TextBoxControl, LabelControl, Snack } from "../components/controls/controls";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PhoneIcon from '@mui/icons-material/Phone';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import { CheckOutlined } from "@ant-design/icons";
import LoaderControl from '../components/controls/loaderControl';

const useConstructor = util.useConstructor();

function AddPlayers(props) {
    const [players, setPlayers] = useState([]);
    const [nick, setNick] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [mobile, setMobile] = useState("");
    const [showContacts, setShowContacts] = useState(false);
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState();
    const [contacts, setContacts] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [playerExist, setPlayerExist] = useState("");
    const [snackMessage, setSnackMessage] = useState("");

    useConstructor(() => {

        //if (global.selectedTab != "players") global.setSelectedTab("players");

        let params = util.getParametersFromUrl(window.location);

        if (params.fromContacts) {
            setShowContacts(true);
        }

        // if (navigator && navigator.contactsPhoneNumbers) {
        //     navigator.contactsPhoneNumbers.list(function (contacts) {

        //         let cnts = [];
        //         contacts.map((item, index) => {

        //             let mob = "";
        //             if (item.phoneNumbers && item.phoneNumbers.length > 0) {
        //                 mob = item.phoneNumbers[0].number;
        //             }
        //             cnts.push({ name: item.displayName, mobile: mob, checked: false });
        //         })
        //         setContacts(cnts);

        //     }, function (error) {
        //         console.error(error);
        //     });
        // } else {

        //     let c = [
        //         { name: "canjo", mobile: "09183277", checked: false },
        //         { name: "tuna", mobile: "0986736547", checked: false },
        //         { name: "kralj", mobile: "0993737473", checked: false },
        //         { name: "jozo", mobile: "0919374443", checked: false },
        //         { name: "franjo", mobile: "0957638484", checked: false },
        //         { name: "canjo", mobile: "09183277", checked: false },
        //         { name: "tuna", mobile: "0986736547", checked: false },
        //         { name: "kralj", mobile: "0993737473", checked: false },
        //         { name: "jozo", mobile: "0919374443", checked: false },
        //         { name: "franjo", mobile: "0957638484", checked: false },
        //         { name: "canjo", mobile: "09183277", checked: false },
        //         { name: "tuna", mobile: "0986736547", checked: false },
        //         { name: "kralj", mobile: "0993737473", checked: false },
        //         { name: "jozo", mobile: "0919374443", checked: false },
        //         { name: "franjo", mobile: "0957638484", checked: false },
        //         { name: "canjo", mobile: "09183277", checked: false },
        //         { name: "tuna", mobile: "0986736547", checked: false },
        //         { name: "kralj", mobile: "0993737473", checked: false },
        //         { name: "jozo", mobile: "0919374443", checked: false },
        //         { name: "franjo", mobile: "0957638484", checked: false },
        //         { name: "canjo", mobile: "09183277", checked: false },
        //         { name: "tuna", mobile: "0986736547", checked: false },
        //         { name: "kralj", mobile: "0993737473", checked: false },
        //         { name: "jozo", mobile: "0919374443", checked: false },
        //         { name: "franjo", mobile: "0957638484", checked: false },
        //         { name: "canjo", mobile: "09183277", checked: false },
        //         { name: "tuna", mobile: "0986736547", checked: false },
        //         { name: "kralj", mobile: "0993737473", checked: false },
        //         { name: "jozo", mobile: "0919374443", checked: false },
        //         { name: "franjo", mobile: "0957638484", checked: false },
        //     ];

        //     setContacts([]);
        // }
    });

    function success(data) {
        
        if (data.ErrorMessage) {
            setSnackMessage(data.ErrorMessage);
            setLoading(false);
            return;
        }
        
        if (data.Success) {
           
            let params = util.getParametersFromUrl();
            if (params.backto) {
                window.location = "#/" + params.backto;
            } else {
                window.location = "#/playerList";
            }
        }
        else {
            alert(data.ErrorMessage);
        }
    }

    function fail(data) {
        setLoading(false);
        alert(data.ErrorMessage);
    }

    function save() { }

    function add() {

        let items = [...players];
        let player = {
            Nick: nick,
            Email: email,
            Mobile: mobile,
            Number: number
        }

        items.push(player);
        setPlayers(items);
        setNick("");
        setEmail("");
        setMobile("");
        setNumber("");
    }

    function removeAt(index) {
        players.splice(index, 1);
        let items = [...players];
        setPlayers(items);
        setNick("");
        setEmail("");
        setMobile("");
        setNumber("");
    }

    function send() {
        setLoading(true);
        let selectedTeam = database.get("selectedTeam");
        let user = database.get("user");

        let data = {
            Players: players.map((item) => { return { Nick: item.Nick, Email: item.Email, Mobile: item.Mobile, TeamID: selectedTeam.TeamID, Number: item.Number} }),
            TeamID: selectedTeam.TeamID
        }

        api.post("AddPlayers", data, success, fail);
    }

    function saveFromContacts() {
        setLoading(true);
        let selectedTeam = database.get("selectedTeam");
        let user = database.get("user");

        let pls = [];
        contacts.map((item) => { if (item.checked) pls.push({ Nick: item.name, Email: "", Mobile: item.mobile, TeamID: selectedTeam.TeamID }) });

        let data = {
            Players: pls,
            TeamID: selectedTeam.TeamID
        }

        api.post("AddPlayers", data, success, fail);
    }

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },
    }));

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '50%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }));

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));


    function check(index) {
        let c = [...contacts];
        let ceck = !c[index].checked;

        c[index].checked = ceck;
        setContacts(c);
    }

    function onNickChange(e) {
        setNick(e.target.value);

        let pls = players.filter(x => x.Nick == e.target.value);

        if (pls && pls.length > 0) {
            setPlayerExist(true);
        }
    }

    return (
        <div className="component-container centered-container">
            <Snack open={snackMessage} type={"error"} onClose={() => setSnackMessage(null)} message={snackMessage} />
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                showContacts ?
                    <>
                        <Box sx={{ flexGrow: 1 }}>
                            <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                                <Toolbar>
                                    <IconButton onClick={(e) => { window.location = "#/playerList" }} size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                        <ArrowBackIosIcon />
                                    </IconButton>
                                    <Typography component="div" sx={{ flexGrow: 1 }}>
                                        Contacts
                                    </Typography>

                                    <IconButton onClick={saveFromContacts} size="small" edge="end" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                        <CheckOutlined></CheckOutlined>
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                        </Box>

                        <div style={{ textAlign: "center", padding: "20px" }}>
                            <TextField size="small" label="Search" variant="outlined" InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }} onChange={(e) => setSearchText(e.target.value)} value={searchText} />
                        </div>

                        <div className="fixed-container-contacts">
                            {contacts && contacts.map((contact, index) => {
                                return <>
                                    {contact.name && contact.name.toLowerCase().includes(searchText.toLowerCase()) && <div className="contact" onClick={(e) => { check(index) }}>
                                        <LabelControl style={{ fontWeight: "bold" }} className="contact-name">{contact.name}</LabelControl>
                                        <LabelControl className="contact-number">{contact.mobile}</LabelControl>
                                        <Checkbox checked={contacts && contacts[index].checked} style={{ float: "right", marginTop: "-38px" }} />
                                    </div>}
                                </>
                            })}

                        </div> </> :
                    <>
                        <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
                            <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                                <Toolbar>
                                    <IconButton onClick={(e) => { window.location = "#/playerList" }} size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                        <ArrowBackIosIcon />
                                    </IconButton>
                                    <Typography style={{ textAlign: "left" }} component="div" sx={{ flexGrow: 1 }}>
                                        <p style={{ fontSize: "12px" }}>{util.translate("addPlayers")}</p>
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        </Box>

                        <div className="fixed-container-playerList">
                            <div style={{ padding: "20px" }}>
                                <TextBoxControl size="small" required value={nick} label={util.translate("playerName")} onChange={onNickChange} variant="outlined" style={{ width: "100%" }} />
                                <TextBoxControl size="small" value={number} label={util.translate("number") + " (optional)"} onChange={(e) => setNumber(e.target.value)} variant="outlined" style={{ width: "100%" }} />
                               
                                <TextBoxControl size="small" helperText={util.translate("userNameIsOptional")} value={email} label={util.translate("username")+ " (optional)"} onChange={(e) => setEmail(e.target.value)} variant="outlined" style={{ width: "100%" }} />
                                {/* <TextBoxControl  size="small" value={mobile} label={"Mobile"} onChange={(e) => setMobile(e.target.value)} variant="outlined" style={{ width: "100%" }} /> */}


                                <Button disabled={!nick} variant="contained" onClick={() => add()} style={{ width: "100%", marginTop: "10px" }}>
                                    ADD
                                </Button>
                                {/* <Button onClick={() => setShowContacts(true)} style={{ width: "100%", marginTop: "10px" }}>
                                    Or add from contacts
                                </Button> */}
                                {players.length > 0 ? (
                                    <>
                                        <br></br><br></br>
                                        {/* <div className="divider">MEMBERS</div> */}
                                        {players.map((item, index) => {
                                            return (
                                                <div
                                                    style={{ height: 42 }}
                                                    className={database.get("theme") == "light" ? "trplayer-light" : "trplayer-dark"} >

                                                    <table style={{ tableLayout: "fixed", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                                                                    <LabelControl style={{ fontWeight: "bold", float: "left" }}>{item.Nick} {item.Email && "(" + item.Email + ")"}</LabelControl>
                                                                </td>
                                                                <td style={{ width: "40px" }}>
                                                                    <FontAwesomeIcon style={{ fontSize: "20", float: "right", marginRight: "5px" }} onClick={() => removeAt(index)} icon={faTrash}></FontAwesomeIcon>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>


                                                </div>
                                            );
                                        })}
                                        <br></br><br></br>
                                        <Button variant="contained" onClick={() => send()} style={{ width: "100%", marginTop: "10px" }}>
                                            Save
                                        </Button>
                                        <br></br><br></br><br></br><br></br>
                                    </>
                                ) : (
                                    <></>
                                )}


                            </div>
                        </div>
                    </>
            )}
        </div>
    );
}

export default AddPlayers;
