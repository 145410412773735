import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import GlobalContext from "../components/globalContext";
import { faParking, faMoneyCheck, faUser, faFutbol, faGlasses, faTrash, faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import PlayerEditor from "../components/playerEditor";
import ShareIcon from '@mui/icons-material/Share';
import { ButtonControl, PageTitleControl, DividerControl, TextBoxControl, LabelControl, MuiButton } from "../components/controls/controls";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PhoneIcon from '@mui/icons-material/Phone';
import Team1img from '../images/team1.png';
import Team2img from '../images/team2.png';
import Team0img from '../images/team.png';
import Paper from '@mui/material/Paper';
import LoaderControl from '../components/controls/loaderControl';
import { Progress } from 'antd';
import "antd/dist/antd.css";
import { Col, Row, Statistic } from 'antd';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Beer from '../images/beer.png';

const useConstructor = util.useConstructor();

function PlayerProfile(props) {
    const [loading, setLoading] = useState(true);
    const [player, setPlayer] = useState();

    useConstructor(() => {
        let params = util.getParametersFromUrl(window.location);
        let playerID;

        if (props.playerID)
            playerID = props.playerID;
        else
            playerID = params.playerID;

        let seasonID = database.get("season").SeasonID;
        if(!seasonID){
            seasonID = database.get("match").SeasonID;
        }

        api.get("Team/GetPlayerProfile?playerID=" + playerID + "&seasonID=" + seasonID, (data) => {
            setPlayer(data);
            setLoading(false);
        });
    });

    function share() {
        var div = document.getElementById("pp");
        div.style.backgroundColor = database.get("theme") == "dark" ? "rgb(49 49 49)" : "whitesmoke";
        util.share("pp");
        div.style.backgroundColor = "rgb(0 0 0 / 0%)";
    }

    function onMatchClick(matchID) {
        if (props.onMatchClick) {
            props.onMatchClick(matchID);
        }
    }

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <>
                    <div className="fixed-container-playerProfile" id="pp" style={{ padding: 10, backgroundColor: database.get("theme") == "dark" ? "rgb(0 0 0 / 0%)" : "rgb(0 0 0 / 0%)" }}>
                        <table style={{ width: "100%", marginTop: "10px", tableLayout: "fixed" }}>
                            <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        {/* <img style={{ height: "50px" }} src={Team1img}></img> */}
                                        <LabelControl style={{ fontSize: "20px", fontWeight: "bold" }}>{player && player.Stats.Nick}</LabelControl>
                                        <table style={{ width: "100%", textAlign: "center", fontSize: "8px", tableLayout:"fixed" }}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <LabelControl>{util.translate("winner")}</LabelControl>
                                                        <div className="blue-dot"></div>
                                                    </td>
                                                    <td>
                                                        <LabelControl>{util.translate("draw")}</LabelControl>
                                                        <div className="gray-dot"></div>
                                                    </td>
                                                    <td>
                                                        <LabelControl>{util.translate("loser")}</LabelControl>
                                                        <div className="red-dot"></div>
                                                    </td>                                                   
                                                    <td>
                                                        <LabelControl>{util.translate("notPlayed")}</LabelControl>
                                                        <div className="empty-dot"></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div>
                                            {player && player.Matches && player.Matches.slice(0).map((m, i) => {
                                                if (m.Result == 1)
                                                    return <div key={"dot" + i} className={"blue-dot"}></div>

                                                if (m.Result == 0)
                                                    return <div key={"dot" + i} className={"gray-dot"}></div>

                                                if (m.Result == 2)
                                                    return <div key={"dot" + i} className={"red-dot"}></div>

                                                if (m.Result == -1)
                                                    return <div key={"dot" + i} className={"empty-dot"}></div>
                                            })}
                                        </div>
                                    </td>

                                </tr>
                                <tr style={{ textAlign: "center", width: "100%" }}>
                                    <td style={{ textAlign: "center", width: "100%" }}>

                                    </td>
                                </tr>
                                <tr>
                                    <td className="stat-td">
                                        <LabelControl className="stat1">{util.translate("played")}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat2">{player && player.Stats.P}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat5">{player && player.PP}%</LabelControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="stat-td">
                                        <LabelControl className="stat1">{util.translate("win")}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat2">{player && player.Stats.W}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat5">{player && player.WP}%</LabelControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="stat-td">
                                        <LabelControl className="stat1">{util.translate("draw")}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat2">{player && player.Stats.D}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat5">{player && player.DP}%</LabelControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="stat-td">
                                        <LabelControl className="stat1">{util.translate("lost")}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat2">{player && player.Stats.L}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat5">{player && player.LP}%</LabelControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="stat-td">
                                        <LabelControl className="stat1">{util.translate("goals")}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat2">{player && player.Stats.G}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat4">{player && player.GoalsPerMatch}</LabelControl>
                                        <LabelControl className="stat3">{util.translate("perMatch")}</LabelControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="stat-td">
                                        <LabelControl className="stat1">{util.translate("points")}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat2">{player && player.Stats.PTS}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat4">{player && player.PointsPerMatch}</LabelControl>
                                        <LabelControl className="stat3">{util.translate("perMatch")}</LabelControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="stat-td">
                                        <LabelControl className="stat1">{util.translate("winsInARow")}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat2">{player && player.Stats.WinsInARow}</LabelControl>
                                    </td>
                                    <td className="stat-td">

                                    </td>
                                </tr>
                                <tr>
                                    <td className="stat-td">
                                        <LabelControl className="stat1">{util.translate("notLostInARow")}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat2">{player && player.Stats.NotLostInARow}</LabelControl>
                                    </td>
                                    <td className="stat-td">

                                    </td>
                                </tr>
                                <tr>
                                    <td className="stat-td">
                                        <LabelControl className="stat1"><img style={{ width: "34px" }} src={Beer}></img></LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat2">{player && player.Mvp}</LabelControl>
                                    </td>
                                    <td className="stat-td">
                                        <LabelControl className="stat4">{player && player.MvpPerMatch}</LabelControl>
                                        <LabelControl className="stat3">{util.translate("perMatch")}</LabelControl>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td></td>
                                <td className="stat-td" onClick={() => share()}>
                                    <ShareIcon style={{ marginTop: 30, marginBottom: 30 }}></ShareIcon>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <DividerControl>{util.translate("matches")}</DividerControl>
                    <div style={{ padding: "5px" }}>
                        {player && player.Matches && player.Matches.slice(0).reverse().map((item, index) => {
                            return item.Result != -1 ? <MuiButton className={item.Result == 0 ? "gray" : item.Result == 1 ? "blue" : item.Result == 2 ? "red" : "blue"} variant={database.get("theme") == "light" ? "" : "contained"} key={"match" + index} style={{ width: "100%", marginTop: "2px" }} noTimeOut onClick={() => { onMatchClick(item.MatchID) }}>
                                <table style={{ width: "100%", fontSize: "25px", fontWeight: "bold", color: database.get("theme") == "light" ? "inherit" : "whitesmoke" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "70%" }}>
                                                {util.getDateFromDateTimeString(item.DatePlayed)} {util.getTimeFromDateTimeString(item.DatePlayed)}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {/* <p className="res" style={{ fontSize: "25px", fontWeight: "bold" }}><img style={{ width: "25px" }} src={Team1img}></img> {"   "} {item.Team1Score} - {item.Team2Score} {"   "} <img style={{ width: "25px" }} src={Team2img}></img></p> */}
                                                {item.Team1Score} - {item.Team2Score}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </MuiButton>  : null
                        })}
                    </div>
                </>
            )}
        </div>
    );
}

export default PlayerProfile;
