import React, { useState, useRef, useContext } from "react";
import { faParking, faMoneyCheck, faUsers, faFutbol, faGlasses } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import database from "../util/database";
import { I18nextProvider, useTranslation } from "react-i18next";
import $ from "jquery";
import CircularProgress from "@mui/material/CircularProgress";
import { alertTitleClasses } from "@mui/material";
import api from "../util/api";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import GlobalContext from "../components/globalContext";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import GroupsIcon from '@mui/icons-material/Groups';
import IconButton from '@mui/material/IconButton';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LoaderControl from '../components/controls/loaderControl';
import { ButtonControl, PageTitleControl, LabelControl, DividerControl, MuiButton } from "../components/controls/controls";
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import Team1img from "../images/team1.png";
import Team2img from "../images/team2.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import TeamEditor from "../components/teamEditor";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const useConstructor = util.useConstructor();

let Home = (props) => {

    //test2
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({ Username: "gfdgd" });
    const [selectedTeam, setSelectedTeam] = useState("Select team");
    const [teams, setTeams] = useState([]);
    const [openTeamEditor, setOpenTeamEditor] = useState();
    const [seasons, setSeasons] = useState([]);
    const global = useContext(GlobalContext);

    const nuser = database.get("user");

    if (nuser && user.guid != nuser.guid) {
        console.log("home");
        console.log(nuser);
        setUser(nuser);
    } else {
        if (!nuser) {
            window.location = "#/login";
        }
    }

    useConstructor(() => {
        setLoading(true);
        let user = database.get("user");

        if (!user) {
            //global.setSelectedTab("login");
            window.location = "#/login";
            return;
        }

        global.setSelectedTab("home");

        var theme = database.get("theme");

        if (!theme) {
            util.setTheme("dark");
        }else{
            util.setTheme(theme);
        }

        load();

        if (window.FirebasePlugin) {
            window.FirebasePlugin.onMessageReceived(
                (e) => {
                    onMessageReceived(e);
                },
                function (data) {
                    util.throwExcMessage(data);
                }
            );
        }
    });

    function load() {
        api.get("Members/GetHome", (data) => {
            if (!data.Success) {
                window.location = "#/login";
                database.remove("user");
                return;
            }
            
            database.set("teams", data.Teams);
            database.set("members", data.Members);
            setTeams(data.Teams || []);
            let sns = [];
            // data.Teams.forEach((team, index) => {
            //     team.Seasons.forEach((season, index)=>{
            //         sns.push({TeamName: team.Name, TeamID: team.TeamID, SeasonID: season.SeasonID, Number: season.Number});
            //     })
            // })

            setSeasons(sns);
            setLoading(false);
            updateFirebaseToken();

            // let firebaseToken = database.get("firebaseToken");

            // if (!firebaseToken) {
            //     updateFirebaseToken();
            // } else {
            //     setLoading(false);
            // }
        });

    }

    function onMessageReceived(e) {
        if (e.type == 4 || e.type == 6) {
            try {
                // let t = eval(e.jsonData);
                // let tms = [...teams];
                // let index = tms.filter(x => x.TeamID == t.TeamID);
                // if(!index){
                //     tms.push(t);
                // }

                // database.set("teams", tms);
                // setTeams(tms);
                load();
            }
            catch (err) {
                alert(err.message);
            }
        }
    }

    function newMatch() {
        setTimeout(() => {
            //global.setSelectedTab("newmatch");
            window.location = "#/newmatch";
        }, 200);
    }

    function selectTeam() {
        setTimeout(() => {
            //global.setSelectedTab("selectteam");
            window.location = "#/selectteam";
        }, 200);
    }

    function updateFirebaseToken() {
        if (window.FirebasePlugin) {

            // window.FirebasePlugin.onNotificationOpen((e) => {alert('shit1');}, function (data) {util.throwExcMessage(data);});

            // window.FirebasePlugin.onMessageReceived(
            //     (e) => {
            //         util.alert("notifi - " + e.body);
            //     },
            //     function (data) {
            //         //alert(error);
            //         util.throwExcMessage(data);
            //     }
            // );

            //window.FirebasePlugin.hasPermission(function (data) {
            //    console.log(data.isEnabled);
            //});

            // window.FirebasePlugin.getToken(
            //     function (token) {
            //         //alert("get token - " + token);
            //     },
            //     function (data) {
            //         util.throwExcMessage(data);
            //     }
            // );

            window.FirebasePlugin.onTokenRefresh(
                function (token) {
                    // save this server-side and use it to push notifications to this device

                    //alert(token);
                    //alert("onTokenRefresh - " + token);
                    database.set("firebaseToken", token);
                    const deviceUUID = database.get("DeviceUUID");

                    let data = { UserDeviceGUID: "", FirebaseToken: token, DeviceUUID: deviceUUID, OS: util.getPlatform() };
                    api.post(
                        "Members/UpdateFirebaseToken",
                        data,
                        (result) => {
                            setLoading(false);
                        },
                        (data) => {
                            //alert("error - " + error.Message);
                            util.throwExcMessage(data);
                        }
                    );

                    //UpdateToken(token);
                },
                function (data) {
                    console.error(data);
                }
            );
        }

        setLoading(false);
    }

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <>
                    {/* <PageTitleControl>SELECT GROUP</PageTitleControl> */}
                    <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                            <Toolbar>
                                <Typography component="div" sx={{ flexGrow: 1 }} style={{ textAlign: "left" }}>
                                    {util.translate("selectGroupOrCreate")}

                                </Typography>
                                {/* <IconButton onClick={(e) => { window.location = "#/teamEditor?add=true" }} size="large" edge="end" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                    <AddIcon />
                                </IconButton> */}

                                <IconButton onClick={(e) => setOpenTeamEditor(true)} size="large" edge="end" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                    <AddIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Box>
                    <div className="fixed-container-home">
                        {teams.length > 0 ? (

                            <>
                                {teams.map((team, index) => {

                                    return (
                                        // <MuiButton
                                        //     style={{ margin: "1px", width: "95%", textAlign: "center", height: "70px", fontSize:25, fontWeight:"bold" }}
                                        //     // color="success"
                                        //     variant="contained"
                                        //     key={"btn" + index}
                                        //     // startIcon={<GroupsIcon style={{fontSize:"40px"}}></GroupsIcon>}
                                        //     onClick={() => {
                                        //         global.setTeamName(team.Name);
                                        //         database.set("teamName", team.Name);
                                        //         database.set("selectedTeam", team);
                                        //         database.set("season", team.Season);
                                        //         database.set("selectedTeamID", team.TeamID);
                                        //         window.location = "#/teamPage";
                                        //     }}
                                        // >
                                        //     <table style={{width:"100%"}}>
                                        //         <tbody>
                                        //             <tr>
                                        //                 {/* <td style={{textAlign:"left"}}>
                                        //                     <GroupsIcon style={{ fontSize: "40px" }}></GroupsIcon>
                                        //                 </td> */}
                                        //                 <td>
                                        //                     {team.Name} 
                                        //                     {/* - {team.Season.Name} */}
                                        //                 </td>
                                        //             </tr>

                                        //         </tbody>
                                        //     </table>
                                        // </MuiButton>
                                        <MuiButton
                                            className={database.get("theme") == "dark" ? "darkgray-dark" : "darkgray-light"}
                                            variant={database.get("theme") == "light" ? "" : "contained"}
                                            key={"match" + index}
                                            style={{ width: "100%", marginTop: "2px", textAlign: "left", float: "left", display: "inline" }}
                                            noTimeOut
                                            onClick={() => {
                                                global.setTeamName(team.Name);
                                                database.set("teamName", team.Name);
                                                database.set("selectedTeam", team);
                                                database.set("season", team.Season);
                                                database.set("selectedTeamID", team.TeamID);
                                                window.location = "#/teamPage";
                                            }}
                                        >

                                            <>


                                                <table style={{ width: "100%" }}>
                                                    <tr>
                                                        <td>
                                                            <span style={{ fontSize: "14px", float: "left", fontWeight: "bold" }}>{team.Name}</span>
                                                            <br></br>
                                                            <span style={{ fontSize: "10px", float: "left", marginRight: "5px", marginTop: "-5px", opacity: 0.7, textTransform: "none" }}> {team.Description}</span>
                                                            <br></br>
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <PersonIcon style={{ fontSize: "20px", fontWeight: "bold", color: "#69e769" }}></PersonIcon>
                                                                        </td>
                                                                        <td>
                                                                            <span style={{ display: "block", marginTop: "-5px" }}>{team.PlayersCount} </span>
                                                                        </td>
                                                                        <td style={{ width: "10px" }}>

                                                                        </td>
                                                                        <td>
                                                                            <SportsSoccerIcon style={{ fontSize: "20px", fontWeight: "bold", color: "#69e769" }}></SportsSoccerIcon>
                                                                        </td>
                                                                        <td>
                                                                            <span style={{ display: "block", marginTop: "-5px" }}>{team.MatchesCount}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </td>
                                                        <td style={{ width: "100px", fontSize: 10 }}>
                                                            {(team.LastMatchMatchStatusTypeID && team.LastMatchMatchStatusTypeID == 1) ? <div className="team-card">
                                                                <SportsSoccerIcon style={{ fontSize: "14px", fontWeight: "bold", color: "#69e769" }}></SportsSoccerIcon> {" "}<br></br>
                                                                <span style={{ opacity: 0.5, marginTop: -4 }}>Next match</span>  <br></br>
                                                                {util.getDay(team.LastMatchDatePlayed).toUpperCase()} {"  "}
                                                                {util.getTimeFromDateTimeString(team.LastMatchDatePlayed)}<br></br>
                                                                {util.getDateFromDateTimeString(team.LastMatchDatePlayed)}
                                                            </div> : null}

                                                            {(team.LastMatchMatchStatusTypeID && team.LastMatchMatchStatusTypeID == 2) ? <div className="team-card">
                                                                <SportsSoccerIcon style={{ fontSize: "14px", fontWeight: "bold", color: "gray" }}></SportsSoccerIcon> {" "}<br></br>
                                                                <span style={{ opacity: 0.5, marginTop: -4 }}>Last match</span>  <br></br>
                                                                {/* <img style={{ width: "14px" }} src={Team1img}></img>{" "} */}
                                                                <span style={{ fontSize: 12 }}>{team.LastMatchTeam1Score} : {team.LastMatchTeam2Score} </span>
                                                                {" "}
                                                                {/* <img style={{ width: "14px" }} src={Team2img}></img> */}

                                                            </div> : null}

                                                        </td>
                                                    </tr>
                                                </table>





                                            </>
                                        </MuiButton>
                                    );
                                })}
                                <br></br><br></br>
                            </>
                        ) : (
                            <>
                                <LabelControl style={{ padding: "20px" }}> {util.translate("youAreNotMember")}</LabelControl>
                                {/* <Button onClick={(e) => { window.location = "#/teamEditor?add=true" }} endIcon={<GroupAddIcon />}>{util.translate("newGroup")}</Button> */}
                            </>
                        )}

                    </div>


                    {/* <Button
                    style={{margin:"4px", width:"70%"}}
                     variant="outlined"
                        onClick={() => {
                            window.location = "#/teamEditor";
                        }}
                    >
                        Create new group +
                    </Button> */}
                    <Dialog onClose={() => setOpenTeamEditor(false)} open={openTeamEditor} fullScreen>
                        <DialogTitle style={{ textAlign: "center" }}>
                            <LabelControl style={{ fontSize: "11px" }}>ADD GROUP</LabelControl>
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenTeamEditor(false)}
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <TeamEditor mode="add"></TeamEditor>
                    </Dialog>
                </>
            )}
        </div>
    );
};

export default Home;
