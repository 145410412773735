import React, { useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import GlobalContext from "./globalContext";
import { faParking, faMoneyCheck, faUsers, faFutbol, faGlasses, faCheck } from "@fortawesome/free-solid-svg-icons";
import { ButtonControl, PageTitleControl, LabelControl, MuiButton } from "./controls/controls";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import FormGroup from "@mui/material/FormGroup";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { CheckOutlined } from "@ant-design/icons";
import Team1img from '../images/team1.png';
import Team2img from '../images/team2.png';
import Team0img from '../images/team.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LoaderControl from '../components/controls/loaderControl';
import { useSwipeable } from "react-swipeable";
import $ from "jquery";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SwipeIcon from '@mui/icons-material/Swipe';

const useConstructor = util.useConstructor();

function SelectPlayers2(props) {
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [players, setPlayers] = useState([]);
    const global = useContext(GlobalContext);
    const [match, setMatch] = useState();
    const [selectedPlayersTeam1, setSelectedPlayersTeam1] = useState([]);
    const [selectedPlayersTeam2, setSelectedPlayersTeam2] = useState([]);

    const [selectedPlayerIndex, setSelectedPlayerIndex] = useState();
    const [sp, setsp] = useState();
    useConstructor(() => {
        // if (global.selectedTab != "players") global.setSelectedTab("players");
        // const teamID = database.get("selectedTeam").TeamID;

        // let params = util.getParametersFromUrl();
        // if (params.matchID) {
        //     api.get("GetMatch?id=" + params.matchID, success, fail);
        // } else {
        //     api.get("GetLastMatch?teamID=" + teamID, success, fail);
        // }
    });

    useEffect(() => {
        if (!players.length > 0) {
            let pls = [];
            props.data.map((player, index) => {
                pls.push({ ...player });
            })
            // setMatch(m);
            setPlayers(pls);
        }
    });

    function save() {
        props.onSave(players);
    }

    function onSaveSuccess(data) {
        let params = util.getParametersFromUrl();
        if (params.backto) {
            window.location = "#/" + params.backto;
        } else {
            window.location = "#/teamPage";
        }
    }

    function onSaveFail(data) {
        setLoading(false);
    }

    function success(data) {
        setLoading(false);
        setMatch(data);
        setPlayers(data.Players);
    }

    function fail(data) {
        setLoading(false);
    }

    function getSelectedCount() {

        let count = 0;

        if (players && players.length) {
            players.forEach((sp) => {
                if (sp.ForTeam) {

                    count += 1;
                }
            })
        }

        return count;
    }

    const leftRightHandler = useSwipeable({
        onSwiped: (eventData) => {
            console.log("User Swiped!", eventData);
            console.log(sp);
            console.log(plid);
            let ft = 0;

            dir = eventData.dir;

            if (eventData.dir == "Right") {
                ft = 2;
                $("#pl" + sp).addClass("fadeRight");
            }

            if (eventData.dir == "Left") {
                ft = 1;
                $("#pl" + sp).addClass("fadeLeft");
            }


            setTimeout(() => {
                let arr = [...players];
                arr[sp].ForTeam = ft;
                setSelectedPlayerIndex(sp);
                setPlayers(arr);
            }, 200)

        }
    });


    const blueSwipeHendler = useSwipeable({
        onSwiped: (eventData) => {
            console.log("User Swiped blue!", eventData);
            let ft = 0;

            dir = eventData.dir;

            if (eventData.dir == "Right") {
                ft = 0;
                $("#pl" + sp).addClass("fadeRight");
            }

            setTimeout(() => {
                let arr = [...players];
                arr[sp].ForTeam = ft;
                setSelectedPlayerIndex(sp);
                setPlayers(arr);
            }, 200)

        }
    });

    const redSwipeHendler = useSwipeable({
        onSwiped: (eventData) => {
            console.log("User Swiped blue!", eventData);
            let ft = 0;

            dir = eventData.dir;

            if (eventData.dir == "Left") {
                ft = 0;
                $("#pl" + sp).addClass("fadeLeft");
            }

            setTimeout(() => {
                let arr = [...players];
                arr[sp].ForTeam = ft;
                setSelectedPlayerIndex(sp);
                setPlayers(arr);
            }, 200)

        }
    });

    function remove(index) {
        $("#pright" + index).addClass("opout");
        $("#pleft" + index).addClass("opout");
        setTimeout(() => {

            let arr = [...players];
            arr[index].ForTeam = 0;
            setsp(index);
            setSelectedPlayerIndex(index);
            setPlayers(arr);
        }, 200)
    }


    let dir = null;
    let plid = 0;

    let lp = 1;
    let rp = 1;


    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (

                players && players.length > 1 ?
                    <>
                        <Box sx={{ flexGrow: 1 }}>
                            <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                                <Toolbar>
                                    <IconButton onClick={(e) => { props.onCancel() }} size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                        Cancel
                                    </IconButton>
                                    <Typography component="div" sx={{ flexGrow: 1 }}>
                                        {/* Selected players {getSelectedCount()} */}
                                    </Typography>

                                    <IconButton onClick={save} size="large" edge="end" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                        {/* <CheckOutlined></CheckOutlined> */}
                                        OK
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                        </Box>

                        <div className="">
                            <table style={{ width: "100%", fontSize: "13px", tableLayout: "fixed" }}>
                                <tbody>
                                    <tr style={{ height: "40px" }}>
                                        <td style={{ width: "30%", display: "table-cell" }}>
                                            <img style={{ width: "20px" }} src={Team1img}></img>
                                        </td>
                                        <td>
                                            <br></br>

                                            <LabelControl style={{fontSize:10}}>
                                                <SwipeIcon></SwipeIcon><br></br>
                                                 swipe players
                                            </LabelControl>
                                        </td>
                                        <td style={{ width: "30%" }}>
                                            <img style={{ width: "20px" }} src={Team2img}></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "top" }} {...blueSwipeHendler}>

                                            {players.map((player, index) => {
                                                return player.ForTeam == 1 ? lp++ && (
                                                    <div key={"pleft" + index} id={"pleft" + index} onClick={() => remove(index)} className={index == selectedPlayerIndex ? "newPlayerEffect" : ""} onTouchStart={() => { setsp(index); plid = player.PlayerID }} style={{ width: "100%", padding: "4px" }} >
                                                        <LabelControl> {lp - 1}. {player.Nick}</LabelControl>
                                                    </div>
                                                ) : null
                                            })}
                                        </td>
                                        <td style={{ verticalAlign: "top" }}{...leftRightHandler}>
                                            <div className="fixed-container-selectPlayers">
                                                {players.map((player, index) => {
                                                    return player.ForTeam == 0 ? (
                                                        <div key={"pl" + index} id={"pl" + index} className={index == selectedPlayerIndex ? "opin" : ""} onTouchStart={() => { setsp(index) }}>

                                                            <table style={{ width: "100%" }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <LabelControl style={{ fontF: "arial" }}>   </LabelControl>
                                                                        </td>
                                                                        <td>
                                                                            <LabelControl style={{ fontSize: "20px" }}>  {player.Nick} </LabelControl>

                                                                        </td>
                                                                        <td>
                                                                            <LabelControl>    </LabelControl>
                                                                        </td>

                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ) : null
                                                })}
                                            </div>
                                        </td>
                                        <td style={{ verticalAlign: "top" }} {...redSwipeHendler}>
                                            {players.map((player, index) => {
                                                return player.ForTeam == 2 && rp++ ? (
                                                    <div key={"pright" + index} id={"pright" + index} onClick={() => remove(index)} className={index == selectedPlayerIndex ? "newPlayerEffect" : ""} onTouchStart={() => setsp(index)} style={{ width: "100%", padding: "4px" }} >
                                                        <LabelControl>{rp - 1}. {player.Nick}</LabelControl>
                                                    </div>
                                                ) : null
                                            })}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                    :
                    <>
                        <LabelControl>You do not have any players added to group. Please add players first.</LabelControl>
                        <MuiButton onClick={() => window.location = "#/playerList"}>Manage players</MuiButton>
                    </>
            )}
        </div>
    );
}

export default SelectPlayers2;
