import React, { useState, useRef, useContext } from "react";
import { faParking, faMoneyCheck, faUsers, faFutbol, faGlasses } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import database from "../../util/database";
import { I18nextProvider, useTranslation } from "react-i18next";
import $ from "jquery";
import CircularProgress from "@mui/material/CircularProgress";
import { alertTitleClasses } from "@mui/material";

import { Oval } from "react-loader-spinner";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
 
// const useConstructor = util.useConstructor();

let DividerControl = (props) => {
    // useConstructor(() => {

    // });
    return (
        <>
        
        <div style={props.style} className={database.get("theme") == "light" ? "divider-light" : "divider-dark"}>
            {props.children}
            <br></br>
            {props.img && <img src={props.img} style={props.style}></img>}
        </div>
        
        </>
        
    );
};

export default DividerControl;