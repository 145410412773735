import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, HashRouter } from "react-router-dom";

import Home from "../components/home";
import Stats from "./stats";
import NewMatch from "../components/newMatch";
import MatchDetails from "../components/matchDetails";
import SelectTeam from "../components/selectTeam";
import Notification from "../components/notifications";
import Language from "../components/language";
import UserProfile from "../components/userProfile";
import TopBarMenu from "../components/topBarMenu";
import TeamPage from "../components/teamPage";
import Tracker from "../components/tracker";
import TeamEditor from "../components/teamEditor";
import BottomBarMenu from "../components/bottomBarMenu";
import SuccessForm from "../components/success";
import Settings from "../components/settings";
import LogIn from "../components/logIn";
import SelectPlayers from "./selectPlayers2";
import Invitations from "../components/invitations";
import AddPlayers from "../components/addPlayers";
import PlayerList from "../components/playerList";
import PrivacyPolicy from "../components/privacyPolicy";
import PlayerProfile from "../components/playerProfile";
import Registration from "../components/registration";
import SelectResult from "../components/selectResult2";
import Test from "../components/test";
import api from "../util/api";
import database from "../util/database";
import GlobalContext from "../components/globalContext";
import PasswordChange from "./passwordChange";
import About from "./about";

function Navigation (props) {
  
    const [user, setUser] = useState(database.get("user"));
    const [selectedTab, setSelectedTab] = useState(0);
    const [showSideMenu, setShowSideMenu] = useState(false);


    const global = useContext(GlobalContext);

    useEffect(() => {
          //alert("cdm navigation " + this.props.selectedTab);
          if (selectedTab != props.selectedTab) {
            setSelectedTab(props.selectedTab);          
        }
    });

    function onMenuClick(item) {
 
        if (item == "settings") {
            window.location = "#/settings";
        }

        if (item == "logout") {
            database.set("user", null);
            sessionStorage.setItem("init", false);
            // alert("try to disconnect");
            if(window.plugins && window.plugins.googleplus){
                window.plugins.googleplus.disconnect(
                    function (msg) {
                    //   alert("disconected"); // do something useful instead of alerting
                    },
                    function (msg) {
                        // alert("disconected error"); // do something useful instead of alerting
                      }
                );
                // window.plugins.googleplus.logout(
                //     function (msg) {
                    
                //     }
                // );
            }

            setUser(null);
            window.location = "#/login";
        }

        global.setSelectedTab(item);
        setSelectedTab(item);
        setShowSideMenu(false);
    };

    function onHamburgerClick (data) {
        setShowSideMenu(data)
    };

    return (
        <div className={database.get("theme") == "light" ? "wrapper-light" : "wrapper-dark"} style={{position:"fixed", width:"100%"}}>
            <header  >{global.selectedTab!="login" && global.selectedTab!="register" && selectedTab && !window.location.toString().includes("futsa.online") ? <TopBarMenu onMenuClick={onMenuClick} onHamburgerClick={onHamburgerClick} showSideMenu={showSideMenu} selectedTab={selectedTab}></TopBarMenu> : null}</header>
            {/* <header>{<TopBarMenu onMenuClick={onMenuClick} onHamburgerClick={onHamburgerClick} showSideMenu={showSideMenu} selectedTab={selectedTab}></TopBarMenu>}</header> */}
            <main>
                <HashRouter>
                    <Switch>
                        {<Route exact path="/" component={Home}></Route>}
                        {<Route path="/home" component={Home}></Route>}
                        {<Route path="/about" component={About}></Route>}
                        {<Route path="/test" component={Test}></Route>}
                        {<Route path="/tracker" component={Tracker}></Route>}
                        {<Route path="/invitations" component={Invitations}></Route>}
                        {<Route path="/selectResult" component={SelectResult}></Route>}                  
                        {<Route path="/addPlayers" component={AddPlayers}></Route>}
                        {<Route path="/login" component={LogIn}></Route>}
                        {<Route path="/stats" component={Stats}></Route>}
                        {<Route path="/newmatch" component={NewMatch}></Route>}
                        {<Route path="/matchDetails" component={MatchDetails}></Route>}
                        {<Route path="/teamPage" component={TeamPage}></Route>}
                        {<Route path="/selectteam" component={SelectTeam}></Route>}
                        {<Route path="/notifications" component={Notification}></Route>}
                        {<Route path="/language" component={Language}></Route>}
                        {<Route path="/userprofile" component={UserProfile}></Route>}
                        {<Route path="/success" component={SuccessForm}></Route>}
                        {<Route path="/settings" component={Settings}></Route>}
                        {<Route path="/register" component={Registration}></Route>}
                        {<Route path="/teamEditor" component={TeamEditor}></Route>}
                        {<Route path="/players" component={SelectPlayers}></Route>}
                        {<Route path="/playerList" component={PlayerList}></Route>}
                        {<Route path="/playerProfile" component={PlayerProfile}></Route>}
                        {<Route path="/privacyPolicy" component={PrivacyPolicy}></Route>}
                        {<Route path="/passwordChange" component={PasswordChange}></Route>}
                        {/* {<Route path="/success" render={(props) => <SuccessForm {...props}/>} ></Route>} */}
                  
                    </Switch>
                </HashRouter>
            </main>
            {/* <footer style={{ position: "fixed", width: "100%", bottom: "0px", zIndex: "9999" }}>{global.selectedTab!="login" && global.selectedTab!="register" && selectedTab && <BottomBarMenu onMenuClick={onMenuClick} selectedTab={selectedTab}></BottomBarMenu>}</footer> */}
        </div>
    );
    
}

export default Navigation;
