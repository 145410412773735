import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faParking } from "@fortawesome/free-solid-svg-icons";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import GlobalContext from "../components/globalContext";
import { ButtonControl, LabelControl, PageTitleControl, MuiButton, DividerControl } from "../components/controls/controls";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoaderControl from "../components/controls/loaderControl";
import GroupLight from "../images/grouplight.png";
import GroupDark from "../images/group.png";
import { data } from "jquery";

import Team1img from '../images/team1.png';
import Team2img from '../images/team2.png';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Shirt from './shirt';

const useConstructor = util.useConstructor();
import { HexColorPicker } from "react-colorful";

function TeamEditor(props) {

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);

    const global = useContext(GlobalContext);
    const [mode, setMode] = useState("edit");

    const [changesMade, setChangesMade] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openReset, setOpenReset] = React.useState(false);
    const [showColorPicker, setShowColorPicker] = React.useState(false);
    const [color, setColor] = React.useState();
    const [colorFor, setColorFor] = React.useState(0);
    const [team, setTeam] = React.useState({
        Team1Color: "blue",
        Team2Color: "red",
        Team1Color: "white",
        Team2Color: "black",
        Team1Name: "Team 1",
        Team2Name: "Team 2"
    });
    const [team1Color, setTeam1Color] = React.useState("#287bf1");
    const [team2Color, setTeam2Color] = React.useState("#ffffff");
    const [team1Color2, setTeam1Color2] = React.useState("#ffffff");
    const [team2Color2, setTeam2Color2] = React.useState("#000000");
    const [team1Name, setTeam1Name] = React.useState();
    const [team2Name, setTeam2Name] = React.useState();

    useConstructor(() => {

        let params = util.getParametersFromUrl(window.location);

        setMode(props.mode);

        if (props.mode == "add" || params.add) {
            setMode("add");
            if (global.selectedTab != "teamEditor") global.setSelectedTab("teamEditor");
        }

        if (props.mode == "edit") {
            let team = database.get("selectedTeam");
            setTeam(team)
            setName(team.Name);
            setTeam1Name(team.Team1Name);
            setTeam2Name(team.Team2Name);
            setTeam1Color(team.Team1Color);
            setTeam2Color(team.Team2Color);
            setTeam1Color2(team.Team1Color2);
            setTeam2Color2(team.Team2Color2);

            setDescription(team.Description || "");
        }

    });


    function save() {
        setLoading(true);

        let data = { name: name, description: description };

        if (props.mode == "edit") {
            data = database.get("selectedTeam");
            data.name = name;
            data.description = description;
            data.team1Color = team1Color;
            data.team2Color = team2Color;
            data.team1Color2 = team1Color2;
            data.team2Color2 = team2Color2;
            data.team1Name = team1Name;
            data.team2Name = team2Name;

            api.post("UpdateTeam", data, success, fail);
        } else {
            // data = database.get("selectedTeam");
            data.name = name;
            data.description = description;
            data.team1Color = team1Color;
            data.team2Color = team2Color;
            data.team1Color2 = team1Color2;
            data.team2Color2 = team2Color2;
            data.team1Name = team1Name;
            data.team2Name = team2Name;

            api.post("AddTeam", data, success, fail);
        }
    }

    function success(team) {

        setChangesMade(false);
        global.setTeamName(team.Name);
        database.set("teamName", team.Name);
        database.set("selectedTeam", team);
        database.set("season", team.Season);
        database.set("selectedTeamID", team.TeamID);
        if (mode == "add") {
            window.location = "#/teamPage?firstTime=true";
        } else {
            window.location.reload();
        }


    }

    function fail(data) {
        setLoading(false);
    }

    function back() {

        if (mode == "edit") {

        } else {
            window.location = "#/home";
        }
    }



    function leave() {

        if (confirm("Leave group?")) {
            api.get("LeaveTeam?teamID=" + database.get("selectedTeam").TeamID, (data) => {
                if (data.Success) {
                    if (props.onLeave) {
                        props.onLeave();
                    }

                    window.location = "#/home";
                }
            })
        }

    }

    function deleteGroup() {
        api.get("DeleteTeam?teamID=" + database.get("selectedTeam").TeamID, (data) => {

            debugger;
            if (data.Success) {
                if (props.onLeaveDelete) {
                    props.onLeaveDelete();
                }

                window.location = "#/home";
            } else {
                alert(data.ErrorMessage)
            }
        })
    }

    function resetGroup() {
        setLoading(true);
        api.get("ResetTeam?teamID=" + database.get("selectedTeam").TeamID, (data) => {
            if (data.Success) {
                if (props.onLeaveDelete) {
                    props.onLeaveDelete();
                }

                window.location.reload();
            } else {
                alert(data.ErrorMessage)
            }
            setLoading(false);
        })
    }

    function onNameChange(e) {
        if (global.iAmAdmin || mode == "add") {
            setName(e.target.value);
            setChangesMade(true);
        }
    }

    function onTeam1NameChange(e) {
        if (global.iAmAdmin || mode == "add") {
            setTeam1Name(e.target.value);
            setChangesMade(true);
        }
    }

    function onTeam2NameChange(e) {
        if (global.iAmAdmin || mode == "add") {
            setTeam2Name(e.target.value);
            setChangesMade(true);
        }
    }


    function onDescriptionChange(e) {
        if (global.iAmAdmin || mode == "add") {
            setDescription(e.target.value);
            setChangesMade(true);
        }
    }

    function onColorPick(e) {
        let t = { ...team };

        switch (colorFor) {
            case 1:
                setTeam1Color(color);
                break;
            case 2:
                setTeam2Color(color);
                break;
            case 3:
                setTeam1Color2(color);
                break;
            case 4:
                setTeam2Color2(color);
                break;

        }

        setTeam(t);

        setShowColorPicker(false);
    }

    function setColorForFor(index) {
        setColorFor(index);
        setShowColorPicker(true);
    }

    function onColorChange(event) {
        setColor(event.target.value);
    };



    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <>
                    {/* <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
                        <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                            <Toolbar>
                                <Typography style={{ textAlign: "left" }} component="div" sx={{ flexGrow: 1 }}>
                                    <p style={{ fontSize: "12px" }}>New group</p>
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Box> */}



                    <div style={{ padding: "20px", paddingBottom: "100px" }}>
                        <TextField value={name} onChange={onNameChange} label={util.translate("groupName")} variant="outlined" style={{ marginTop: 10, width: "100%" }} />
                        <br></br>
                        <br></br>

                        <TextField value={description} onChange={onDescriptionChange} multiline={true} rows={4} label={util.translate("description")} variant="outlined" style={{ marginTop: 10, width: "100%" }} />
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        {global.iAmAdmin || mode == "add" ?
                            <>
                                <TextField value={team1Name} onChange={onTeam1NameChange} label={util.translate("team1Name")} variant="outlined" style={{ marginTop: 10, width: "100%" }} />
                                <br></br>
                                <br></br>

                                <TextField value={team2Name} onChange={onTeam2NameChange} label={util.translate("team2Name")} variant="outlined" style={{ marginTop: 10, width: "100%" }} />

                                <br></br>
                                <br></br>

                                <DividerControl>Pick team colors</DividerControl>
                                <br></br>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td onClick={() => setColorForFor(1)}>
                                                <Shirt label="Team 1" color={team1Color} color2={team1Color2} number={10}></Shirt>
                                            </td>
                                            <td onClick={() => setColorForFor(2)}>
                                                <Shirt label="Team 2" color={team2Color} color2={team2Color2} number={10}></Shirt>
                                            </td>
                                        </tr>
                                        <br></br>
                                        <tr>
                                            <td style={{ padding: 1 }}>
                                                <MuiButton variant="contained" style={{ width: "100%", fontSize: 10 }} onClick={() => setColorForFor(1)}>
                                                    Change shirt color
                                                </MuiButton>
                                            </td>
                                            <td style={{ padding: 1 }}>
                                                <MuiButton variant="contained" style={{ width: "100%", fontSize: 10 }} onClick={() => setColorForFor(2)}>
                                                    Change shirt color
                                                </MuiButton>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: 1 }}>

                                                <MuiButton variant="contained" style={{ width: "100%", fontSize: 10 }} onClick={() => setColorForFor(3)}>
                                                    Change number color
                                                </MuiButton>
                                            </td>
                                            <td style={{ padding: 1 }}>
                                                <MuiButton variant="contained" style={{ width: "100%", fontSize: 10 }} onClick={() => setColorForFor(4)}>
                                                    Change number color
                                                </MuiButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </> : null}
                        <br></br><br></br><br></br><br></br>

                        {mode != "add" && global.iAmAdmin ?
                            <>
                                <MuiButton color="error" variant="contained" style={{ width: "170px" }} onClick={() => { setOpenReset(true) }}>
                                    {util.translate("resetStandings")}
                                </MuiButton>
                                <br></br><br></br>
                            </> : null}

                        {mode != "add" && global.iAmAdmin && database.get("user").userID == database.get("selectedTeam").CreatorID ?
                            <>
                                <MuiButton color="error" variant="contained" style={{ width: "170px" }} onClick={() => setOpenDelete(true)}>
                                    {util.translate("deleteGroup")}
                                </MuiButton>
                                <br></br><br></br>
                            </> : null}

                        {database.get("selectedTeam") && database.get("user").userID != database.get("selectedTeam").CreatorID && mode != "add" ? (
                            <MuiButton variant="contained" style={{ width: "170px" }} onClick={leave}>
                                {util.translate("leaveGroup")}
                            </MuiButton>
                        ) : null}
                        <br></br><br></br>

                        {global.iAmAdmin || mode == "add" ?
                            <>
                                {/* <Button variant="contained" style={{ width: "100px" }} onClick={() => back()}>
                                {util.translate("cancel")}
                            </Button>{" "}
                            &nbsp; */}
                                <MuiButton variant="contained" style={{ width: "170px" }} disabled={!name || !changesMade || !team1Name || !team2Name} onClick={save}>
                                    {util.translate("save")}
                                </MuiButton>
                            </> : null}
                    </div>



                    <br></br><br></br>


                    {/* {database.get("selectedTeam") && database.get("user").userID != database.get("selectedTeam").CreatorID && mode != "add" ? (
                        <MuiButton variant="contained" style={{ width: "170px" }} onClick={leave}>
                            {util.translate("leaveGroup")}
                        </MuiButton>
                    ) : null} */}
                </>
            )}

            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {util.translate("deleteGroupAreYouSure")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)}>{util.translate("no")}</Button>
                    <Button onClick={deleteGroup} autoFocus>
                        {util.translate("yes")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openReset}
                onClose={() => setOpenReset(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {util.translate("resetStandingsAreYouSure")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenReset(false)}>{util.translate("no")}</Button>
                    <Button onClick={resetGroup} autoFocus>
                        {util.translate("yes")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showColorPicker}
                onClose={() => setShowColorPicker(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}
                <DialogContent>
                    <HexColorPicker color={color} onChange={(c) => { setColor(c); setChangesMade(true) }}></HexColorPicker>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowColorPicker(false)}>Cancel</Button>
                    <Button onClick={onColorPick} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default TeamEditor;
