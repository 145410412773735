import React from "react";

import { faParking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";
import util from "../util/util";
import api from "../util/api";
import database from "../util/database";

function Settings(props) {
    let userDeviceID = database.get("user") ? database.get("user").userDeviceGUID : "";

    return (
        <div className="component">
            <div>center</div>
        </div>
    );
}

export default Settings;
