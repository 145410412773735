import React, { useState } from "react";

import util from "../../util/util";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

function Snack(props) {
    const [loading, setLoading] = useState(false);
    const [reservations, setReservations] = useState([]);

    return (
        <Snackbar open={props.open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => props.onClose(false)}>
            <Stack sx={{ width: "100%" }}>
                <MuiAlert elevation={6} severity={props.type} variant="filled" style={{ width: "100%" }}>
                     
                    {props.message}
                </MuiAlert>
            </Stack>
        </Snackbar>
    );
}

export default Snack;
