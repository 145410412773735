import React from "react";
import database from "../util/database";
import { faParking, faMoneyCheck, faUsers, faFutbol } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SelectTeam(props) {
    let teams = database.get("teams");

    function teamClick(team) {
        database.set("selectedTeamID", team.TeamID);
        window.location = "#/home";
    }

    return (
        <div className="component-container" style={{textAlign: "center"}}>
            {teams.map((team, index) => {
                return (
                    <>
                        <button className="team-button clickable animated fadeInUp" onClick={() => teamClick(team)}>
                            <div>
                                <FontAwesomeIcon style={{ fontSize: "40" }} icon={faUsers}></FontAwesomeIcon>

                                <div style={{ fontSize: "12px", marginTop: 10 }}>
                                    <p>{team.Name}</p>
                                </div>
                            </div>
                        </button>
                        <br></br>
                    </>
                );
            })}
        </div>
    );
}

export default SelectTeam;
