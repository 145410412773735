import React, { useState, useRef, useContext, useEffect } from "react";
import database from "../util/database";
import { faParking, faMoneyCheck, faUsers, faFutbol } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../util/api";
import util from "../util/util";
import { ButtonControl, PageTitleControl, LabelControl, DividerControl } from "../components/controls/controls";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LoaderControl from '../components/controls/loaderControl';

const useConstructor = util.useConstructor();

function CloseMatch(props) {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    // const [players, setPlayers] = React.useState([]);
    const [match, setMatch] = React.useState();
    const [date, setDate] = React.useState();
    const [dateString, setDateString] = React.useState();
    const [team1Score, setTeam1Score] = React.useState(0);
    const [team2Score, setTeam2Score] = React.useState(0);
    const [team1OwnGoals, setTeam1OwnGoals] = React.useState(0);
    const [team2OwnGoals, setTeam2OwnGoals] = React.useState(0);
    const [team1Total, setTeam1Total] = React.useState(0);
    const [team2Total, setTeam2Total] = React.useState(0);

    useConstructor(() => {
        // if (props.playerss){
        //     setPlayers([...props.playerss]);
        // }
        let team1s = 0;
        let team2s = 0;

        props.playerss.map((p, index) => {
            if(p.Goals){
                if(p.ForTeam == 1){
                    team1s += p.Goals;                                      
                }
                if(p.ForTeam == 2){
                    team2s += p.Goals;   
                }
            }
        })

        setTeam1Score(team1s);
        setTeam2Score(team2s);
        
        console.log("dinmao")
        //api.get("GetTeamInfo?id=" + teamID, success, fail);
    });

    useEffect(() => {
        //alert("cdm navigation " + this.props.selectedTab);
        console.log(props.playerss);
        if (props.playerss.length == 0) {
            // setPlayers(props.playerss);
         
        }
    });

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    }

    const theme = useTheme();

    const onTeam1ScoreChange = (e, newValue) => {
        setTeam1Score(e.target.value);
    };

    const onTeam2ScoreChange = (e, newValue) => {
        setTeam2Score(e.target.value);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const hundred = [100];

    const playerPlusGoal = (index, pl) => {
        let pls = [...props.playerss];
        let player = pl || pls[index];
        player.Goals += 1;

       
        if (player.ForTeam == 1) {
            setTeam1Score(team1Score + 1);

        } else {
            setTeam2Score(team2Score + 1);
        }
        //setPlayers(pls);
    }

    const cancel = () => {
        // setPlayers([]);
        let pls = [...props.playerss];
        pls = [];
        // alert("DSADSA");
        if (props.onCancel)
            props.onCancel();
    } 


    const playerMinusGoal = (index, pl) => {

        let pls = [...props.playerss];
        let player = pl || pls[index];


        if (player.Goals > 0) {
            player.Goals -= 1;

            if (player.ForTeam == 1) {
                if (team1Score > 0)
                    setTeam1Score(team1Score - 1)
            } else {
                if (team2Score > 0)
                    setTeam2Score(team2Score - 1)
            }

            // setPlayers(pls);
        }
    }

    const playerPlusAssists = (index) => {
        let pls = [...props.playerss];
        pls[index].Assists += 1;

        props.apply(pls);
        //setPlayers(pls);
    }

    const playerMinusAssists = (index) => {
        let pls = [...props.playerss];
        pls[index].Assists -= 1;
        if (pls[index].Assists < 0)
            pls[index].Assists = 0;

        props.apply(pls);
        //setPlayers(pls);
    }

    const teamPlusOwnGoal = (forTeam) => {
        if (forTeam == 1) {
            setTeam1OwnGoals(team1OwnGoals + 1);
            playerPlusGoal(-1, { ForTeam: 2, Goals: 1 });

        } else {
            setTeam2OwnGoals(team2OwnGoals + 1);
            playerPlusGoal(-1, { ForTeam: 1, Goals: 1 });
        }
    }

    const teamMinusOwnGoal = (forTeam) => {
        if (forTeam == 1) {
            if (team1OwnGoals > 0) {
                setTeam1OwnGoals(team1OwnGoals - 1);
                playerMinusGoal(-1, { ForTeam: 2, Goals: 1 })
            }
        } else {
            if (team2OwnGoals > 0) {
                setTeam2OwnGoals(team2OwnGoals - 1);
                playerMinusGoal(-1, { ForTeam: 1, Goals: 1 })
            }
        }
    }




    const save = () => {
        if (props.onSave) {
            setLoading(true);
            let data = {
                Team1Score: team1Score,
                Team2Score: team2Score,
                players: [...props.playerss],
                MatchID: props.match.MatchID
            };
            props.onSave(data);
        }
    }

   
    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <>
                    {/* <PageTitleControl>{database.get("selectedTeam").Name}</PageTitleControl> */}

                    <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
                        <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                            <Tabs value={value} onChange={handleChange} indicatorColor="secondary" textColor="inherit" variant="fullWidth" aria-label="full width tabs example">
                                {/* <Tab label="Result" {...a11yProps(0)} /> */}
                                <Tab label="Scorers" {...a11yProps(0)} />
                                <Tab label="Assists" {...a11yProps(1)} />
                                {/* <Tab label="MVPs" {...a11yProps(2)} /> */}
                            </Tabs>
                        </AppBar>
                        <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex}>
                            {/* <TabPanel value={value} index={0} dir={theme.direction}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={team1Score}
                                                    label="Teascore"
                                                    onChange={onTeam1ScoreChange}
                                                >
                                                    {[...Array(100)].map((num, index) => {
                                                        return (<MenuItem key={"sc1-" + index} value={index}>{index}</MenuItem>)
                                                    })

                                                    }

                                                </Select>
                                            </td>
                                            <td>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={team2Score}
                                                    label="Teascore"
                                                    onChange={onTeam2ScoreChange}
                                                >
                                                    {[...Array(100)].map((num, index) => {
                                                        return (<MenuItem key={"sc1-" + index} value={index}>{index}</MenuItem>)
                                                    })

                                                    }

                                                </Select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel> */}
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <br></br>
                                <LabelControl style={{fontSize:"25px",fontWeight:"bold"}}>{team1Score} : {team2Score}</LabelControl>
                                <DividerControl>Blues</DividerControl>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        {props.playerss && props.playerss.map((player, index) => {
                                            return player.ForTeam == 1 && (
                                                <tr key={"pl1-1-" + index}>
                                                    <td style={{ width: "50px" }}>
                                                        <ButtonControl fast onClick={() => playerMinusGoal(index)}>-</ButtonControl>
                                                    </td>
                                                    <td>
                                                        <ButtonControl fast onClick={() => playerPlusGoal(index)} selectedd={player.Goals > 0}  >{player.Nick}  {player.Goals != "0" && <b>{"(" + player.Goals + ")"}</b>}</ButtonControl>
                                                    </td>
                                                    <td style={{ width: "50px" }}>
                                                        <ButtonControl fast onClick={() => playerPlusGoal(index)}>+</ButtonControl>
                                                    </td>
                                                </tr>
                                            )
                                        })}


                                        <tr key={"pl1-1-owng"}>
                                            <td style={{ width: "50px" }}>
                                                <ButtonControl fast onClick={() => teamMinusOwnGoal(1)}>-</ButtonControl>
                                            </td>
                                            <td>
                                                <ButtonControl fast onClick={() => teamPlusOwnGoal(1)} selectedd={team1OwnGoals > 0}> Own goals {team1OwnGoals} </ButtonControl>
                                            </td>
                                            <td style={{ width: "50px" }}>
                                                <ButtonControl fast onClick={() => teamPlusOwnGoal(1)}>+</ButtonControl>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>


                                <DividerControl>Reds</DividerControl>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        {props.playerss && props.playerss.map((player, index) => {
                                            return player.ForTeam == 2 && (
                                                <tr key={"pl2-" + index}>
                                                    <td style={{ width: "50px" }}>
                                                        <ButtonControl fast onClick={() => playerMinusGoal(index)}>-</ButtonControl>
                                                    </td>
                                                    <td>
                                                        <ButtonControl fast onClick={() => playerPlusGoal(index)} selectedd={player.Goals > 0} >{player.Nick}  {player.Goals != "0" && <b>{"(" + player.Goals + ")"}</b>}</ButtonControl>
                                                    </td>
                                                    <td style={{ width: "50px" }}>
                                                        <ButtonControl fast onClick={() => playerPlusGoal(index)}>+</ButtonControl>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                        <tr key={"pl1-1-owng"}>
                                            <td style={{ width: "50px" }}>
                                                <ButtonControl fast onClick={() => teamMinusOwnGoal(2)}>-</ButtonControl>
                                            </td>
                                            <td>
                                                <ButtonControl fast onClick={() => teamPlusOwnGoal(2)} selectedd={team2OwnGoals > 0}> Own goals {team2OwnGoals} </ButtonControl>
                                            </td>
                                            <td style={{ width: "50px" }}>
                                                <ButtonControl fast onClick={() => teamPlusOwnGoal(2)}>+</ButtonControl>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <DividerControl>Blues</DividerControl>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        {props.playerss && props.playerss.map((player, index) => {
                                            return player.ForTeam == 1 && (
                                                <tr key={"pl2-" + index}>
                                                    <td style={{ width: "50px" }}>
                                                        <ButtonControl fast onClick={() => playerMinusAssists(index)}>-</ButtonControl>
                                                    </td>
                                                    <td>
                                                        <ButtonControl fast onClick={() => playerPlusAssists(index)} selectedd={player.Assists > 0} >{player.Nick}  {player.Assists != "0" && <b>{"(" + player.Assists + ")"}</b>}</ButtonControl>
                                                    </td>
                                                    <td style={{ width: "50px" }}>
                                                        <ButtonControl fast onClick={() => playerPlusAssists(index)}>+</ButtonControl>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                <DividerControl>Reds</DividerControl>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        {props.playerss && props.playerss.map((player, index) => {
                                            return player.ForTeam == 2 && (
                                                <tr key={"pl1-" + index}>
                                                    <td style={{ width: "50px" }}>
                                                        <ButtonControl fast onClick={() => playerMinusAssists(index)}>-</ButtonControl>
                                                    </td>
                                                    <td>
                                                        <ButtonControl fast onClick={() => playerPlusAssists(index)} selectedd={player.Assists > 0} >{player.Nick}  {player.Assists != "0" && <b>{"(" + player.Assists + ")"}</b>}</ButtonControl>
                                                    </td>
                                                    <td style={{ width: "50px" }}>
                                                        <ButtonControl fast onClick={() => playerPlusAssists(index)}>+</ButtonControl>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </TabPanel>
                            {/* <TabPanel value={value} index={2} dir={theme.direction}>
                                Item Three
                            </TabPanel> */}
                        </SwipeableViews>
                        <ButtonControl onClick={() => { save() }}>Save</ButtonControl>
                        <ButtonControl onClick={() => { cancel() }}>Cancel</ButtonControl>
                    </Box>
                </>
            )}
        </div>
    );
}

export default CloseMatch;
