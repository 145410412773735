import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faParking } from "@fortawesome/free-solid-svg-icons";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import GlobalContext from "../components/globalContext";
import LoaderControl from '../components/controls/loaderControl';
import LabelControl from "./controls/labelControl";

const useConstructor = util.useConstructor();

function Tracker(props) {
    const [name, setName] = useState("");
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [loginHistory, setLoginHistory] = useState();
    useConstructor(() => {
        // if (global.selectedTab != "teamEditor") global.setSelectedTab("teamEditor");
     
        api.get("tracker", (data) => {
          
            setLoginHistory(data.LoginHistory);
        })
    });

    function success(data) { }

    function fail(data) { }

    function save() { }

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <>
                    <LabelControl style={{textAlign:"left", paddingLeft:"10px"}}>Login history</LabelControl>
                    <table style={{ width: "100%"}}>
                        <tbody>
                            {loginHistory && loginHistory.map((item, index) => {
                                return <tr>
                                    <td style={{textAlign:"left", paddingLeft:"10px"}}> 
                                        <LabelControl style={{ fontSize: "9px" }}>{item.Username}</LabelControl>
                                    </td>
                                    <td style={{textAlign:"left"}}>
                                        <LabelControl style={{ fontSize: "9px" }}>{new Date(item.LoginDate).toLocaleString()}</LabelControl>
                                    </td>
                                </tr>

                            })}

                        </tbody>

                    </table>

                </>
            )}
        </div>
    );
}

export default Tracker;
