import $ from "jquery";
import api from "./api";
import languages from "./languages";
import { useState, useRef } from "react";
import * as moment from "moment";
import html2canvas from "html2canvas";
import database from "./database";

export const util = {
    alert,
    getParametersFromUrl,
    getCurrentPosition,
    getPlatform,
    translate,
    getCookie,
    setCookie,
    setLanguage,
    isMobileDevice,
    unixToDateTime,
    getSecondsBetweenDates,
    getSecondsToTime,
    getDateFromDateTimeString,
    getTimeFromDateTimeString,
    useConstructor,
    sanitizePlateNumber,
    openTemplate,
    getOffsetDateTime,
    unsetOffsetDateTime,
    getDay,
    share,
    shareFutsa,
    getPermissionForiOSNotifications,
    getPermissionForAndroidNotifications,
    setTheme
};

// function getPlatform() {
//     deviceOS = "android";

//     if (typeof device != "undefined") {
//         if (
//             device.platform == "ios" ||
//             device.platform == "iOS" ||
//             device.platform == "IOS"
//         )
//             deviceOS = "ios";
//     } else {
//         deviceOS = "browser";
//     }

//     return deviceOS;
// }

function getTimeFromDateTimeString(date) {

    if (!date) {
        return new Date();
    }

    if (date && !date.getDate) {
        date = new Date(date);
    }

    return date.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ":" + date.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
}


function getDay(date) {
    const d = new Date(date);
    const dow = d.getDay();

    const lang = localStorage.getItem("lang");

    if (lang.toLowerCase() == "en") {
        switch (dow) {
            case 0: return "Sunday";
            case 1: return "Monday";
            case 2: return "Tuesday";
            case 3: return "Wednesday";
            case 4: return "Thursday";
            case 5: return "Friday";
            case 6: return "Sathurday";
        }
    } else {
        switch (dow) {
            case 0: return "Nedjelja";
            case 1: return "Ponedjeljak";
            case 2: return "Utorak";
            case 3: return "Srijeda";
            case 4: return "Četvrtak";
            case 5: return "Petak";
            case 6: return "Subota";
        }
    }

}

function getDateFromDateTimeString(date) {
    let todayDate = new Date();
    let tomorowDate = new Date();
    tomorowDate.setDate(tomorowDate.getDate() + 1);

    if (!date) {
        date = new Date();
    }

    if (date && !date.getDate) {
        date = new Date(date);
    }

    let today = todayDate.getDate() + "." + (todayDate.getMonth() + 1) + "." + todayDate.getFullYear();
    let tomorow = tomorowDate.getDate() + "." + (tomorowDate.getMonth() + 1) + "." + tomorowDate.getFullYear();
    let newDate = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();

    // if (today == newDate) return translate("today");

    // if (tomorow == newDate) return translate("tomorrow");

    return newDate;
}

function getSecondsToTime(seconds) {
    let measuredTime = new Date(null);
    measuredTime.setSeconds(seconds); // specify value of SECONDS
    return measuredTime.toISOString().substr(11, 8);
}

function getSecondsBetweenDates(date1, date2) {
    let dif = date1.getTime() - date2.getTime();
    let Seconds_from_T1_to_T2 = dif / 1000;
    return Math.abs(Seconds_from_T1_to_T2);
}

function unixToDateTime(data) {
    let unix_timestamp = data;
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(unix_timestamp * 1000);
    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime = hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);

    return formattedTime;
}

function isMobileDevice() {
    if (window.hasOwnProperty("cordova")) {
        return true;
    }

    // Or

    if (typeof cordova == "object") {
        return true;
    }
    // Or

    if (!!window.cordova) {
        return true;
    }

    return false;
}

function alert(text) {
    if (window.navigator.notification) {
        window.navigator.notification.alert(text, null, "naslov1", "ok1");
    } else {
        window.alert(text);
    }
}

function getParametersFromUrl(url) {

    if (!url)
        url = window.location;

    let params = {};
    (url + "?")
        .split("?")[1]
        .split("&")
        .forEach(function (pair) {
            pair = (pair + "=").split("=").map(decodeURIComponent);
            if (pair[0].length) {
                params[pair[0]] = pair[1];
            }
        });
    return params;
}

var getCurrentPositionSucessOut;
var getCurrentPositionErrorOut;

function getCurrentPosition(success, error) {
    getCurrentPositionSucessOut = success;
    getCurrentPositionErrorOut = error;

    navigator.geolocation.getCurrentPosition(getCurrentPositionSuccess, getCurrentPositionError, { enableHighAccuracy: true });
}

function getCurrentPositionSuccess(data) {
    getCurrentPositionSucessOut({ latitude: data.coords.latitude, longitude: data.coords.longitude });
}

function getCurrentPositionError(data) {
    getCurrentPositionErrorOut(data);
}

function getPlatform() {
    if (window.device) {
        return window.device.platform;
    }
    return "Browser";
}

function shareFutsa(){
    const options = {
        message: database.get("selectedTeam").Name, // not supported on some apps (Facebook, Instagram)
        subject: 'Standings', // fi. for email
        url: "http://futsa.online/#/teamPage?uid=" + database.get("TeamUID"),
        chooserTitle: 'Whatsapp' // Android only, you can override the default share sheet title
    };

    window.plugins.socialsharing.shareWithOptions(
        options,
        () => {
            node.style.backgroundColor = "transparent";
        },
        () => {
            node.style.backgroundColor = "transparent";
        }
    );
}

function translate(text) {
    //let lan = getCookie("lang");



    let lan = localStorage.getItem("lang");
    if (!lan) {
        //setCookie("lang", "hr");
        localStorage.setItem("lang", "EN");
        localStorage.setItem("langID", "2");
        lan = "EN";
    }

    if (languages[lan]) { return languages[lan][text] }
    else { return "Nema prijevoda - " + lan; }
}

function setLanguage(lang) {
    //setCookie("lang", lang);
    localStorage.setItem("lang", lang.shortName);
    localStorage.setItem("langID", lang.languageId);
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cookieName, value, expirationDate) {
    if (expirationDate) {
        document.cookie = cookieName + "=" + value + "; path=/; expires=" + expirationDate.toUTCString();
    } else {
        document.cookie = cookieName + "=" + value + "; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    }
}

function useConstructor() {
    return (callBack = () => { }) => {
        const hasBeenCalled = useRef(false);
        if (hasBeenCalled.current) return;
        callBack();
        hasBeenCalled.current = true;
    };
}

function sanitizePlateNumber(plateNumber) {
    // the characters i'm looking for in a string:
    var charList = [
        '"',
        "<",
        ">",
        "?",
        "!",
        ")",
        "(",
        "/",
        "$",
        "&",
        "%",
        "#",
        "*",
        "+",
        "=",
        "_",
        ";",
        ",",
        ".",
        ":",
        "-",
        " ",
        "Ą",
        "ą",
        "Ć",
        "ć",
        "Č",
        "ć",
        "Ę",
        "ę",
        "È",
        "è",
        "Ł",
        "ł",
        "Ó",
        "ó",
        "Ś",
        "ś",
        "Š",
        "š",
        "Ź",
        "ź",
        "Ż",
        "ź",
        "Ž",
        "ž",
        "Đ",
        "đ",
        "Ó",
        "ó",
        "Ö",
        "ö",
        "Ü",
        "ü",
        "Ä",
        "ä",
        "Ë",
        "ë",
        "Ï",
        "ï",
        "À",
        "à",
        "Á",
        "á",
        "Â",
        "â",
        "Ã",
        "ã",
        "Å",
        "å",
        "Æ",
        "æ",
        "Ç",
        "ç",
        "É",
        "é",
        "Ê",
        "ê",
        "Ì",
        "ì",
        "Í",
        "í",
        "Î",
        "î",
        "Ñ",
        "ñ",
        "Ò",
        "ò",
        "Ó",
        "ó",
        "Ô",
        "ô",
        "Õ",
        "õ",
        "Ø",
        "ø",
        "Ù",
        "ù",
        "Ú",
        "ú",
        "Û",
        "û",
        "Ü",
        "ü"
    ];

    // the characters i'd like to replace them with:
    var replaceList = [
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        " ",
        "A",
        "a",
        "C",
        "c",
        "C",
        "c",
        "E",
        "e",
        "E",
        "e",
        "L",
        "l",
        "O",
        "o",
        "S",
        "s",
        "S",
        "s",
        "Z",
        "z",
        "Z",
        "z",
        "Z",
        "z",
        "D",
        "d",
        "O",
        "o",
        "O",
        "o",
        "U",
        "u",
        "A",
        "a",
        "E",
        "e",
        "I",
        "i",
        "A",
        "a",
        "A",
        "a",
        "A",
        "a",
        "A",
        "a",
        "A",
        "a",
        "E",
        "e",
        "C",
        "c",
        "E",
        "e",
        "E",
        "e",
        "I",
        "i",
        "I",
        "i",
        "I",
        "i",
        "N",
        "n",
        "O",
        "o",
        "O",
        "o",
        "O",
        "o",
        "O",
        "o",
        "O",
        "o",
        "U",
        "u",
        "U",
        "u",
        "U",
        "u",
        "U",
        "u"
    ];

    var sanitizedPlateNumber = plateNumber;
    var limit = sanitizedPlateNumber.length;
    for (let i = 0; i < limit; i++) {
        for (var j in charList) {
            if (sanitizedPlateNumber.charAt(i) === charList[j]) {
                //našao sam karakter u registarskoj oznaci na poziciji i, koji odgovara problematičnom karakteru na poziciji j
                sanitizedPlateNumber = sanitizedPlateNumber.replace(
                    sanitizedPlateNumber.charAt(i),
                    replaceList[j]
                );
                //kako je moguće da smo ovim postupkom maknuli
            }
        }
    }
    sanitizedPlateNumber = sanitizedPlateNumber.replace(/\s/g, "");
    return sanitizedPlateNumber;
}

function openTemplate(template) {
    window.location = "#/paymentpreparation?ticket=" + template.Ticket + "&userdevicecardid=" + template.UserDeviceCardID + "&cityid=" + template.CityID + "&zoneid=" + template.ZoneID + "&zonetariffid=" + template.ZoneTariffID + "&quantity=" + template.Quantity;
};

function getOffsetDateTime(value) {
    if (!(value instanceof Date)) {
        value = new Date(value);
    }

    //shiftamo lokalno vrijeme pošto na serveru dobivamo utc vrijednost

    if (!value) return null;
    var offset = value.getTimezoneOffset();
    var newDateObj = moment(value).add(offset * -1, "m");
    return newDateObj.toDate();
}

function unsetOffsetDateTime(value) {
    //ovo je suprotna funkcija od getOffsetDateTime
    //služi kako se update-om observable-a koji je prethodno doživio getOffsetDateTime, ne bi dogodilo stalno shiftanje vremena
    if (!value) return null;
    var offset = value.getTimezoneOffset();
    var newDateObj = moment(value).add(offset, "m");
    return newDateObj.toDate();
}

function share(elementID) {
    var node = document.getElementById(elementID);
    html2canvas(node).then(function (canvas) {
        try {
            window.plugins.socialsharing.share(null, 'Futsa', canvas.toDataURL(), null);
        } catch (ex) {
            alert("err - " + ex);
        }
    });
}

function getPermissionForiOSNotifications() {
    const deviceOS = getPlatform();
    if (deviceOS.toUpperCase() == "IOS") {  
        if (window.FirebasePlugin) {
            window.FirebasePlugin.hasPermission(function (data) {
                if (data.isEnabled != true) {
                    window.FirebasePlugin.grantPermission(); // (iOS only);
                }
            });
        }
    }
}

function getPermissionForAndroidNotifications() {
    
    const deviceOS = getPlatform();
    
    if (deviceOS.toUpperCase() == "ANDROID") {
        
        if (window.FirebasePlugin) {
            
            window.FirebasePlugin.hasPermission(function (data) {
                
                if (data.isEnabled != true || data === false) {
                    window.FirebasePlugin.grantPermission((per) => {
                        
                    }); 
                }
            });
        }
    }
}

function setTheme(theme){
    database.set("theme", theme)
   
    if(window.NavigationBar){
        
        
        if(theme === "dark"){
            window.NavigationBar.backgroundColorByHexString("#121212", false);
        }else{
            window.NavigationBar.backgroundColorByName("white", true);
        }
    }
}

export default util;
