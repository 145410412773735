import React, { useState, useRef, useContext, useEffect } from "react";
import database from "../util/database";
import { faParking, faMoneyCheck, faUsers, faFutbol } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@mui/material/CircularProgress";
import util from "../util/util";
import api from "../util/api";
import { ButtonControl, PageTitleControl, LabelControl, MuiButton } from "../components/controls/controls";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ArrowBackIos } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Team1img from "../images/team1.png";
import Team2img from "../images/team2.png";
import Beer from "../images/beer.png";
import CloseIcon from "@mui/icons-material/Close";
import LoaderControl from "../components/controls/loaderControl";
import FootballPitch from "../components/FootballPitch2";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import Shirt from "./shirt";

const useConstructor = util.useConstructor();

function MatchDetails(props) {
    const [loading, setLoading] = useState();
    const [match, setMatch] = useState();
    const [team1, setTeam1] = useState([]);
    const [team2, setTeam2] = useState([]);
    const [matchID, setMatchID] = useState();
    const [players, setPlayers] = useState();
    const [totalPlayersCount, setTotalPlayersCount] = useState(0);

    function sortByNick(a, b) {
        // Convert both "Nick" properties to lowercase for case-insensitive sorting
        const nickA = a.Nick.toLowerCase();
        const nickB = b.Nick.toLowerCase();

        if (nickA < nickB) {
            return -1; // a should come before b
        }
        if (nickA > nickB) {
            return 1; // b should come before a
        }
        return 0; // a and b are equal
    }

    useConstructor(() => {
        if (props.data) {
            setMatch(props.data);
            setPlayersFromMatch(props.data);
            setTotalPlayersCount(getTotalPlayersCount(props.data));
        }
    });

    function getTotalPlayersCount(fromMatch) {
        let pc = 0;
        if (fromMatch && fromMatch.Players) {
            fromMatch.Players.map((player, index) => {
                if (player.ForTeam > 0) pc++;
            });
        }
        return pc;
    }

    function loadMatch(id) {
        setLoading(true);
        api.get("GetMatch?id=" + id, (data) => {
            setMatch(data);
            setPlayersFromMatch(data);
            setTotalPlayersCount(getTotalPlayersCount(data));
            setLoading(false);
        });
    }

    function setPlayersFromMatch(m) {
        var t1 = m.Players.filter(x => x.ForTeam == 1).sort((a, b) => {
            return a.Number - b.Number;
        });
        var t2 = m.Players.filter(x => x.ForTeam == 2).sort((a, b) => {
            return a.Number - b.Number;
        });

        // console.error(m);
        setTeam1(t1);
        setTeam2(t2);
    }


    useEffect(() => {
        if (props.matchID && props.matchID != matchID) {
            setMatchID(props.matchID);
            loadMatch(props.matchID);
        }

        if (!props.data) {
            return;
        }

        if ((props.data && !match) || match.Team1Score != props.data.Team1Score || match.Team2Score != props.data.Team2Score || match.Mvp != props.data.Mvp) {
            setMatch(props.data);
            setPlayersFromMatch(props.data);
        }
    });

    function onDatePlayedClick() {
        if (props.onDatePlayedClick) {
            props.onDatePlayedClick();
        }
    }

    return loading ? (
        <LoaderControl></LoaderControl>
    ) : (
        <>
            {!props.hideAppBar && (
                <CloseIcon
                    onClick={() => {
                        if (props.onClose) props.onClose();
                    }}
                    style={{ float: "right", margin: "5px" }}
                ></CloseIcon>
            )}

            <div className="component-container" style={{ textAlign: "center", padding: "20px", paddingTop: 5 }}>
                {match && (
                    <div id={props.id}>
                        <table style={{ padding: "5px", borderRadius: "10px", width: "100%", tableLayout: "fixed" }}>
                            <tbody>
                                <tr>
                                    <td onClick={onDatePlayedClick} style={{ padding: "5px", textAlign: "left" }}>
                                        <LabelControl className="fugaz" style={{ fontSize: "20px", fontWeight: "bold", opacity: 0.9 }}>
                                            {util.getDay(match.DatePlayed).toUpperCase()}
                                        </LabelControl>
                                        <LabelControl className="fugaz" style={{ fontSize: "15px", marginTop: "-10px", paddingLeft: 1, opacity: 0.4 }}>
                                            {util.getDateFromDateTimeString(match.DatePlayed)}
                                        </LabelControl>
                                    </td>
                                    {/* <td>
                                        <LabelControl style={{ fontSize: "30px", fontWeight: "bold", opacity: 0.7 }}>
                                            <AccessAlarmIcon></AccessAlarmIcon>
                                        </LabelControl>
                                    </td> */}
                                    <td onClick={onDatePlayedClick} style={{ textAlign: "right" }}>
                                        <LabelControl style={{ fontSize: "35px", fontWeight: "bold", opacity: 0.9, padding: "5px" }}>{util.getTimeFromDateTimeString(match.DatePlayed)}</LabelControl>
                                    </td>
                                </tr>
                                {match && match.MatchStatusTypeID == 2 ?
                                    <tr>
                                        <td colSpan={2} style={{}}>
                                            <LabelControl style={{ fontSize: 12 }}>{util.translate("fulltime")}</LabelControl>
                                        </td>
                                    </tr> : null}
                            </tbody>
                        </table>

                        <table style={{ width: "100%", tableLayout: "fixed" }}>
                            <tbody>
                                {(!props.hideField && props.data && props.data.MatchStatusTypeID == 2) || (match && match.MatchStatusTypeID == 2) ? (
                                    <tr>
                                        <td style={{ display: "table-cell" }}>
                                            {/* <img style={{ width: "40px" }} src={Team1img}></img> */}
                                            <Shirt label={props.team.Team1Name} color={props.team.Team1Color} color2={props.team.Team1Color2} number={10}></Shirt>
                                        </td>
                                        <td style={{ display: "table-cell" }} onClick={() => props.onResultClick && props.onResultClick()}>
                                            <LabelControl res style={{ fontSize: "25px", marginTop: 20, fontWeight: "bold" }}>
                                                {" "}
                                                <p className="res">
                                                    {" "}
                                                    {match && match.Team1Score} - {match && match.Team2Score}
                                                </p>
                                            </LabelControl>
                                        </td>
                                        <td style={{ display: "table-cell" }}>
                                            {/* <img style={{ width: "40px" }} src={Team2img}></img> */}
                                            <Shirt label={props.team.Team2Name} color={props.team.Team2Color} color2={props.team.Team2Color2} number={10}></Shirt>
                                        </td>
                                    </tr>
                                ) : null}
                                {/* <tr>
                                        <th style={{ display: "table-cell" }}>

                                            <LabelControl style={{ fontSize: "9px" }}>Scorers</LabelControl>
                                        </th>
                                        <th style={{ display: "table-cell" }}>
                                            <LabelControl style={{ fontSize: "9px" }}></LabelControl>
                                        </th>
                                        <th style={{ display: "table-cell" }}>

                                            <LabelControl style={{ fontSize: "9px" }}>Scorers</LabelControl>
                                        </th>
                                    </tr>  */}

                                {!props.hideField || (props.data && props.data.MatchStatusTypeID != 1) ? (
                                    <tr>
                                        <td colSpan={3} style={{ textAlign: "center" }}>
                                            <FootballPitch
                                                onPlayerClick={(p) => {
                                                    if (props.onPlayerClick) props.onPlayerClick(p);
                                                }}
                                                theme={database.get("theme")}
                                                mode={props.mode || "preview"}
                                                onDoubleClick={props.onPitchDoubleClick}
                                                width="100%"
                                                substitutions={match.Players.some(x => x.PositionX > 100 && x.ForTeam != 0)}
                                                // team1img={Team1img}
                                                // team2img={Team2img}
                                                players={match.Players}
                                                team={props.team}
                                            ></FootballPitch>
                                        </td>
                                    </tr>
                                ) : null}

                                {!props.hideLineups && totalPlayersCount > 0 ? (
                                    <>
                                        <tr>
                                            <td colSpan={3}>
                                                <LabelControl style={{ marginTop: 10, fontSize: 11 }}>Lineups</LabelControl>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>
                                                <table style={{ width: "100%" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top" style={{ width: "50%", padding: "1px" }}>
                                                                {match &&
                                                                    team1.map((player, index) => {
                                                                        if (player.ForTeam == 1)
                                                                            return (
                                                                                <MuiButton
                                                                                    // className={item.Team1Score == item.Team2Score ? "gray" : item.Team1Score > item.Team2Score ? "blue" : item.Team1Score < item.Team2Score ? "red" : "blue"}
                                                                                    className={database.get("theme") == "dark" ? "darkgray-dark-blue" : "darkgray-light-blue"}
                                                                                    variant={database.get("theme") == "light" ? "" : "contained"}
                                                                                    key={"match" + index}
                                                                                    style={{ width: "100%", marginTop: "2px" }}
                                                                                    noTimeOut

                                                                                    onClick={() => {
                                                                                        if (props.onLineUpPlayerClick) props.onLineUpPlayerClick(player);
                                                                                    }}
                                                                                >
                                                                                    <span className="plnum">{player.Number}</span>
                                                                                    {player.Nick}
                                                                                </MuiButton>



                                                                            );
                                                                    })}
                                                            </td>

                                                            <td valign="top" style={{ padding: "1px" }}>
                                                                {match &&
                                                                    team2.map((player, index) => {
                                                                        if (player.ForTeam == 2)
                                                                            return (
                                                                                <MuiButton
                                                                                    // className={item.Team1Score == item.Team2Score ? "gray" : item.Team1Score > item.Team2Score ? "blue" : item.Team1Score < item.Team2Score ? "red" : "blue"}
                                                                                    className={database.get("theme") == "dark" ? "darkgray-dark-red" : "darkgray-light-red"}
                                                                                    variant={database.get("theme") == "light" ? "" : "contained"}
                                                                                    key={"match" + index}
                                                                                    style={{ width: "100%", marginTop: "2px", textAlign: "left" }}
                                                                                    noTimeOut


                                                                                    onClick={() => {
                                                                                        if (props.onLineUpPlayerClick) props.onLineUpPlayerClick(player);
                                                                                    }}
                                                                                >
                                                                                    <>
                                                                                        <span className="plnum">{player.Number}</span>
                                                                                        {player.Nick}
                                                                                    </>

                                                                                </MuiButton>
                                                                            );
                                                                    })}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </>
                                ) : null}
                                <br></br>
                                <tr>
                                    <td style={{ display: "table-cell" }}>
                                        {match &&
                                            match.Players &&
                                            match.Players.map((player, index) => {
                                                if (player.Goals && player.ForTeam == 1)
                                                    return (
                                                        <LabelControl
                                                            onClick={() => {
                                                                if (props.onLineUpPlayerClick) props.onLineUpPlayerClick(player);
                                                            }}
                                                            key={"pl1" + index}
                                                            style={{ fontSize: "9px" }}
                                                        >
                                                            {player.Nick} ({player.Goals})
                                                        </LabelControl>
                                                    );
                                            })}
                                    </td>
                                    <td style={{ display: "table-cell" }}>
                                        {match && match.Players && match.Players.filter((x) => x.Goals && x.ForTeam != 0).length > 0 && (
                                            <LabelControl style={{ fontSize: "12px", fontWeight: "bold" }}>
                                                <SportsSoccerIcon></SportsSoccerIcon>
                                                <br></br>
                                                {util.translate("scorers")}
                                            </LabelControl>
                                        )}
                                    </td>
                                    <td style={{ display: "table-cell" }}>
                                        {match &&
                                            match.Players &&
                                            match.Players.map((player, index) => {
                                                if (player.Goals && player.ForTeam == 2)
                                                    return (
                                                        <LabelControl
                                                            onClick={() => {
                                                                if (props.onLineUpPlayerClick) props.onLineUpPlayerClick(player);
                                                            }}
                                                            key={"pl2" + index}
                                                            style={{ fontSize: "9px" }}
                                                        >
                                                            {player.Nick} ({player.Goals})
                                                        </LabelControl>
                                                    );
                                            })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {match.Mvp && match.Players.filter((x) => x.ForTeam != 0).length > 0 ? (
                            <div style={{ marginTop: "40px" }}>
                                <LabelControl style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    <img style={{ width: "40px" }} src={Beer}></img> <br></br>free beer for
                                </LabelControl>
                                <LabelControl style={{ paddingTop: "5px", fontSize: "20px" }}>
                                    {/* <img style={{ width: "20px" }} src={match.Mvp.ForTeam == 1 ? Team1img : Team2img}></img> {match.Mvp.Nick} */}
                                    {match.Mvp.ForTeam == 1 ?
                                        <Shirt color={props.team.Team1Color} color2={props.team.Team1Color2} number={match.Mvp.Number}></Shirt>
                                        :
                                        <Shirt color={props.team.Team2Color} color2={props.team.Team2Color2} number={match.Mvp.Number}></Shirt>
                                    }
                                    
                                    
                                </LabelControl>
                                <br></br>
                                <LabelControl style={{marginTop:"-40px"}}>
                                    {match.Mvp.Nick}
                                </LabelControl>
                                 
                            </div>
                        ) : null}
                    </div>
                )}

                {props.isAdmin && match && match.MatchID != 0 ? (
                    <div style={{ paddingBottom: "20px", textAlign: "center", marginTop: "100px" }}>
                        <MuiButton
                            style={{ margin: "4px", height: "40px", width: "70%", fontSize: "10px" }}
                            variant="contained"
                            color="error"
                            startIcon={<DeleteIcon></DeleteIcon>}
                            onClick={() => {
                                if (props.onDelete) props.onDelete(match.MatchID);
                                // setNewMatch(true);
                                // window.location = "#/newmatch?datePlayed=" + (match && match.DatePlayed);
                            }}
                        >
                            DELETE MATCH
                        </MuiButton>
                    </div>
                ) : null}

                {props.onShare ? (
                    <div
                        className="stat-td"
                        onClick={() => {
                            props.onShare();
                        }}
                    >
                        <ShareIcon style={{ marginTop: 10, marginBottom: 30 }}></ShareIcon>
                    </div>
                ) : null}
            </div>
        </>
    );
}

export default MatchDetails;
