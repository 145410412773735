import $ from "jquery";
import database from "./database";
import util from "../util/util";

// const host = process.env.NODE_ENV == "development" ? "http://vsprojects-002-site1.htempurl.com/" : "http://vsprojects-002-site1.htempurl.com/";
const host = process.env.NODE_ENV == "development" ? "http://vsprojects-002-site1.htempurl.com/" : "http://vsprojects-002-site1.htempurl.com/";
// const host = process.env.NODE_ENV == "development" ? "https://localhost:44359/" : "http://vsprojects-002-site1.htempurl.com/";
// const host = process.env.NODE_ENV == "development" ? "https://futsa.azurewebsites.net/" : "https://futsa.azurewebsites.net/";
// const host = "http://localhost:20744";
const hostapi = process.env.NODE_ENV == "development" ? host : host;

export const api = {
    get,
    post,
    logIn,
    logOut,
    postOut,
    register
};

function getAuthHeader() {
    let user = database.get("user");
    if (user && (user.token || user.access_token)) {
        return {
            Authorization: "Bearer " + (user.token || user.access_token),
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    } else {
        return {};
    }
}

function get(url, sucess, fail) {

    fetch(hostapi + "/" + url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: getAuthHeader(),
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (sucess) {
                sucess(data);
            }
        })
        .catch((error) => {
            if (fail) {
                fail(error.message);
            }
        });
}

function post(url, data, success, fail) {
    let user = database.get("user");

    return $.ajax({
        type: "POST",
        url: hostapi + "/" + url,
        dataType: "json",
        data: JSON.stringify(data),
        crossDomain: true,
        headers: {
            Authorization: "Bearer " + (user.token || user.access_token) ,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
        .done(function (data) {
            if (success) success(data);
        })
        .fail(function (ex) {
            if (fail) fail(ex);
        });
}

function register(url, data, success, fail) {

    //alert(hostapi + "/" + url);
    return $.ajax({
        type: "POST",
        url: hostapi + "/" + url,
        dataType: "json",
        data: JSON.stringify(data),
        crossDomain: true,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
        .done(function (data) {
            if (success) success(data);
        })
        .fail(function (ex) {
            if (fail) fail(ex);
        });
}

function logIn(username, password, sucess, fail) {

    let data = {
        grant_type: "password",
        username: username,
        password: password,
        uuid: database.get("DeviceUUID"),
        deviceOS: util.getPlatform(),
        notificationRegID: "nemaregid",
        languageID: database.get("langID") || 2
    };

    $.ajax({
        url: host + "/token",
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: data,
    })
        .done(function (data) {
            if (sucess) {
                sucess(data);
            }
        })
        .fail(function (ex) {
            if (fail) fail(ex);
        });
}

function logOut(success, fail) {
    database.deleteUser(success, fail);
}

function postOut(url, data, sucess, fail) {
    $.ajax({
        type: "POST",
        url: hostapi + "/" + url,
        dataType: "json",
        data: data,
        crossDomain: true,
    })
        .done(function (data) {
            if (sucess) sucess(data);
        })
        .fail(function (ex) {
            if (fail) fail(ex);
        });
}

export default api;
