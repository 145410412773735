import React, { useState, useEffect, useRef } from "react";
import "../css/FootballPitch.css";
import $ from "jquery";
import LabelControl from "./controls/labelControl";
import ButtonControl from "./controls/buttonControl";
import LogoutIcon from "@mui/icons-material/Logout";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MuiButton from "./controls/muiButton";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import keeper1img from "../images/keeper1.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import util from "../util/util";
import api from "../util/api";
import HistoryIcon from "@mui/icons-material/History";
import LoaderControl from "./controls/loaderControl";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import RepeatIcon from "@mui/icons-material/Repeat";
import Shirt from "./shirt";
const FootballPitch = (props) => {
    const [players, setPlayers] = useState();
    const [matches, setMatches] = useState();
    const [selectedPlayer, setSelectedPlayer] = useState();
    const [pickPositionPlayerID, setPickPositionPlayerID] = useState();
    const [positionX, setPositionX] = useState(50);
    const [positionY, setPositionY] = useState(50);
    const [showPlayers, setShowPlayers] = useState(false);
    const [showMatches, setShowMatches] = useState(false);
    const [doubleTap, setDoubleTap] = useState(0);
    const [draggerPositionX, setDraggerPositionX] = useState(50);
    const [draggerPositionY, setDraggerPositionY] = useState(50);
    const [autoChangeTeamsOnMove, setAutoChangeTeamsOnMove] = useState(true);
    const [dragging, setDragging] = useState(false);
    const [mode, setMode] = useState("preview");
    const [selectedSubIndexTeam1, setSelectedSubIndexTeam1] = useState(-1);
    const [selectedSubIndexTeam2, setSelectedSubIndexTeam2] = useState(-1);
    const [pointerClass, setPointerClass] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!players) {
            if (props.players) {
                setPlayers(structuredClone(props.players));
            }
        }

        if (!matches) {
            if (props.matches) {
                let mtcsh = structuredClone(props.matches);
                mtcsh.shift();
                setMatches(structuredClone(mtcsh));
            }
        }

        if (pickPositionPlayerID != props.pickPositionPlayerID) {
            if (props.pickPositionPlayerID) {
                setPickPositionPlayerID(props.pickPositionPlayerID);
            }
        }

        if (props.mode) {
            if (props.mode != mode) {
                setMode(props.mode);
            }
        }
        fp = React.createRef();
        box1 = React.createRef();
        box2 = React.createRef();
    });

    let fp = React.createRef();
    let box1 = React.createRef();
    let box2 = React.createRef();

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    function drag(playerID, ev) {
        ev.dataTransfer.setData("playerID", playerID);
    }

    function updatePosition(e) {
        let foopi = document.getElementById("foopi");
        var offset = foopi.getBoundingClientRect();
        let p1x = foopi.clientWidth / 100;
        let x = (e.changedTouches[0].clientX - offset.left) / p1x;
        let p1y = foopi.clientHeight / 100;
        let y = (e.changedTouches[0].clientY - offset.top) / p1y;

        setDraggerPositionX(e.changedTouches[0].clientX);
        setDraggerPositionY(e.changedTouches[0].clientY);

        setPositionX(x);
        setPositionY(y);
    }

    function onMove(player, x, y) {
        let pls = [...players];
        let p = players.filter((x) => x.PlayerID == player.PlayerID)[0];
        p.PositionX = parseInt(x);
        p.PositionY = parseInt(y);
        p.ForTeam = player.ForTeam;
        setPlayers(pls);
    }

    const keeper1y = 5;
    const keeper2y = 95;

    function move(p) {
        if (onMove && p) {
            if (autoChangeTeamsOnMove) {
                if (p.ForTeam != 0) {
                    if (positionY > 50) {
                        p.ForTeam = 2;
                    } else {
                        p.ForTeam = 1;
                    }
                }
            }

            let h = Math.max(fp.clientHeight || 0, window.innerHeight || 0) - 55;
            let w = Math.max(fp.clientWidth || 0, window.innerWidth || 0) - 55;

            //provjeram da li je spušten unutar delete gumba (konvertiram u pixele) = brišem
            let wx = (w / 100) * positionX;
            let wy = (h / 100) * positionY;
            if (wx > w - 50 && wy < 50) {
                p.ForTeam = 0;

                if (!pickPositionPlayerID) setSelectedPlayer(null);
                setSelectedSubIndexTeam1(-1);
                setSelectedSubIndexTeam2(-1);
            }

            //provjeravam da li je spušten unutar 16-erca = postavljam ga na sredinu kao golmana
            //GOLMAN
            let x = positionX;
            let y = positionY;

            if (positionX > 45 && positionX < 55 && positionY < 10) {
                x = 50;
                y = keeper1y;
            }

            if (positionX > 45 && positionX < 55 && positionY > 90) {
                x = 50;
                y = keeper2y;
            }

            onMove(p, x, y);

            if (positionX > 100) {
                if (!pickPositionPlayerID) setSelectedPlayer(null);
                setDragging(false);
            }
        }
    }

    function onRemoveSelectedPlayer(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        let p = { ...selectedPlayer };

        let pls = [...players];

        let p2 = pls.filter((x) => x.PlayerID == p.PlayerID)[0];
        p2.ForTeam = 0;

        setPlayers(pls);

        if (e) move(p2);
        if (!pickPositionPlayerID) setSelectedPlayer(null);
        setSelectedSubIndexTeam1(-1);
        setSelectedSubIndexTeam2(-1);
    }

    function onChangePlayer(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
            console.log(selectedPlayer);
            setPositionX(selectedPlayer.PositionX);
            setPositionY(selectedPlayer.PositionY);
        }
        setShowPlayers(true);
    }

    function onPitchClick(e, source) {
        if (!pickPositionPlayerID) setShowPlayers(false);

        setShowMatches(false);

        e.preventDefault();
        e.stopPropagation();

        console.log(e);
        console.log(fp);
        if (doubleTap == 0) {
            setDoubleTap(1);
            setTimeout(() => {
                setDoubleTap(0);
            }, 300);
        }

        if (doubleTap == 1) {
            setPointerClass("football-pitch-circle-pointer");
            setTimeout(() => setPointerClass(""), 500);

            setDoubleTap(0);
            if (props.onDoubleClick) props.onDoubleClick();
            if (props.mode == "preview") {
            } else {
                if (source && source == "football-pitch-side1") {
                    setPositionX(110);
                    setPositionY(10);
                    setSelectedSubIndexTeam1(-1);
                    setSelectedSubIndexTeam2(-1);
                }

                if (source && source == "football-pitch-side2") {
                    setPositionX(110);
                    setPositionY(60);
                    setSelectedSubIndexTeam1(-1);
                    setSelectedSubIndexTeam2(-1);
                }

                if (pickPositionPlayerID) {
                    let p = players.filter((x) => x.PlayerID == pickPositionPlayerID)[0];
                    addPlayer(p);
                    // setSelectedPlayer(p);
                } else {
                    setTimeout(() => {
                        setShowPlayers(true);
                    }, 300);
                }
            }
        }
    }

    function addPlayer(p) {
        if (selectedPlayer) {
            let pl = { ...selectedPlayer };
            pl.ForTeam = 0;

            move(pl);
        }

        if (positionY > 50) {
            p.ForTeam = 2;
        } else {
            p.ForTeam = 1;
        }

        move(p);
        if (!pickPositionPlayerID) setShowPlayers(false);

        setShowMatches(false);

        setSelectedPlayer(p);
    }

    function save() {
        if (props.onSave) {
            if (pickPositionPlayerID) {
                props.onSave(selectedPlayer);
            } else {
                props.onSave(players);
            }
        }
    }

    function cancel() {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    function restoreLastMatch() {
        setPlayers(structuredClone(props.lastMatchPlayers));
    }

    function isKeeper(player) {
        if (player.PositionX == 50) {
            if (player.PositionY == keeper1y) {
                return true;
            }
            if (player.PositionY == keeper2y) {
                return true;
            }
        }

        return false;
    }

    function loadMatch(id) {
        setLoading(true);
        setShowMatches(false);
        api.get("GetMatch?id=" + id, (data) => {
            setLoading(false);
            setPlayers(data.Players);
            setTotalPlayersCount(getTotalPlayersCount(data.Players));
        });
    }

    return (
        <div style={{ height: props.height || "400px", width: props.width || "300px" }}>
            {showPlayers ? (
                <div className="football-pitch-player-picker">
                    {players &&
                        players
                            .filter((x) => x.ForTeam == 0)
                            .map((p, index) => {
                                return (
                                    <div style={{ width: "100%" }}>
                                        <MuiButton style={{ color: "white", width: "100%" }} onClick={() => addPlayer(p)}>
                                            {p.IAmCommingStatusID == 0 || p.IAmCommingStatusID == null ? (
                                                <>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div>{p.Nick}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div style={{ color: "green", fontSize: 9 }}></div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            ) : null}

                                            {p.IAmCommingStatusID == 1 ? (
                                                <>
                                                    <table className="player-picker-comming">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div style={{ color: "#191919" }}>{p.Nick}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div style={{ color: "#626262", fontSize: 9, fontWeight: "bold", marginTop: -5 }}>Comming</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            ) : null}

                                            {p.IAmCommingStatusID == 2 ? (
                                                <>
                                                    <table className="player-picker-not-comming">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div style={{ color: "black" }}>{p.Nick}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div style={{ color: "black", fontSize: 9, fontWeight: "bold", marginTop: -5 }}>Not comming</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            ) : null}

                                            {p.IAmCommingStatusID == 3 ? (
                                                <>
                                                    <table className="player-picker-maybe">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div style={{ color: "black" }}>{p.Nick}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div style={{ color: "black", fontSize: 9, fontWeight: "bold", marginTop: -5 }}>Maybe comming</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            ) : null}
                                        </MuiButton>
                                    </div>
                                );
                            })}
                </div>
            ) : null}

            {showMatches ? (
                <div className="football-pitch-player-picker">
                    {matches &&
                        matches.map((m, index) => {
                            return (
                                <div style={{ width: "100%" }}>
                                    <MuiButton
                                        style={{ color: "white", width: "100%" }}
                                        onClick={() => {
                                            setTimeout(() => {
                                                loadMatch(m.MatchID);
                                            }, 300);
                                        }}
                                    >
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div>
                                                            {util.getDateFromDateTimeString(m.DatePlayed)} {util.getTimeFromDateTimeString(m.DatePlayed)}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div style={{ color: "green", fontSize: 9 }}></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </MuiButton>
                                </div>
                            );
                        })}
                </div>
            ) : null}

            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <table style={{ width: "100%", height: "100%" }}>
                    <tbody>
                        <tr style={{ width: "100%", height: "100%" }}>
                            <td>
                                <div
                                    ref={fp}
                                    id="foopi"
                                    className="football-pitch"
                                    onTouchMove={(e) => {
                                        if (mode == "preview") return;

                                        if (selectedPlayer) setDragging(true);

                                        updatePosition(e);
                                    }}
                                    onTouchStart={(e) => {
                                        onPitchClick(e);
                                        if (mode == "preview") return;
                                        updatePosition(e);
                                        if (!pickPositionPlayerID) setSelectedPlayer(null);
                                    }}
                                    onTouchEnd={(e) => {
                                        if (props.onClick) {
                                            props.onDoubleClick();
                                        }
                                    }}
                                >
                                    {selectedPlayer && !pickPositionPlayerID ? (
                                        <>
                                            <div className="football-pitch-leave-buton" onTouchStart={onRemoveSelectedPlayer}>
                                                <ArrowOutwardIcon></ArrowOutwardIcon>
                                            </div>
                                            <div className="football-pitch-sub-buton" onTouchStart={onChangePlayer}>
                                                <RepeatIcon></RepeatIcon>
                                            </div>
                                        </>
                                    ) : null}
                                    <div className="grass" style={{ backgroundColor: props.theme == "light" ? "rgb(255 255 255)" : "rgb(74 225 13)" }}>
                                        {players && players.filter((x) => x.ForTeam != 0).length == 0 && mode == "edit" && !showPlayers && (
                                            <div className="football-pitch-tips" style={{ textAlign: "center", fontSize: 14 }}>
                                                <TouchAppIcon style={{ textAlign: "center", fontSize: 40 }}></TouchAppIcon>

                                                <br></br>
                                                {pickPositionPlayerID ? "Double tap to pick your team and position" : util.translate("doubleTapToAdd")}
                                            </div>
                                        )}

                                        {players && players.filter((x) => x.ForTeam != 0).length == 0 && mode == "preview" && !showPlayers && <div className="football-pitch-tips"> No players added to the match</div>}

                                        {players && players.filter((x) => x.ForTeam == 1).length > 0 && mode == "edit" && <div className="football-pitch-counter1">{players.filter((x) => x.ForTeam == 1 && x.PositionX < 100).length}</div>}

                                        {players && players.filter((x) => x.ForTeam == 2).length > 0 && mode == "edit" && <div className="football-pitch-counter2">{players.filter((x) => x.ForTeam == 2 && x.PositionX < 100).length}</div>}
                                        <div style={{ left: positionX + "%", top: positionY + "%" }} className={pointerClass}></div>

                                        {selectedPlayer && selectedPlayer.PositionX < 100 ? <div style={{ left: selectedPlayer.PositionX + "%", top: selectedPlayer.PositionY + "%" }} className="football-pitch-selected-pointer"></div> : null}
                                    </div>
                                    <div className="outline marking"></div>
                                    <div ref={box1} className="box left marking"></div>
                                    <div className="box-d left marking"></div>
                                    <div className="box left small marking"></div>
                                    <div ref={box2} className="box right marking"></div>
                                    <div className="box-d right marking"></div>
                                    <div className="box right small marking"></div>
                                    <div className="spot left marking"></div>
                                    <div className="spot right marking"></div>
                                    <div className="spot center marking"></div>
                                    <div className="center-line marking"></div>
                                    <div className="center-circle marking"></div>

                                    {/* <div className="corner top left"></div> */}

                                    {/* <div className="corner top left marking"></div>
                                <div className="corner top right marking"></div>
                                <div className="corner bottom left marking"></div>
                                <div className="corner bottom right marking"></div> */}

                                    {/* <div style={{ left: positionX + "%", top: positionY + "%" }} className="football-pitch-circle-pointer"></div> */}

                                    {/* {dragging && draggerPositionX < 100 ? <div style={{ left: draggerPositionX, top: draggerPositionY }} className="football-pitch-drag-pointer"></div> : null} */}

                                    {players &&
                                        players
                                            .filter((x) => x.ForTeam != 0)
                                            .map((p, index) => {
                                                let s = { position: "absolute", zIndex: 50, left: p.PositionX + "%", top: p.PositionY + "%", color: "white", textAlign: "center", opacity: dragging || (pickPositionPlayerID && selectedPlayer && selectedPlayer.PlayerID != p.PlayerID) ? 0.4 : 1 };
                                                return p.PositionX < 101 ? (
                                                    <div
                                                        className="tt"
                                                        style={s}
                                                        key={p.PlayerID}
                                                        onClick={(e) => {
                                                            if (mode == "preview") {
                                                                if (props.onPlayerClick) {
                                                                    props.onPlayerClick(p);
                                                                }
                                                                return;
                                                            }
                                                        }}
                                                        onTouchStart={(e) => {
                                                            if (mode == "preview") {
                                                                return;
                                                            }

                                                            updatePosition(e);
                                                            if (!pickPositionPlayerID) setSelectedPlayer(p);
                                                            setSelectedSubIndexTeam1(-1);
                                                            setSelectedSubIndexTeam2(-1);
                                                            setDragging(false);
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        onTouchEnd={(e) => {
                                                            if (mode == "preview") return;
                                                            if (dragging) {
                                                                move(selectedPlayer);
                                                                setDragging(false);
                                                            }

                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        {/* <div style={{ width: "20px", height: "20px", backgroundColor: "red", border: "1px solid red", borderRadius: "50%", display: "inline-block" }}></div> */}

                                                        {/* {isKeeper(p) ? <img src={keeper1img} style={{ width: 20 }}></img> : p.FormTeam != 0 ? p.ForTeam == 1 ? <img src={props.team1img} style={{ width: 20 }}></img> : <img src={props.team2img} style={{ width: 20 }}></img> : null}
                                                        <div className="player-name">{p.Nick}</div> */}

                                                        {isKeeper(p) ?
                                                            <Shirt label={p.Nick} number={p.Number} color="#85ff45" color2="black"></Shirt>
                                                            :
                                                            <Shirt label={p.Nick} number={p.Number} color={p.ForTeam == 1 ? props.team.Team1Color : props.team.Team2Color} color2={p.ForTeam == 1 ? props.team.Team1Color2 : props.team.Team2Color2}></Shirt>}
                                                    </div>
                                                ) : null;
                                            })}
                                </div>
                            </td>
                            {props.substitutions ? (
                                <td style={{ width: "50px" }}>
                                    <div
                                        id={"football-pitch-side"}
                                        className="football-pitch-side"
                                        style={{ backgroundColor: props.theme == "light" ? "rgb(255 255 255)" : "rgb(74 225 13)" }}
                                        onTouchMove={(e) => {
                                            if (mode == "preview") return;
                                            setDragging(true);
                                            updatePosition(e);
                                        }}
                                        onTouchEnd={(e) => {
                                            if (mode == "preview") return;
                                            move(selectedPlayer);
                                            setDragging(false);
                                        }}
                                    >
                                        {dragging ? <div style={{ left: draggerPositionX, top: draggerPositionY }} className="football-pitch-drag-pointer"></div> : null}

                                        <div className="grass">
                                            <div className="football-pitch-side-separator"></div>
                                            <div className="football-pitch-side-limiter1"></div>
                                            <div className="football-pitch-side-limiter2"></div>
                                        </div>

                                        <div
                                            style={{ height: "50%", left: 0, width: "100%", position: "absolute", display: "inline-block", overflow: "auto", zIndex: 50 }}
                                            onTouchStart={(e) => {
                                                if (!pickPositionPlayerID) setSelectedPlayer(null);
                                                onPitchClick(e, "football-pitch-side1");
                                            }}
                                        >
                                            {selectedPlayer && selectedSubIndexTeam1 > -1 ? <div style={{ left: 24, top: selectedSubIndexTeam1 * 50 + 30 }} className="football-pitch-selected-pointer"></div> : null}

                                            {players &&
                                                players
                                                    .filter((x) => x.PositionX > 101 && x.ForTeam == 1)
                                                    .map((p, index) => {
                                                        return p.PositionX > 101 ? (
                                                            <div
                                                                className="tt"
                                                                style={{ left: "24px", top: index * 50 + 30, position: "absolute" }}
                                                                key={p.PlayerID}
                                                                onTouchStart={(e) => {
                                                                    if (mode == "preview") return;
                                                                    setSelectedSubIndexTeam1(index);
                                                                    setSelectedSubIndexTeam2(-1);
                                                                    updatePosition(e);
                                                                    if (!pickPositionPlayerID) setSelectedPlayer(p);
                                                                    setDragging(false);
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                                onTouchEnd={(e) => {
                                                                    if (mode == "preview") return;
                                                                    if (dragging) {
                                                                        move(selectedPlayer);
                                                                        setSelectedSubIndexTeam1(-1);
                                                                        setSelectedSubIndexTeam2(-1);
                                                                        setDragging(false);
                                                                    }

                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                {/* {p.ForTeam == 1 ? <img src={props.team1img} style={{ width: 20 }}></img> : null}
                                                                {p.ForTeam == 2 ? <img src={props.team2img} style={{ width: 20 }}></img> : null}
                                                                <div className="player-name">{p.Nick}</div> */}
                                                                 
                                                                <Shirt label={p.Nick} number={p.Number} color={p.ForTeam == 1 ? props.team.Team1Color : props.team.Team2Color} color2={p.ForTeam == 1 ? props.team.Team1Color2 : props.team.Team2Color2}></Shirt>
                                                            </div>
                                                        ) : null;
                                                    })}
                                        </div>
                                        <div
                                            style={{ height: "50%", left: 0, top: "50%", width: "100%", position: "absolute", display: "inline-block", overflow: "auto", zIndex: 50 }}
                                            onTouchStart={(e) => {
                                                if (!pickPositionPlayerID) setSelectedPlayer(null);

                                                onPitchClick(e, "football-pitch-side2");
                                            }}
                                        >
                                            {selectedPlayer && selectedSubIndexTeam2 > -1 ? <div style={{ left: 24, top: selectedSubIndexTeam2 * 50 + 30 }} className="football-pitch-selected-pointer"></div> : null}

                                            {players &&
                                                players
                                                    .filter((x) => x.PositionX > 101 && x.ForTeam == 2)
                                                    .map((p, index) => {
                                                        return p.PositionX > 101 ? (
                                                            <div
                                                                className="tt"
                                                                style={{ left: "24px", top: index * 50 + 30, position: "absolute" }}
                                                                key={p.PlayerID}
                                                                onTouchStart={(e) => {
                                                                    if (mode == "preview") return;
                                                                    setSelectedSubIndexTeam2(index);
                                                                    setSelectedSubIndexTeam1(-1);
                                                                    updatePosition(e);
                                                                    if (!pickPositionPlayerID) setSelectedPlayer(p);
                                                                    setDragging(false);
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                                onTouchEnd={(e) => {
                                                                    if (mode == "preview") return;
                                                                    if (dragging) {
                                                                        move(selectedPlayer);
                                                                        setSelectedSubIndexTeam2(-1);
                                                                        setDragging(false);
                                                                    }

                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                {/* {p.ForTeam == 1 ? <img src={props.team1img} style={{ width: 20 }}></img> : null}
                                                                {p.ForTeam == 2 ? <img src={props.team2img} style={{ width: 20 }}></img> : null}
                                                                <div className="player-name">{p.Nick}</div> */}
                                                                <Shirt label={p.Nick} number={p.Number} color={p.ForTeam == 1 ? props.team.Team1Color : props.team.Team2Color} color2={p.ForTeam == 1 ? props.team.Team1Color2 : props.team.Team2Color2}></Shirt>
                                                            </div>
                                                        ) : null;
                                                    })}
                                        </div>
                                    </div>
                                </td>
                            ) : null}
                        </tr>
                        {props.buttons ? (
                            <tr>
                                <td colSpan={2}>
                                    <BottomNavigation showLabels>
                                        <BottomNavigationAction label="Cancel" icon={<CancelIcon />} onClick={cancel} />

                                        {props.lastMatchPlayers ? <BottomNavigationAction label="Add from last match" icon={<RestoreIcon />} onClick={restoreLastMatch} /> : null}

                                        {matches && matches.length > 0 ? (
                                            <BottomNavigationAction
                                                label="Load from previous"
                                                icon={<HistoryIcon />}
                                                onClick={() => {
                                                    setShowMatches(true);
                                                }}
                                            />
                                        ) : null}

                                        <BottomNavigationAction label="Save" icon={<SaveIcon />} onClick={save} />
                                    </BottomNavigation>
                                    {/* <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "50%" }}>
                                                <ButtonControl onClick={cancel}>
                                                    Cancel
                                                </ButtonControl>
                                            </td>
                                            <td>
                                                <ButtonControl onClick={save}>
                                                    Save
                                                </ButtonControl>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default FootballPitch;
