import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faParking } from "@fortawesome/free-solid-svg-icons";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import GlobalContext from "../components/globalContext";
import LoaderControl from '../components/controls/loaderControl';


const useConstructor = util.useConstructor();

function Shirt(props) {
    const [name, setName] = useState("");
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);

    useConstructor(() => {
         
    });

    function success(data) {}

    function fail(data) {}

    function save() {}

    return (
        <div className="tshirt" style={props.style}>
            <div style={{backgroundColor: props.color ?? "blue", color: props.color2 ?? "white", fontSize:12}} className="tshirtbody">{props.number ?? ""}</div>
            <div className="collar"></div>
            <div style={{backgroundColor: props.color ?? "blue"}} className="sleeve left-sleeve"></div>
            <div style={{backgroundColor: props.color ?? "blue"}} className="sleeve right-sleeve"></div>
            <div className="tshirt-player-name">{props.label}</div>  
             
        </div>
    );
}

export default Shirt;
