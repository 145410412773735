import React, { useState, useRef, useContext } from "react";
import { faParking, faMoneyCheck, faUsers, faFutbol, faGlasses } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";
import database from "../../util/database";
import { I18nextProvider, useTranslation } from "react-i18next";
import $ from "jquery";
import CircularProgress from "@mui/material/CircularProgress";
import { alertTitleClasses } from "@mui/material";

import { Oval } from "react-loader-spinner";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
 
// const useConstructor = util.useConstructor();

let TextBoxControl = (props) => {
    // useConstructor(() => {

    // });
    return (
        <TextField disabled={props.disabled} size={props.size || "normal"} variant="filled" value={props.value} helperText={props.helperText} required={props.required} onChange={(e) => props.onChange(e)} label={props.label} placeholder={props.placeholder} style={{ marginTop: 10, width: "100%" }} />
                    
    );
};

export default TextBoxControl;