import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import util from "../util/util";
import database from "../util/database";
import api from "../util/api";
import useLongPress from "../util/useLongPress";
import { Oval } from "react-loader-spinner";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import GlobalContext from "./globalContext";
import { AiFillDelete } from "react-icons/ai";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import MapIcon from "@mui/icons-material/Map";
import CardHeader from "@mui/material/CardHeader";

import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const useConstructor = util.useConstructor();


function Stats(props) {
  
    return (
        <div className="component-container fadein" style={{ marginTop: 100 }}>
            <div className="centered-container title">
                <p>Stats</p>
            </div>
        </div>
    );
}

export default Stats;
