var languages = {
    EN: {
        matchDay: "Match day",
        standings: "Standings",
        matches: "Matches",
        selectPlayers: "Select players",
        changeResult: "Change result",
        newMatch: "New match",
        endMatch: "Close match",
        share: "Share",
        lineups: "Lineups",
        freeBeerFor: "free beer for",
        home: "Home",
        managePlayers: "Manage players",
        lightMode: "Light mode",
        darkMode: "Dark mode",
        about: "About",
        login: "Login",
        logout: "Logout",
        playerName: "Player name",
        username: "Username",
        password: "Password",
        editPlayer: "Edit player",
        isAdmin: "Is admin",
        save: "Save",
        cancel: "Cancel",
        delete: "Delete",
        groupInfo: "Group info",
        groupName: "Group name",
        description: "Description",
        deleteGroup: "Delete group",
        time: "Time",
        back: "Back",
        next: "Next",
        skip: "Skip",
        selectTeam1Score: "Select team 1 score",
        selectTeam2Score: "Select team 2 score",
        selectScorrers: "Select scorrers",
        doubleTapToAdd: "Double tap to add player to desired team side and position",
        selectGroupOrCreate: "Select group or create new group",
        forgotPassword: "Forgot password?",
        eMail: "Email",
        send: "Send",
        token: "Token",
        newPassword: "New password",
        repeatPassword: "Repeat password",
        changePassword: "Change password",
        alreadyHaveToken: "Already have token?",
        sendAPasswordChangeRequest: "Send a password change request",
        signInWithGoogle: "Sign in with google",
        register: "Register",
        registration: "Registration",
        playerProfile: "Player stats",
        played: "Played",
        win: "Win",
        draw: "Draw",
        lost: "Lost",
        goals: "Goals",
        points: "Points",
        perMatch: "per match",
        scorrers: "Scorrers",
        theresNoNewMatches: "There's no new matches",
        createNewMatch: "Create new match",
        welcomeToGroup: "Welcome to group",
        youDontHaveAnyMatches: "You don't have any matches created yet. To create new match you need to add players first. You can add players by clicking",
        inMainMenuOrYou: "in main menu, or you can add players later, and create new match now",
        season: "Season",
        date: "Date",
        blueWins: "Blue wins",
        redWins: "Red wins",
        winner: "Won",
        loser: "Lost",
        youAreNotMember: "You are not member of any group but you can join the group if someone add you to the group, or you can create new group",
        newGroup: "New group",
        edit:"Edit",
        areYouCommingToMatch:"Are you comming to match?",
        leaveGroup:"Leave group",
        addPlayers:"Add players",
        topScorrers: "Top scorrers",
        teamStats: "Team stats",
        playerStats: "Player stats",
        player: "Player",
        team: "Team",
        userNameIsOptional: "Username is optional. Add username if you want to link player with existing user.",
        noMatchesPlayedYet: "No matches played yet.",
        deletePlayerAreYouSure: "Deleting a player can affect the standings. The player will be deleted from all matches. Are you sure you want to delete the player?",
        deleteGroupAreYouSure: "Are you sure you want to delete the group?",
        resetStandings:"Reset standings",
        resetStandingsAreYouSure:"By resetting the standings, all match data and results will be deleted. Are you sure you want to reset?",
        yes:"YES",
        no:"NO",
        fulltime:"Full time",
        winsInARow: "Wins in a row",
        notLostInARow: "Not lost in a row",
        team1Name:"Team 1 name",
        team2Name:"Team 2 name",
        notPlayed:"Not played",
        number: "Player number",
        editGroup :"Edit group"

    },
    HR: {
        matchDay: "Termin",
        standings: "Statistika",
        matches: "Utakmice",
        selectPlayers: "Postave",
        changeResult: "Promjeni rezultat",
        newMatch: "Novi termin",
        endMatch: "Kraj utakmice",
        share: "Dijeli",
        lineups: "Postave",
        freeBeerFor: "besplatna piva za",
        home: "Termini",
        managePlayers: "Igrači",
        lightMode: "Svijetla pozadina",
        darkMode: "Tamna pozadina",
        about: "O aplikaciji",
        login: "Prijava",
        logout: "Odjava",
        playerName: "Ime",
        username: "Korisničko ime",
        password: "Lozinka",
        editPlayer: "Uredi igrača",
        isAdmin: "Admin",
        save: "Spremi",
        cancel: "Odustani",
        delete: "Briši",
        groupInfo: "Info",
        groupName: "Naziv grupe/termina",
        description: "Opis",
        deleteGroup: "Briši grupu",
        time: "Vrijeme",
        back: "Natrag",
        next: "Dalje",
        skip: "Dalje",
        selectTeam1Score: "Rezultat ekipa 1",
        selectTeam2Score: "Rezultat ekipa 2",
        selectScorrers: "Strijelci",
        doubleTapToAdd: "Dvostruki dodir za dodavanje igrača na željenu poziciju",
        selectGroupOrCreate: "Odaberi termin",
        forgotPassword: "Zaboravljena lozinka?",
        eMail: "Email",
        send: "Pošalji",
        token: "Token",
        newPassword: "Nova lozinka",
        repeatPassword: "Ponovi lozinku",
        changePassword: "Promjeni lozinku",
        alreadyHaveToken: "Već imate token?",
        sendAPasswordChangeRequest: "Pošalji zahtjev za promjenom lozinke",
        signInWithGoogle: "Prijava putem Google",
        register: "Registracija",
        registration: "Registracija",
        playerProfile: "Statistika igrača",
        played: "Utakmice",
        win: "Pobjeda",
        draw: "Neriješeno",
        lost: "Izgubljeno",
        goals: "Golovi",
        points: "Bodovi",
        perMatch: "po utakmici",
        scorrers: "Strijelci",
        theresNoNewMatches: "Nema novih termina",
        createNewMatch: "Novi termin",
        welcomeToGroup: "Dobrodošli u grupu",
        youDontHaveAnyMatches: "Za kreiranje novog termina treba dodati igrače. Igrače možete dodati klikom na",
        inMainMenuOrYou: "u izborniku, ili ih možete dodati kasnije i kreirati novi termin sada",
        season: "Sezona",
        date: "Datum",
        blueWins: "Pobjeda plavi",
        redWins: "Pobjeda crveni",
        winner: "Pobjedio",
        loser: "Izgubio",
        youAreNotMember: "Trenutno nemate aktivnu grupu/termin. Možete kreirati vlastitu grupu ili se pridružiti postojećoj grupi.",
        newGroup: "Nova grupa/termin",
        edit:"Uredi",
        areYouCommingToMatch:"Da li dolaziš na utakmicu?",
        leaveGroup:"Napusti grupu",
        addPlayers:"Dodavanje igrača",
        topScorrers: "Najbolji strijelci",
        teamStats: "Statistika timova",
        playerStats: "Statistika igrača",
        player: "Igrač",
        team: "Tim",
        userNameIsOptional: "Upišite korisničko ime postojećeg korisnika ako ga želite povezati sa igračem.",
        noMatchesPlayedYet: "Nema odigranih utakmica.",
        deletePlayerAreYouSure: "Brisanje igrača može utjecati na stanje u poretku. Igrač će biti izbrisan iz svih utakmica. Jeste li sigurni da želite obrisati igrača?",
        deleteGroupAreYouSure: "Jeste li sigurni da želite izbrisati grupu?",
        resetStandings:"Resetiraj tablicu",
        resetStandingsAreYouSure:"Resetiranjem tablice biti će izbrisani svi podaci i rezultati o utakmicama. Jeste li sigurni da želite resetirati?",
        yes:"DA",
        no:"NE",
        fulltime:"Kraj utakmice",
        winsInARow: "Pobjeda u nizu",
        notLostInARow: "Ne poražen u nizu",
        team1Name:"Ekipa 1 naziv",
        team2Name:"Ekipa 2 naziv",
        notPlayed:"Nije igrao",
        number:"Broj",
        editGroup :"Uredi grupu"
 
    },
};

export default languages;
