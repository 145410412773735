import React, { useState, useRef, useContext } from "react";
import { faParking, faMoneyCheck, faUsers, faFutbol, faGlasses } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";
import database from "../../util/database";
import { I18nextProvider, useTranslation } from "react-i18next";
import $ from "jquery";
import CircularProgress from "@mui/material/CircularProgress";
import { alertTitleClasses } from "@mui/material";
import Ball from '../../images/loading.gif';
import Curve from '../../images/curve.gif';

import { Oval } from "react-loader-spinner";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

let LoaderControl = (props) => {
    return (

        false ? 
        
<div style={{ top: "50%", position: "fixed", marginLeft: "-75px", left: "50%", marginTop: "-75px" }}>
                    <Oval height="150" width="150" color="#2dc2fd" ariaLabel="loading" secondaryColor="lightgray" strokeWidth={0.5} />{" "}
                </div> 
            :

            <div style={{ marginTop: "50vh", marginLeft: "50vw" }}>
                    <CircularProgress size={60} variant="indeterminate" style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", marginLeft: "-30px", marginTop: "-30px" }}  />
                </div> 
    );
};

export default LoaderControl;

{/*             
<div style={{ top: "50%", position: "fixed", marginLeft: "-150px", left: "50%", marginTop: "-150px" }}>
        <img style={{ width: "300px" }} src={Ball}></img>
</div> 
*/}

{/*             
<div style={{ marginTop: "50vh", marginLeft: "50vw" }}>
                    <CircularProgress style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", marginLeft: "-20px", marginTop: "-20px" }} />
                </div> 
*/}

{/*             
<div style={{ top: "50%", position: "fixed", marginLeft: "-75px", left: "50%", marginTop: "-75px" }}>
                    <Oval height="150" width="150" color="#2dc2fd" ariaLabel="loading" secondaryColor="lightgray" strokeWidth={0.5} />{" "}
                </div> 
*/}