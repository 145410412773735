import React, { useState, useRef, useContext } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { ButtonControl, PageTitleControl } from "../components/controls/controls";
import database from "../util/database";
import Paper from '@mui/material/Paper';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
function Notifications(props) {

    return (
        <div className="like-dialog">
            {/* <CountdownCircleTimer isPlaying duration={100} strokeWidth="4" colors={["#326da8", "#326da8", "#3eb9de", "#3e59de"]} 
                strokeLinecap="butt"
                colorsTime={[5, 5, 2, 0]}>
                    {({ remainingTime }) => <div>{remainingTime}</div>}
                </CountdownCircleTimer> */}

            {props.notifications.map((item, index) => {
                return <Paper className="notification">

                    <table style={{height:"100%"}}>
                        <tr>
                            <td><SportsSoccerIcon></SportsSoccerIcon> </td>
                            <td style={{padding:"10px"}}>{item}</td>
                        </tr>
                    </table>
                </Paper>
            })}
        </div>
    );
}

export default Notifications;
