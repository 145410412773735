import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faParking } from "@fortawesome/free-solid-svg-icons";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import GlobalContext from "../components/globalContext";
import LoaderControl from '../components/controls/loaderControl';
import LabelControl from "./controls/labelControl";
import Logo from "../images/logo.png";
 
const useConstructor = util.useConstructor();

function About(props) {
    const [name, setName] = useState("");
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [loginHistory, setLoginHistory] = useState();
    useConstructor(() => {

    });

    function success(data) { }

    function fail(data) { }

    function save() { }

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <div>
                    <img style={{ width: "50%", marginTop: "20%" }} src={Logo}></img>
                    <LabelControl style={{ marginTop: "50px", fontSize: "30px", fontWeight: "bold" }}>
                        FUTSA
                    </LabelControl>
                    <LabelControl style={{ marginTop: "-10px" }}>
                        Version {database.get("appversion")}
                    </LabelControl>
                    <LabelControl style={{ marginTop: "50px" }}>
                        vsprojects 2024
                    </LabelControl>
                    <LabelControl style={{ marginTop: "5px", fontSize:10}}>
                        all rigthts reserved
                    </LabelControl>
                    <br></br>
                    <LabelControl style={{ marginTop: "5px", fontSize:10, opacity: 0.6}}>
                        {database.get("firebaseToken").substring(0, 15)}
                    </LabelControl>
                </div>
            )}
        </div>
    );
}

export default About;
