import api from "../util/api";
import languages from "../util/languages";

export const database = {
    init,
    get,
    add,
    remove,
    set,
    where,
    first
};

function init(){}

function get(field) {
    let items = window.localStorage.getItem(field);

    if (items) {
        try{
            items = JSON.parse(items);
        }catch{
            
        }
    } else {
        items = null;
        error("Table " + field + " does not exist!");
    }

    if (Array.isArray(items)) {
        items.forEach((item, index) => {
            items[index]._id = index;
        });
    }
    return items;
}

function set(field, items) {
    window.localStorage.setItem(field, JSON.stringify(items));
}

function add(field, value) {
    let items = window.localStorage.getItem(field);
    if (items) items = JSON.parse(items);
    if (!items) items = [];
    items.push(value);
    window.localStorage.setItem(field, JSON.stringify(items));
}

function error(message){
    //console.error(message);
}

function where(field, property, value) {
    let items = get(field);

    if(items && items.length > 0){    

        if(!items[0][property]){
            error("Property " + property + " does not exist in table " + field);
            return null;
        }

        let result = items.filter((x) => x[property] == value);
        return result;
    }

    return null;
}

function first(field, property, value) {
    let result = where(field, property, value);
    if(result && result.length > 0) {
        return where(field, property, value)[0];
    }
    return null;
}

function remove(field, value) {
    let items = window.localStorage.getItem(field);
    if (items) items = JSON.parse(items);
    let isMatch = true;
    let matchIndex = -1;

    for (var x = 0; x < items.length; x++) {
        let item = items[x];
        let prps = Object.getOwnPropertyNames(item);

        for (var i = 0; i < prps.length; i++) {
            let pItem = prps[i];
            isMatch = true;
            if (item[pItem] !== value[pItem]) {
                isMatch = false;
            }

            if (isMatch) {
                matchIndex = x;
                items.splice(matchIndex, 1);
                window.localStorage.setItem(field, JSON.stringify(items));
                break;
            }
        }

        if (isMatch) {
            break;
        }
    }
}

export default database;
