import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../util/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import database from "../util/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faParking } from "@fortawesome/free-solid-svg-icons";
import util from "../util/util";
import { Oval } from "react-loader-spinner";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ButtonControl, PageTitleControl, LabelControl, DividerControl, TextBoxControl } from "./controls/controls";
import LoaderControl from '../components/controls/loaderControl';
import Switch from '@mui/material/Switch';

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function PlayerEditor(props) {
    const [nick, setNick] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [player, setPlayer] = useState();
    const [number, setNumber] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {


        if (!player || (player && player.PlayerID != props.player.PlayerID)) {
            setPlayer(props.player);
            setNick(props.player.Nick || "");
            setEmail(props.player.Email || "");
            setMobile(props.player.Mobile || "");
            setNumber(props.player.Number || "");
            setIsAdmin(props.player.IsAdmin ? true : false);
        }

    });

    function save() {
        let data = {
            Nick: nick,
            Email: email,
            Mobile: mobile,
            PlayerID: player.PlayerID,
            TeamID: player.TeamID,
            IsAdmin: isAdmin ? 1 : 0,
            Number: number
        }

        props.onSave(data);
        clear();
    }

    function cancel() {
        props.onCancel();
        clear();
    }

    function clear() {
        setNick("");
        setEmail("");
        setMobile("");
    }

    function deletePlayer(){
        if(confirm(util.translate("deletePlayerAreYouSure"))){
            props.onDelete(player);
        }
    }

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <div className="component-container centered-container">
                    {/* <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                        <Toolbar>
                            <Typography style={{ textAlign: "left" }} component="div" sx={{ flexGrow: 1 }}>
                                <p style={{ fontSize: "12px" }}>{"New player"}</p>
                            </Typography>
                        </Toolbar>
                    </AppBar> */}
                    <br></br>
                    <div style={{ padding: "20px" }}>
                        <TextBoxControl required value={nick} label={util.translate("playerName")} onChange={(e) => setNick(e.target.value)} variant="outlined" style={{ width: "100%" }} />
                        <TextBoxControl type="number" value={number} label={util.translate("number") + " (optional)"} onChange={(e) => setNumber(e.target.value)} variant="outlined" style={{ width: "100%" }} />
                        <TextBoxControl disabled={player && !!player.IsCreator} value={email} label={util.translate("username") + " (optional)"} onChange={(e) => setEmail(e.target.value)} variant="outlined" style={{ width: "100%" }} />
                        
                        {/* <TextBoxControl value={mobile} label={"Mobile"} onChange={(e) => setMobile(e.target.value)} variant="outlined" style={{ width: "100%" }} /> */}
                        <br></br><br></br>
                        <LabelControl>{util.translate("isAdmin")}</LabelControl> <Switch disabled={player && !!player.IsCreator} checked={false || isAdmin} onChange={(e) => setIsAdmin(e.target.checked)}></Switch>
                        <br></br><br></br><br></br>
                        <table style={{ width: "100%", tableLayout: "fixed" }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: "2%" }}>
                                        <Button variant="contained" disabled={!nick} onClick={save} style={{ width: "100%" }}>{util.translate("save")}</Button>
                                    </td>
                                    {/* <td style={{ padding: "2%" }}>
                                        <Button variant="contained" onClick={() => cancel()} style={{ width: "100%" }}>{util.translate("cancel")}</Button>
                                    </td> */}
                                    <td style={{ padding: "2%" }}>
                                        <Button disabled={player && !!player.IsCreator} variant="contained" color="error" onClick={deletePlayer} style={{ width: "100%" }}>{util.translate("delete")}</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
            )}
        </div>
    );
}

export default PlayerEditor;
