import React, { useState, useContext } from "react";
import util from "../util/util";
import { faCog, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import database from "../util/database";
import GlobalContext from "../components/globalContext";

function SideMenu(props) {
    const global = useContext(GlobalContext);

    const onMenuClick = (item) => {
        global.setSelectedTab(null);
        if (props.onMenuClick) {
            props.onMenuClick(item);
        }
    };

    return (
        <div
            className="side-menu animated"
            onClick={(e) => {
                onMenuClick("");
                e.stopPropagation();
            }}
        >
            <div className="">
                <div
                    className="side-menu-item"
                    onClick={(e) => {
                        onMenuClick("userProfile");
                        e.stopPropagation();
                    }}
                >
                    {/* <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>  */}
                    {util.translate("korisnicki-profil").toUpperCase()}
                </div>
                <div
                    className="side-menu-item"
                    onClick={(e) => {
                        onMenuClick("settings");
                        e.stopPropagation();
                    }}
                >
                    {/* <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>  */}
                    {util.translate("info-o-aplikaciji").toUpperCase()}
                </div>
                <div
                    className="side-menu-item"
                    onClick={(e) => {
                        onMenuClick("language");
                        e.stopPropagation();
                    }}
                >
                    {/* <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>  */}
                    {util.translate("language").toUpperCase()}
                </div>
                <div
                    className="side-menu-item"
                    onClick={(e) => {
                        onMenuClick("login");
                        e.stopPropagation();
                    }}
                >
                    {util.translate("signout")}
                    <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
                </div>
                <div
                    className="side-menu-item"
                    onClick={(e) => {
                        database.set("theme", "light");
                        NavigationBar.backgroundColorByName("white", true);
                        location.reload();
                        e.stopPropagation();
                    }}
                >
                    Light theme
                </div>
                <div
                    className="side-menu-item"
                    onClick={(e) => {
                        database.set("theme", "dark");
                        NavigationBar.backgroundColorByName("black", false);
                        location.reload();
                        e.stopPropagation();
                    }}
                >
                    Dark theme
                </div>
                <div
                    className="side-menu-item"
                    onClick={(e) => {
                        window.location = "#/login";
                    }}
                >
                    Log in
                </div>
            </div>
        </div>
    );
}

export default SideMenu;
