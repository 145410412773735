import React, { useState, useRef, useContext, useEffect } from "react";
import database from "../util/database";
import { faParking, faMoneyCheck, faUsers, faFutbol } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../util/api";
import util from "../util/util";
import { ButtonControl, PageTitleControl, LabelControl, DividerControl, MuiButton } from "../components/controls/controls";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LoaderControl from '../components/controls/loaderControl';
import MatchDetails from "../components/matchDetails";
import { CheckOutlined } from "@ant-design/icons";
import Team1img from '../images/team1.png';
import Team2img from '../images/team2.png';
import Beer from '../images/beer.png';
import Team0img from '../images/team.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Trophy from '../images/trophy16.png';
import GoldenBoot from '../images/goldenboot16.png';
import Ball from '../images/ball.png';
import Goal from '../images/goal.png';
import Mvp from '../images/mvp.png';
import { data } from "jquery";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Shirt from "./shirt";
const useConstructor = util.useConstructor();

function EndMatch(props) {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    const [players, setPlayers] = React.useState([]);
    const [match, setMatch] = React.useState();
    const [date, setDate] = React.useState();
    const [dateString, setDateString] = React.useState();
    const [team1Score, setTeam1Score] = React.useState(0);
    const [team2Score, setTeam2Score] = React.useState(0);
    const [team1OwnGoals, setTeam1OwnGoals] = React.useState(0);
    const [team2OwnGoals, setTeam2OwnGoals] = React.useState(0);
    const [team1Total, setTeam1Total] = React.useState(0);
    const [team2Total, setTeam2Total] = React.useState(0);

    const [step, setStep] = React.useState(1);
    const [stepDescription, setStepDescription] = React.useState("Select " + props.team.Team1Name + " score");
    const [mvp, setMvp] = React.useState();
    const [numbers, setNumbers] = React.useState([]);
    useConstructor(() => {
        setNumbers([...Array(101).keys()]);

        let data = { ...props.data };
        data.Players.forEach(x => x.Goals = 0);
        setMatch(data);
        const img1 = new Image();
        img1.src = Beer;
    });

    useEffect(() => {
        if (!match) {
            let data = { ...props.data };
            data.Players.forEach(x => x.Goals = 0);

            console.log(data.Players);
            setMatch(data);
        }
    });

    function onNumberClick(data, goStep) {

        if (step == 1 && goStep == -1) {
            if (props.onCancel)
                props.onCancel();
            return;
        }

        let nextStep = step + goStep;

        let playerCount = props.data.Players.filter(x => x.ForTeam != 0).length;

        if (step == 2 && nextStep == 3) {
            if (playerCount == 0)
                nextStep = 5;
        }

        if (step == 5 && nextStep == 4) {
            if (playerCount == 0)
                nextStep = 2;
        }

        setStep(nextStep);


        if (nextStep == 2) {
            setTeam1Score(data);
            let m = { ...match };
            m.Team1Score = data;

            setMatch(m);
        }

        if (nextStep == 3) {
            let m = { ...match };
            m.Team2Score = data;
            setMatch(m);
        }




        if (nextStep == 5) {

            let m = { ...match };
            m.Mvp = data;
            m.MvpID = data.PlayerID;
            setMatch(m);

            setMvp(data);
        }

        console.log(props.team);

        if (nextStep == 1)
            setStepDescription("Select " + props.team.Team1Name + " score");

        if (nextStep == 2)
            setStepDescription("Select " + props.team.Team2Name + " score");

        if (nextStep == 3)
            setStepDescription(util.translate("selectScorrers"));

        if (nextStep == 4)
            setStepDescription(util.translate("freeBeerFor"));

        if (nextStep == 5)
            setStepDescription(util.translate("saveMatch"));

    }

    const playerPlusGoal = (index, pl) => {
        let pls = [...match.Players];
        let player = pl || pls[index];
        player.Goals += 1;

        setPlayers(pls);
    }

    const playerMinusGoal = (index, pl) => {

        let pls = [...match.Players];
        let player = pl || pls[index];

        if (player.Goals > 0) {
            player.Goals -= 1;

            setPlayers(pls);
        }
    }

    function onSave() {
        if (props.onSave) {
            props.onSave(match)
        }
    }

    function goalsCount() {
        let gls = 0;
        match.Players.forEach(x => gls += x.Goals);
        return gls;
    }

    let clName = database.get("theme") == "light" ? "number-button" : "number-button-dark";

    return (
        <div className="component-container centered-container">
            {loading ? (
                <LoaderControl></LoaderControl>
            ) : (
                <>
                    <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="static" className={database.get("theme") == "light" ? "appBar-light" : "appBar-dark"}>
                            <Toolbar>
                                <IconButton onClick={(e) => props.onCancel()} size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                    {/* <ArrowBackIosIcon /> */}
                                    {util.translate("cancel")}
                                </IconButton>
                                <Typography component="div" sx={{ flexGrow: 1 }}>
                                    {/* Close match */}
                                </Typography>

                                {/* <IconButton disabled={step != 5} onClick={() => onSave()} size="large" edge="end" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                    
                                    Save
                                </IconButton> */}
                            </Toolbar>
                        </AppBar>
                    </Box>
                    <div style={{ marginTop: "20px", height: "25px" }}>

                        {/* {step == 1 ?
                            <img style={{ width: "24px" }} src={Team1img}></img> : null}

                        {step == 2 ?
                            <img style={{ width: "24px" }} src={Team2img}></img> : null}

                        {step == 3 ?
                            <img style={{ width: "24px" }} src={Goal}></img> : null}

                        {step == 4 ?
                            <img style={{ width: "24px" }} src={Mvp}></img> : null}

                        {step == 5 ?
                            <img style={{ width: "24px" }} src={Ball}></img> : null} */}

                        <LabelControl style={{ fontSize: "20px", fontWeight: "bold" }}>

                            {step == 4 && <>  <img style={{ width: "40px" }} src={Beer}></img> <br></br>  </>}
                            <div>{stepDescription}</div>  &nbsp;

                            {/* {step == 1 && <img style={{ width: "40px" }} src={Team1img}></img>}
                            {step == 2 && <img style={{ width: "40px" }} src={Team2img}></img>} */}
            
                            {/* {step == 1 && <Shirt color={props.team.Team1Color} color2={props.team.Team1Color2} number={10}></Shirt>}
                            {step == 2 && <Shirt color={props.team.Team2Color} color2={props.team.Team2Color2} number={10}></Shirt>} */}
                        </LabelControl>
                    </div>
                    <div>
                        {step == 1 &&

                            <div className="fadeInUp fixed-container-endMatch-step1" style={{ textAlign: "center", padding: "20px" }}>
                                {numbers.map((number) => {
                                    return <div key={"nums01" + number} style={{ backgroundColor: props.team.Team1Color, color: props.team.Team1Color2 }} onClick={() => onNumberClick(number, 1)} className={clName}>{number} </div>
                                })}
                            </div>
                        }

                        {step == 2 &&
                            <div className="fadeInUp fixed-container-endMatch-step1" style={{ textAlign: "center", padding: "20px" }}>
                                {numbers.map((number) => {
                                    return <div key={"nums02" + number} style={{ backgroundColor: props.team.Team2Color, color: props.team.Team2Color2 }} onClick={() => onNumberClick(number, 1)} className={clName}>{number} </div>
                                })}
                            </div>
                        }

                        {step == 3 &&
                            <div className="fadeInUp fixed-container-endMatch-step1" style={{ textAlign: "center", padding: "20px" }}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        {match && match.Players.map((player, index) => {
                                            return player.ForTeam == 1 ? <tr key={"pls01" + index} >
                                                <td style={{ width: "50px" }}>
                                                    <div onClick={() => playerMinusGoal(index)} style={{ fontSize: "14px", height: "65px", display: "flex", alignItems: "center", backgroundColor: props.team.Team1Color, color: props.team.Team1Color2 }} className={"number-button-blue"}> <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>  </div>
                                                </td>
                                                <td>
                                                    <div onClick={() => playerPlusGoal(index)} style={{ fontSize: "14px", height: "65px", backgroundColor: props.team.Team1Color, color: props.team.Team1Color2 }} className={"number-button-blue"}>   {player.Nick} <br /> {player.Goals != "0" && <b style={{ fontSize: "25px", marginTop: -10, display: "block" }}>{player.Goals}</b>} </div>
                                                </td>
                                                <td style={{ width: "50px" }}>
                                                    <div onClick={() => playerPlusGoal(index)} style={{ fontSize: "14px", height: "65px", display: "flex", alignItems: "center", backgroundColor: props.team.Team1Color, color: props.team.Team1Color2 }} className={"number-button-blue"}> <KeyboardArrowRightIcon></KeyboardArrowRightIcon> </div>
                                                </td>
                                            </tr> : null
                                        })}
                                        {match && match.Players.map((player, index) => {
                                            return player.ForTeam == 2 ? <tr key={"pls01" + index} >
                                                <td style={{ width: "50px" }}>
                                                    <div onClick={() => playerMinusGoal(index)} style={{ fontSize: "14px", height: "65px", display: "flex", alignItems: "center", backgroundColor: props.team.Team2Color, color: props.team.Team2Color2 }} className={"number-button-red"}> <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>   </div>
                                                </td>
                                                <td>
                                                    <div onClick={() => playerPlusGoal(index)} style={{ fontSize: "14px", height: "65px", backgroundColor: props.team.Team2Color, color: props.team.Team2Color2 }} className={"number-button-red"}>   {player.Nick} <br />  {player.Goals != "0" && <b style={{ fontSize: "25px", marginTop: -10, display: "block" }}>{player.Goals}</b>} </div>
                                                </td>
                                                <td style={{ width: "50px" }}>
                                                    <div onClick={() => playerPlusGoal(index)} style={{ fontSize: "14px", height: "65px", display: "flex", alignItems: "center", backgroundColor: props.team.Team2Color, color: props.team.Team2Color2 }} className={"number-button-red"}> <KeyboardArrowRightIcon></KeyboardArrowRightIcon>  </div>
                                                </td>
                                            </tr> : null
                                        })}
                                    </tbody>
                                </table>
                            </div>}

                        {step == 4 &&
                            <div className="fadeInUp fixed-container-endMatch-step4" style={{ textAlign: "center", padding: "20px" }}>
                                {match && match.Players.sort(function (a, b) {
                                    return a.ForTeam - b.ForTeam; // Ascending order
                                }).map((player, index) => {
                                    return player.ForTeam ? <div key={"pls02" + index} style={player.ForTeam == 1 ? { fontSize: "20px", backgroundColor: props.team.Team1Color, color: props.team.Team1Color2 } : { fontSize: "20px", backgroundColor: props.team.Team2Color, color: props.team.Team2Color2 }} onClick={() => onNumberClick(player, 1)} className={player.ForTeam == 1 ? "number-button-blue" : "number-button-red"}>
                                        {player.Nick}</div> : null
                                })}
                            </div>}

                        {step == 5 &&
                            <div className="fadeInUp fixed-container-endMatch-step1" style={{ textAlign: "center", padding: "20px" }}>
                                <MatchDetails hideLineups justForInfo hideAppBar data={match} team={props.team}></MatchDetails>
                            </div>}

                        <table style={{ width: "100%", padding: "20px", tableLayout: "fixed" }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: "20px" }}>
                                        <div variant="contained" style={database.get("theme") == "dark" ? { fontSize: "25px", width: "100%", backgroundColor:"#272727" } : { fontSize: "25px", backgroundColor:"#272727" }} onClick={() => onNumberClick(0, -1)} className={"number-button-dark"}>{util.translate("back")}</div>
                                    </td>
                                    <td style={{ padding: "20px" }}>
                                        {step == 5 ?
                                            <div variant="contained" className={"number-button-dark"} style={database.get("theme") == "dark" ? { fontSize: "25px", width: "100%", backgroundColor:"#272727" } : { fontSize: "25px", width: "100%", backgroundColor:"#272727" }} onClick={() => onSave()}  >{util.translate("save")}</div> :
                                            <div variant="contained" className={"number-button-dark"} style={database.get("theme") == "dark" ? { fontSize: "25px", width: "100%", backgroundColor:"#272727" } : { fontSize: "25px", width: "100%", backgroundColor:"#272727" }} disabled={step == 1 || step == 2 || step == 5} onClick={() => onNumberClick(0, 1)} >
                                                {step == 5 ? util.translate("save") : (step == 3 && goalsCount() == 0) || step == 4 ? util.translate("skip") : util.translate("next")}
                                            </div>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )
            }

        </div>)
}




export default EndMatch;
